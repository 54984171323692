;(function (app) {
  app.filter('characterLimitFilter', function characterLimitFilter() {
    return function (value, limit) {

      if (!value || value === undefined)
        return value;

      if(value.length > limit)
         return value.substring(0,limit) + '...';
      else
        return value;
    };
  });
})(angular.module('onfileApp'));
