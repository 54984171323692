(function (app) {
  app.directive('marketText', [
    'SiteConfigurationService',
    function (SiteConfigurationService) {
      return {
        strict: 'A',
        scope: {
          key: '@',
          additionalClasses: '@'
        },
        link: function ($) {
          $.text = SiteConfigurationService.getContent($.key);
        },
        templateUrl: '/assets/app/shared/directives/marketTextContent/template.html'
      };
    }
  ]);
})(angular.module('onfileApp'));