(function (app) {
  app.filter('notInArrayFilter', function notInArray() {
    return function (array, neededArray, trackBy, neededtrackBy) {
      var isPresent;
      var result = [];

      if (!array || !array.hasOwnProperty('length') || !neededArray || !neededArray.hasOwnProperty('length'))
        return result;
      
      for (var i = 0; i < array.length; i++) {
        isPresent = false;

        for (var j = 0; j < neededArray.length; j++) {
          if (array[i][trackBy] == neededArray[j][neededtrackBy]) {
            isPresent = true;
            break;
          }
        }

        isPresent ? void(0) : result.push(array[i]);
      }

      return result;
    };
  });
})(angular.module('onfileApp'));
