(function (app) {
    app.controller('EServiceCtrl', [
        '$scope', '$stateParams', '$rootScope',
        '$cacheFactory', '$state', 'EServiceResource',
        'FilingService', 'CaseService', 'FirebaseService',
        'ToastrService', 'CourtResource', 'EnvironmentService',
        'CaseResource', 'EServiceService', 'DownloadZipService',
        'CaseNameService', 'DocumentLoadService', '$q',
        'SecurityService', 'FilingsMetaTransformer', 'PrintService',

        function ($, $stateParams, $rootScope,
                  $cacheFactory, $state, EServiceResource,
                  FilingService, CaseService, FirebaseService,
                  ToastrService, CourtResource, EnvironmentService,
                  CaseResource, EServiceService, DownloadZipService,
                  CaseNameService, DocumentLoadService, $q,
                  SecurityService, FilingsMetaTransformer, PrintService) {

            const id = $stateParams.id;
            const status = $stateParams.status;

            angular.extend($, {
                service: {},
                error: '',
                filingPartyName: '',
                isCaseAvailableForFiling: FilingService.isCaseAvailableForFiling,
                isCancelable: FilingService.isCancelable,
                filingService: FilingService,
                contact: {},
                isCreateReminderPopupVisible: false,
                filingsMetaTransformer: null,
                isRejected: null,
                loadDocument: DocumentLoadService.loadDocument,

                setPopupVisible: function () {
                    $.isCreateReminderPopupVisible = true;
                },
                downloadAll: function () {
                    const links = [];

                    if (!$.service.documents) {
                        throw new Error('There is no documents for this service to download');
                    }

                    return $.service
                        .documents
                        .reduce(function (promise, doc) {
                            return promise
                                .then(function () {
                                    const pr = [ DocumentLoadService.loadDocument(doc.original.link, $.service.id) ];

                                    if (doc.transmitted && doc.transmitted.link) {
                                        pr.push( DocumentLoadService.loadDocument(doc.transmitted.link, $.service.id) );
                                    }

                                    return $q.all(pr);
                                })
                                .then(function (docs) {
                                    return docs.map(function (doc) {
                                        const parts = doc.filename.split('.');
                                        const ext = parts.pop();
                                        const filename = parts.join('.');

                                        links.push({
                                            url: doc.link,
                                            name: filename,
                                            ext: ext
                                        });
                                    });
                                });
                        }, $q.resolve())

                        // Save ZIP
                        .then(function () {
                            return DownloadZipService.remoteFilesToZip(
                                links,
                                $.service.envelope_number + '_docs'
                            );
                        });
                },
                printFees: function () {
                    var content = angular.element('#print').html();

                    return PrintService.print(content);
                }
            });

            $rootScope.showList = true;
            $.eProdId;

            $.$watch('service', function (service) {
                if (service && service.hasOwnProperty('id')) {
                    if (service.hasOwnProperty('eProdId')) {
                        $.eProdiId = service.eProdId;
                    } else {
                        FirebaseService
                            .getNode('eproduction/submissions/' + EnvironmentService.firm_id() + '/' + service.id)
                            .$loaded()
                            .then(function (item) {
                                if (item && item.hasOwnProperty('eProdId')) {
                                    $.eProdId = item.eProdId;
                                }
                            });
                    }
                }
            });

            // Callback for ui.router directive when state is changed.
            this.uiOnParamsChanged = function (changedParams) {
                $.error = '';
                $.service = {};

                if (changedParams && changedParams.id) {
                    loadFromApi(changedParams);
                }
            };

            function loadContact(court) {
                return CourtResource
                    .getContact({ id: court })
                    .$promise
                    .then(function (contact) {
                        $.contact = contact;
                    });
            }

            function loadDetailedService(id, commonServiceInformation) {
                return EServiceResource
                    .get({id: id, firebaseDetail: true})
                    .$promise
                    .then(function (service) {
                        if (
                            (service.hasOwnProperty('$value') && service.$value === null) ||
                            (service.status_code !== commonServiceInformation.status_code)
                        ) {
                            return EServiceService.updateDetailed(id, $.service)
                                .then(function () {
                                    return EServiceResource.get({id: id, firebaseDetail: true}).$promise;
                                });
                        }

                        return service;
                    })
                    .then(function (service) {
                        $.service = Object.assign($.service, service);
                    })
                    .then(function () {
                        return CaseService
                            .getParticipantById({
                                caseId: $.service.case_id,
                                participantId: $.service.filing_party_id
                            })
                            .then(function (participant) {
                                $.filingPartyName = participant && participant.name ? participant.name : '';
                            });
                    });
            }

            function loadFromApi(changedParams) {
                $.isRejected = null;

                EServiceResource.meta({ id: changedParams.id })
                    .$promise
                    .then(function (filingMeta) {
                        $.filingsMetaTransformer = FilingsMetaTransformer.import(filingMeta);
                    });

                return EServiceResource.get({id: changedParams.id})
                    .$promise
                    .then(function (service) {
                        $.service = service;

                        loadDetailedService(changedParams.id, service);

                        return $.service;
                    })
                    .then(function (service) {
                        return CaseResource.get({id: service.case_id}).$promise;
                    })
                    .then(function (eCase) {
                        $.case = eCase;
                        $.service.case_title_composed = CaseService.composeCaseName(eCase);

                        if (eCase.court_code || $.service.court_name) {
                            loadContact(eCase.court_code || $.service.court_name);
                        }

                        $.service.filing_attorney = FilingService.getFilingAttorney(changedParams.id);
                        $.service.case = eCase;
                        $.isRejected = FilingService.isRejected($.service);

                        CaseNameService.decorate($.service);
                    });
            }

            $.cancelFiling = function (id) {
                return EServiceResource.delete({ id: id })
                    .$promise
                    .then(function () {
                        $.service.sent_to_canceling = true;

                        return EServiceService.updateProp(id, 'sent_to_canceling', true);
                    })
                    .then(function () {
                        // Removes Services/Filings list from Cache in order to upload new list with created Filing
                        $cacheFactory.get('$http').remove('/services');
                        $cacheFactory.get('$http').remove('/services/detail/' + id);
                        ToastrService.showMessageByKey('pending_submission_cancelled');

                        // Changing page to created Filing details
                        $state.transitionTo('filingsView', {id: id}, {
                            reload: true,
                            inherit: false,
                            notify: true
                        });
                    })
                    .catch(function (e) {
                        console.error(e);
                    });
            };

            $.resubmit = function (service) {
                var data;
                var state = null;

                if ($.filingService.isSubsequentFiling(service)) {
                    data = {
                        caseId: service.case.id,
                        courtId: service.case.court_code,
                        envelopeId: service.envelope_number,
                        resubmitFilingId: service.id
                    };
                    state = "filingCreate";
                }
                 else if ($.filingService.isInitialFiling(service)) {
                    data = {
                        envelopeId: service.envelope_number,
                        resubmitFilingId: service.id
                    };
                    state = "caseCreate";
                };

                if (state !== null)
                    $state.go(state, data);
            };

            this.uiOnParamsChanged({ id: id, status: status });
        }
    ]);
})(angular.module('onfileApp'));
