;(function (app) {
    app.value('caseSearchFilter', function () {
            var sortFunction = function (a, b) {
                    var aTitle = '';
                    var bTitle = '';

                    if (a.hasOwnProperty('case_title_composed')) {
                        aTitle = a.case_title_composed;
                    }

                    if (b.hasOwnProperty('case_title_composed')) {
                        bTitle = b.case_title_composed;
                    }

                    if (a.hasOwnProperty('name')) {
                        aTitle = a.name;
                    }

                    if (b.hasOwnProperty('name')) {
                        bTitle = b.name;
                    }


                    if (a.hasOwnProperty('firstName')) {
                        aTitle = a.firstName;
                    }

                    if (b.hasOwnProperty('firstName')) {
                        bTitle = b.firstName;
                    }

                    if (aTitle === 'ALL') {
                        return -1;
                    } else if (bTitle === 'ALL') {
                        return 1;
                    }

                    return aTitle.toLowerCase().localeCompare(bTitle.toLowerCase());
                }
            ;

            return function (collection, search, searchParams) {
                var res = [];

                if (!search) {
                    res = collection || [];
                } else {
                    res = collection.filter(function (item) {
                        if (item.case_title_composed && search) {
                            return item.case_title_composed
                                .toLowerCase()
                                .indexOf(search.toLowerCase()) > -1;
                        }


                        if (search) {
                            var i = item;
                            if (searchParams && !item.hasOwnProperty('name')) { //to avoid ALL
                                i = {};
                                for (var j = 0; j < searchParams.length; j++) {
                                    i[searchParams[j]] = item[searchParams[j]];
                                }
                            }
                            return JSON.stringify(i)
                                .toLowerCase()
                                .indexOf(search.toLowerCase()) > -1;
                        }

                        return false;
                    });
                }

                return res.sort(sortFunction);
            };
        }
    );
})(angular.module('onfileApp'));
