;(function (app) {
  app.filter('htmlDecodeFilter', function htmlDecodeFilter() {
    return function (array, property) {
      var result = [];

      if (!array || !array.hasOwnProperty('length'))
        return result;

      for (var i = 0, item; i < array.length; i++) {
        item = array[i];
        item[property] = item[property].replace(/&apos;/i, "'");
        result.push(item);
      }

      return result;
    };
  });
})(angular.module('onfileApp'));
