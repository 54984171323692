(function (app) {
    app.controller('CreateEServiceCtrl', [
        'FeeResource', 'FilingCodeResource', 'FilingComponentCodeResource',
        'EServiceResource', 'eProductionService', 'PDFParserService',
        'EnvironmentService', 'FirebaseService', 'RefreshDataService',
        'TextNotificationService', 'MarketRulesService', 'CaseResource',
        '$scope', '$state', '$rootScope',
        '$stateParams', '$firebaseArray', '$q',
        'ToastrService', 'GAService', 'CaseService',
        'FilingCreateCacheService', '$timeout',
        function (
            FeeResource, FilingCodeResource, FilingComponentCodeResource,
            EServiceResource, eProductionService, PDFParserService,
            EnvironmentService, FirebaseService, RefreshDataService,
            TextNotificationService, MarketRulesService, CaseResource,
            $, $state, $rootScope,
            $stateParams, $firebaseArray, $q,
            ToastrService, GAService, CaseService,
            FilingCreateCacheService, $timeout
        ) {
            angular.extend($, {
                activeTabIndex: 0,
                isCaseSelected: false,
                party: [],
                nextState: $.hasOwnProperty('nextState') ? $.nextState : 'servicesCreate.accordion',
                creationStatus: false,
                newFilingId: null,
                isCaseFormValid: false,
                filing: [],
                filings: [],
                deleteDraft: function () {
                    var draftId = $stateParams.draftId;

                    FirebaseService
                        .getIdChannelAsObject('auth_users', getDraftNodes().draftNode + '/' + draftId)
                        .then(function (draft) {
                            return draft.$remove();
                        })
                        .then(function () {
                            const nextState = $rootScope.serviceType === 'eservice' ? 'draftServices' : 'draftFilings';

                            $state.transitionTo(nextState);
                        });
                }
            });

            $rootScope.serviceType = $rootScope.serviceType || "eservice";
            $rootScope.baseState = $.nextState;
            $rootScope.isNewPartyAllowed = false;
            $rootScope.isEProductionPackage = !!$stateParams.eProdId;

            var draftId = $state.params.draftId;
            var eProdId = $state.params.eProdId;

            var filingComponents = {
                all: [],
                selected: []
            };

            $rootScope.showList = false;

            function getDraftNodes() {
                var currentStateName = $state.current.name.toString();
                var isService = currentStateName.indexOf('service') >= 0;

                if (isService) {
                    return {
                        draftNode: 'eservices_drafts',
                        submittedNode: 'eservices_submitted'
                    };
                }

                // assume that current item is filing if it's not a service
                return {
                    draftNode: 'efilings_drafts',
                    submittedNode: 'efilings_submitted'
                };
            }

            // When a Case is selected changing the first tab activity
            $.$on('CaseChanged', function (e, eCase) {
                //$.draft = null;
                var draftId = $state.params.draftId;
                var isDraft = draftId !== undefined;

                $.isCaseSelected = true;

                if (draftId) {
                    loadDraft(draftId);
                }

                // If there is some data in $.case than extend it with data from eCase.
                $.case = $.case ? angular.merge($.case, eCase) : eCase;

                if ($stateParams && $stateParams.hasOwnProperty('envelopeId') && $stateParams.envelopeId) {
                    $.case.envelope_id = $stateParams.envelopeId;
                }

                $.case.draft = isDraft;
                if (draftId) {
                    $.case.draftId = draftId;
                }

                initCase(eCase, isDraft);

                if($state.params.eProdId)
                    return populateEProductionLink($state.params.eProdId, EnvironmentService.firm_id());
            });

            var populateEProductionLink = function (eProdId, firmId) {
                return eProductionService
                    .init(null, eProdId, firmId)
                    .then(function (e) {
                        var params = PDFParserService.getParams(eProdId, e.d);
                        var layout = 'file-download-instructions';
                        var mergedParams = Object.assign({ caseId: $.case.id }, params);

                        return PDFParserService.getPdfLink(layout, mergedParams, true);
                    })
                    .then(function (url) {
                        $timeout(function () {
                            $rootScope.eProductionSummaryDoc = url;
                        }, 0);
                    });
            };

            function initCase(eCase, isDraft) {
                // Populating Party Information with Participants.
                if (!(isDraft && eCase.participants)) {
                    var hasParticipants = eCase && eCase.hasOwnProperty('participants') && eCase.participants &&
                        eCase.participants.hasOwnProperty('length');

                    if (hasParticipants) {
                        var participant;
                        eCase.party = ((eCase.party === undefined) || !eCase.party.length) ?
                            [] : eCase.party;

                        for (var i in eCase.participants) {
                            participant = eCase.participants[i];
                            participant.type_code === 'ATTY' ? void(0) : eCase.party.push(participant);
                        }
                    }
                }

                FilingComponentCodeResource.query({court_code: eCase.court_code}).$promise
                    .then(function (res) {
                        filingComponents.all = res;
                    });
            }

            $.$watch('activeTabIndex', function () {
                upsertFiling();
            });

            function plainObject(item) {
                const data = {};

                for (var prop in item) {
                    if (item && item.hasOwnProperty(prop) && prop.indexOf('$') !== 0) {
                        data[prop] = item[prop];
                    }
                }

                return JSON.parse(JSON.stringify(data));
            }

            function loadDraft(draftId) {
                FirebaseService.getIdChannel('auth_users', getDraftNodes().draftNode + '/' + draftId)
                    .then(function (draft) {
                        $.draft = draft;
                        var eProd = $.draft.$getRecord('eProdId');
                        if (eProd) {
                            $rootScope.eProdId = eProd.$value;
                        } else {
                            delete $rootScope.eProdId;
                        }
                    });
            }

            //improve this to only bring down the node and not the whole collection
            function upsertFiling() {
                if (!$.case) {
                    return false;
                }

                if ($.draft == null) {
                    FirebaseService.getIdChannel('auth_users', getDraftNodes().draftNode)
                        .then(function (drafts) {
                            // TODO:check for eProduction param, inset it as additional key
                            var draftParams = {item: plainObject($.case), time: Date.now()};
                            if (eProdId) {
                                draftParams.eProdId = eProdId;
                                $rootScope.eProdId = eProdId;
                            }

                            var resubmitFilingId = null;
                            if ($state.params && $state.params.hasOwnProperty('resubmitFilingId')) {
                                resubmitFilingId = $stateParams.resubmitFilingId;
                            }

                            if (resubmitFilingId && resubmitFilingId.length > 9) {
                                draftParams.item.resubmittedFrom = resubmitFilingId;
                            }

                            drafts.$add(draftParams)
                                .then(function (ref) {
                                    draftId = drafts[drafts.length - 1].$id;
                                    $.case.draftId = draftId;
                                    $.draft = $firebaseArray(ref);
                                    if (eProdId) {
                                        eProductionService
                                            .init(null, eProdId, EnvironmentService.firm_id())
                                            .then(function (e) {
                                                e.d.batch_header.eservice_draft_id = draftId;
                                                e.d.batch_header.case_id = $.case.id;
                                                e.d.batch_header.court_id = $.case.court_code;
                                                e.d.batch_header.case_title = $.case.title;

                                                e.synchronize();
                                            });
                                    }
                                });
                        });
                } else {
                    var item = $.draft.$getRecord('item');
                    var time = $.draft.$getRecord('time');

                    item = item ? angular.extend(item, plainObject($.case)) : plainObject($.case);

                    if (!item || !time) {
                        return;
                    }

                    time.$value = Date.now();

                    $.draft.$save(item);
                    $.draft.$save(time);
                }
            }

            $.completeTheDraft = function () {
                FirebaseService.getIdChannel('auth_users', getDraftNodes().submittedNode, true)
                    .then(function (submitted) {
                        //HACK
                        //try this         item = angular.extend(item, $.case.plainObject());
                        var draft = $.draft.$getRecord('item');

                        delete draft.$id;
                        delete draft.$priority;
                        var parties = draft.parties;
                        if (parties) {
                            parties.forEach(function (item) {
                                delete item.$valid;
                                delete item.$index;
                            });
                        }

                        parties = draft.party;
                        if (parties) {
                            parties.forEach(function (item) {
                                delete item.$valid;
                                delete item.$index;
                            });
                        }

                        //refactor for better reusability
                        if (draft.filings) {
                            draft.filings.forEach(function (item) {
                                delete item.$valid;
                                delete item.$index;

                                item.docs.forEach(function (item) {
                                    delete item.$valid;
                                    delete item.$index;
                                });
                            });
                        }

                        //refactor for better reusability
                        if (draft.filing) {
                            delete draft.filing.$valid;
                            delete draft.filing.$index;

                            draft.filing.docs.forEach(function (item) {
                                delete item.$valid;
                                delete item.$index;
                            });
                        }

                        draft = angular.fromJson(angular.toJson(draft));
                        //TODO: Set submitted eProd to submited
                        //remove eProd from drafts
                        submitted.push().update({item: draft, time: Date()});
                    });

                FirebaseService.getIdChannel('auth_users', getDraftNodes().draftNode + '/' + draftId, true)
                    .then(function (remoteDraft) {
                        remoteDraft.remove();
                    });
            };

            function filterFilingComponents(filingCode) {
                filingCode = filingCode && filingCode.hasOwnProperty('code') ?
                    filingCode.code : filingComponents.all[0].filingcodeid;

                if (!filingCode)
                    throw 'Not valid filingCode';

                filingComponents.selected = {};

                for (var i = 0; i < filingComponents.all.length; i++) {
                    if (filingComponents.all[i].filingcodeid != filingCode)
                        continue;

                    if (filingComponents.all[i].name === 'Lead Document') {
                        filingComponents.selected.lead = filingComponents.all[i];
                    } else {
                        filingComponents.selected.attach = filingComponents.all[i];
                    }
                }

                for (i = 0; i < $.case.filing.docs.length; i++) {
                    $.case.filing.docs[i].filing_component =
                        (i === 0 ? filingComponents.selected.lead : filingComponents.selected.attach);
                }

                return filingComponents.selected;
            }

            $.toggleTab = function (index) {
                if (index === 'prev') {
                    $.activeTabIndex--;
                } else if (index === 'next' || index === undefined) {
                    $.activeTabIndex++;
                } else {
                    $.activeTabIndex = index;
                }

                return $.activeTabIndex;
            };

            // Calculates the fees for service
            $.getFeesForService = function () {
                if (!$.case.filing)
                    return false;

                FeeResource.forService($.case).$promise
                    .then(function (fees) {
                        $.case.fees = fees;
                    });

                return true;
            };

            // Calculates the fees for filing
            $.getFeesForFiling = function () {
                if (!$.case.filing)
                    return false;

                filterFilingComponents($.case.filing.filing_code);
                FeeResource.forFiling($.case).$promise
                    .then(function (fees) {
                        $.case.fees = fees;
                    });

                return true;
            };

            // Changing current state to display an accordion.
            $state.transitionTo($.nextState, $stateParams).transition.promise
                .then(function () {
                    // Watches on activeTabIndex changing after transition is done.
                    $.$watch('activeTabIndex', function () {
                        // Checks if the Case and its Payment Account is selected already.
                        if (!$.isCaseSelected || !$.case || !$.isCaseFormValid) {
                            $.activeTabIndex = 0;
                            return;
                        }

                        if ($.case.hasOwnProperty('draft') && $.case.draft === true) {
                            $state.transitionTo(
                                $.nextState,
                                Object.assign({}, $stateParams, {
                                    currentTab: $.activeTabIndex,
                                    caseId: $.case.id,
                                    courtId: $.case.court_code,
                                    draftId: draftId
                                })
                            );
                        }
                        else {
                            $state.transitionTo(
                                $.nextState,
                                Object.assign({}, $stateParams, {
                                    // currentTab: $.activeTabIndex,
                                    caseId: $.case.id,
                                    courtId: $.case.court_code
                                })
                            );
                        }

                        if ($.activeTabIndex == 3) {
                            // If someone clicks through
                            // tabs quickly the draft might not be saved
                            // yet
                            var loader = angular.element('#workspace-loading');
                            loader.addClass('is-visible');

                            function checkEProd() {
                                var eProd;

                                if (!$.draft) {
                                    $timeout(function () { checkEProd() }, 300);

                                    return null;
                                }

                                loader.removeClass('is-visible');
                                eProd = $.draft.$getRecord('eProdId');

                                if (!eProd) {
                                    return null;
                                }

                                return eProductionService
                                    .init(null, eProd.$value, EnvironmentService.firm_id())
                                    .then(function (e) {
                                        var params = PDFParserService.getParams(eProdId, e.d);
                                        var layout = 'file-download-instructions';
                                        var mergedParams = Object.assign({ caseId: $.case.id }, params);

                                        return PDFParserService.getPdfLink(layout, mergedParams, true);
                                    })
                                    .then(function (url) {
                                        $timeout(function () {
                                            $rootScope.eProductionSummaryDoc = url;
                                        }, 0);
                                    });
                            }

                            checkEProd();
                        }
                    });
                });

            if ($stateParams.hasOwnProperty('currentTab')) {
                var index = parseInt($stateParams.currentTab);
                $.activeTabIndex = isNaN(index) ? 0 : index;
            }

            $.createService = function () {
                if ($.isEProductionPackage) {
                    $rootScope.setLoadingMessage('Submitting eProduction service envelope');
                }

                EServiceResource.save(CaseService.prepareBeforeSend($.case))
                    .$promise
                    .finally(function () { $rootScope.setLoadingMessage(null); })
                    .then(function (res) {
                        if (!res || !res.hasOwnProperty('id')) {
                            throw res;
                        }

                        if ($.case.text_alert_on_change) {
                            TextNotificationService.saveNotificationForFiling(res.id, {case: $.case});
                        }

                        RefreshDataService.forceRefresh();

                        var eProd = $.draft.$getRecord('eProdId');
                        if (eProd) {
                            GAService.addNewEProduction($.case, res);

                            eProductionService
                                .init(null, eProdId, EnvironmentService.firm_id())
                                .then(function (e) {

                                    var guestHeaders = {};

                                    var contacts = $.draft
                                        .$getRecord('item')
                                        .service_contacts.map(function (c) {
                                            {
                                                return {
                                                    "email": c.email,
                                                    "name": c.name
                                                };
                                            }
                                        });
                                    guestHeaders.service_contacts = contacts.filter(function (e) {
                                        return e !== null;
                                    });


                                    guestHeaders.sender_name = EnvironmentService.user_name();
                                    e.d.batch_header.sender_name = EnvironmentService.user_name();
                                    e.d.batch_header.status = "submitted";
                                    e.d.batch_header.submitted_at = new Date().getTime();
                                    e.d.batch_header.filing_id = res.id;
                                    e.d.batch_header.case_title = $.case.title;
                                    e.d.batch_header.case_number = $.case.docket_id;

                                    //so we can capture sender_name as well;
                                    e.synchronize();

                                    FirebaseService
                                        .saveNode("eproduction/guest_headers/" + eProdId, guestHeaders);

                                    //for eservice integration
                                    FirebaseService.saveNode("eproduction/submissions/" + EnvironmentService.firm_id() + "/" + res.id, {eProdId: eProdId});

                                    //TODO: consolidate & the else statement.
                                    $.completeTheDraft();
                                    // Forming variables for success popup
                                    $.creationStatus = true;
                                    $.newFilingId = res.id;
                                });
                        }
                        else {
                            $.completeTheDraft();
                            // Forming variables for success popup
                            $.creationStatus = true;
                            $.newFilingId = res.id;

                            GAService.addNewEService($.case, res);

                        }

                    })
                    .then(function () {
                        try {
                            return FilingCreateCacheService.save($.case);
                        } catch (e) {
                            console.error(e);
                        }
                    })
                    .catch(function (err) {
                        console.error(err);
                    });
            };

            $.clientSelected = function (parties) {
                if (parties) {
                    for (var i = 0; i < parties.length; i++) {
                        if (parties[i].isClient === true)
                            return true;
                    }
                }
                return false;
            };

            $.isValidToSubmit = function () {
                var eProdValid = true;
                var isValid = $.case.filing.docs
                    && $.case.filing.docs.length > 0;

                if (!isValid) {
                    ToastrService.showMessageByKey('no_document_found');
                }

                if ($.isEProductionPackage) {
                    eProdValid = !!$.case.filing.docs.find(function (doc) {
                        return doc.eProduction;
                    });
                }

                if (!eProdValid) {
                    ToastrService.showMessageByKey('download_instructions_missing');
                }

                return isValid && eProdValid;
            };

            $.selectClient = function (index, parties) {
                for (var i = 0; i < parties.length; i++) {
                    parties[i].isClient = false;
                }

                parties[index].isClient = true;
                return true;
            };

            $.caseFormChanged = function (isValid) {
                $.isCaseFormValid = isValid;
            };
        }
    ]);
})(angular.module('onfileApp'));
