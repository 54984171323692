;(function (app) {
    app.directive('newAttorneyPopup', [
        'AttorneyResource', '$rootScope',

        function (AttorneyResource, $rootScope) {
            return {
                strict: 'A',
                scope: {
                    visible: '='
                },
                link: function ($) {
                    Object.assign($, {
                        isLoading: false,
                        errors: [],
                        attorney: {
                            firstName: '',
                            middleName: '',
                            lastName: '',
                            barNumber: ''
                        },

                        togglePopup: function (visible) {
                            $.visible = !!visible;
                        },
                        create: function () {
                            $.errors = [];
                            $.isLoading = true;

                            return AttorneyResource.save($.attorney).$promise
                                .finally(function () {
                                    $.isLoading = false;
                                })
                                .then(function (res) {
                                    if (res.errors) {
                                        $.errors = res.errors;

                                        return false;
                                    }

                                    $.$emit('NewAttorneyCreated', { id: res.attorneyID });
                                    $.togglePopup(false);
                                });
                        }
                    });
                },
                templateUrl: '/assets/app/shared/directives/newAttorneyPopup/template.html?{{onfileApplicationVersion}}'
            };
        }
    ]);
})(angular.module('onfileApp'));
