/**
 * Directive used for displaying Pdf modal
 */
(function (app) {
  app.directive('previewPdf',["$window", "$timeout", "$compile", "$q", "$rootScope", "DocumentLoadService", "filepickerApikey", 'SecurityService',
                function($window, $timeout, $compile, $q, $rootScope, DocumentLoadService, filepickerApikey, SecurityService){
                  // Insert pdf modal on page if directive is present
                  $rootScope.showPdfModal = false;
                  $window.filepicker.setKey(filepickerApikey);
                  // Append modal to body elem.
                  var modalHTML = "<div id='pdf-preview--overlay' ng-show='$root.showPdfModal' ng-click='$root.showPdfModal = false'> <div id='pdf-preview--close' ng-click='$root.showPdfModal = false'><a>X</a></div> <div id='pdf-preview--modal'> <div id='pdf-preview--wrapper'> </div> </div> </div>";
                  $('body').append($compile(modalHTML)($rootScope));
                  var previewCustomCss = "https://d1ax1i5f2y3x71.cloudfront.net/items/3Q3g2o2e0S0p1L0y0E2f/onfile.viewer.custom.css";
                  var previewCompatibleFormats = [
                    'application/pdf',
                    'application/msword',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'application/vnd.oasis.opendocument.text',
                    'application/vnd.ms-excel',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.oasis.opendocument.spreadsheet',
                    'application/vnd.ms-powerpoint',
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                    'application/vnd.oasis.opendocument.presentation',
                    'text/html',
                    'text/plain; charset=UTF-8',
                    'text/plain',
                    'image/bmp',
                    'image/gif',
                    'image/jpeg',
                    'image/jpg',
                    'image/png',
                    'image/svg+xml',
                    'image/tiff',
                    'application/json',
                    'image/vnd.adobe.photoshop',
                    'application/illustrator'
                  ];
                  var secOpts;
                  if (!secOpts) {
                    SecurityService.getPreviewSecParams().then(function(o){
                      secOpts = o;
                    })
                  }

                  return {
                    templateUrl: function(tEl, tAttrs) {
                      if(tAttrs.dialogMode)
                        return "/assets/app/shared/directives/previewPdf/blank-template.html"

                      if (tAttrs.btnMode) {
                        return "/assets/app/shared/directives/previewPdf/template-btn.html"
                      }
                      if (tAttrs.linkMode) {
                        return "/assets/app/shared/directives/previewPdf/template-link.html"
                      }
                      return "/assets/app/shared/directives/previewPdf/template.html"
                    },
                    restrict :'E',
                    scope : {
                      url : '@url',
                      filingId: '@filingId',
                      btnMode : '@btnMode',
                      linkMode : '@linkMode',
                      dialogMode : '@dialogMode',
                      btnClass : '@btnClass',
                      btnText : '@btnText',
                      togglePreviewModal: "&",
                      openDialog : '=openDialog'
                    },
                    link : function(scope, el, attrs){
                      var fp_url = null;
                      var fp_mimetype = null;
                      scope.$watch('url', function(n){
                        parsePreviewCall(n)
                      });

                      scope.$watch('openDialog',function(n){
                       console.log('openDialog' + scope.openDialog);
                        if(scope.openDialog)
                        scope.handlePdfFile();
                      });

                      // var pdfPreviewModalWrapper = document.createElement('div')
                      // pdfPreviewModalWrapper.className = "pdf-preview--modal-wrapper"
                      // pdfPreviewModalWrapper.innerHTML = modalHTML
                      var previewWrapper = document.getElementById('pdf-preview--wrapper');

                      var isIn = function(needle, haystack) {
                        for (var i = 0; i < haystack.length; i += 1) {
                          if (haystack[i] == needle) {
                            return true;
                          }
                        }
                        return false;
                      };

                      var filestackUpload = function() {
                        var deferred = $q.defer();
                        $window.filepicker.storeUrl(scope.url, {}, function(res){
                          fp_url = res.url;
                          fp_mimetype = res.mimetype.trim();
                          deferred.resolve(res);
                        }, 
                        function(err) {
                          deferred.reject(err);
                        });
                        return deferred.promise;
                      };

                      var parsePreviewCall = function(url) {
                        var previewWindow = document.createElement("div");
                        previewWindow.className = "filepicker-preview";
                        previewWindow.setAttribute("type", "filepicker-preview");
                        previewWrapper.innerHTML = "";
                        previewWrapper.appendChild(previewWindow);
                        if (secOpts != null) {
                          // We have to add url for non pdf files.
                          // Because reasons.
                          url += "?policy="+secOpts.policy+"&signature="+secOpts.signature;
                          previewWindow.setAttribute("data-fp-policy", secOpts.policy);
                          previewWindow.setAttribute("data-fp-signature", secOpts.signature);

                        }
                        previewWindow.setAttribute("data-fp-url", url);
                        previewWindow.setAttribute("data-fp-custom-css", previewCustomCss);
                        $window.filepicker.constructWidget(previewWindow);
                      };

                      var downloadOriginalFile = function() {
                        var anchor = el[0].getElementsByClassName("pdf-preview--fallback-link")[0];
                        anchor.click();
                      };

                      scope.handlePdfFile = function() {
                        previewWrapper.innerHTML = "";
                        $rootScope.showPdfModal = true;
                        // This means preview is called on file that has not been submitted yet.
                        if (!scope.filingId) {
                          parsePreviewCall(scope.url)
                        } else {
                          if (fp_url === null) {
                            var promise = DocumentLoadService.loadDocument(scope.url, scope.filingId);
                            promise.then(function(res){
                              fp_url = res.url;
                              fp_mimetype = res.mimetype;
                              if (isIn(fp_mimetype, previewCompatibleFormats)) {
                                parsePreviewCall(res.url);
                              } else {
                                downloadOriginalFile();
                              }
                            });
                          } else {
                            if (isIn(fp_mimetype, previewCompatibleFormats)) {
                              parsePreviewCall(fp_url);
                            } else {
                              downloadOriginalFile();
                            }
                          }
                        }
                      };
                    },
                  };
                }
  ]);
})(angular.module('onfileApp'));
