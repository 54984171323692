;(function (app) {
  app.controller('EServicesDraftSidebarCtrl', [
    'FirebaseService',
    '$scope', '$rootScope', '$stateParams',

    function (
      FirebaseService,
      $, $rootScope, $stateParams
    ) {
      $rootScope.showList = true;
      $rootScope.EServicesDrafts = null;

      angular.extend($rootScope, {
        activeEServiceDraftId: $stateParams.id
      });

      // Callback function which is called when URL state changes.
      this.uiOnParamsChanged = function (changedParams) {
        // If current active eService ID is changed - refresh variable for a view.
        if (changedParams.hasOwnProperty('id'))
          $rootScope.activeEServiceDraftId = changedParams.id;
      };

      FirebaseService.getIdChannel('auth_users', 'eservices_drafts')
        .then(function (drafts) {
          $rootScope.eServicesDrafts = drafts;
          drafts.$loaded().then(function(l){
            for(var i = 0 ; i < l.length; i++)
            {
              l[i].iseProd = !(l[i].eProdId === null || l[i].eProdId === undefined );
            }
            //$rootScope.eServicesDrafts = l.filter(function(e){
            //  return e.eProdId == null;
            //});
          })
        });
    }
  ])
})(angular.module('onfileApp'));
