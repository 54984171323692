;(function (app) {
    app.service('PrintService', [
        function () {
            this.print = function (content) {
                var w = window.open();

                w.document.write(content);
                w.document.close();
                w.focus();

                var css = '@page { size: portrait; }',
                    head = w.document.head || w.document.getElementsByTagName('head')[0],
                    style = w.document.createElement('style');

                style.type = 'text/css';
                style.media = 'print';

                if (style.styleSheet) {
                    style.styleSheet.cssText = css;
                } else {
                    style.appendChild(w.document.createTextNode(css));
                }

                head.appendChild(style);

                w.print();
                w.close();
            };
        }
    ]);
})(angular.module('onfileApp'));