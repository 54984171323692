(function (app) {
    app.factory('PDFParserService', [
        '$rootScope', '$q', '$http', 'EnvironmentService',
        function ($rootScope, q, $http, EnvironmentService) {
            var pdfParserUrl = $rootScope.parsePdfUrl;
            var hostname = $rootScope.appBaseUrl;

            function sanitizeStr(s) {
                return s ? s.replace(/[^\x00-\x7F]/g, '') : '';
            }

            this.getParams = function (eProdId, eProductionData, name) {
                return {
                    eProdNotes: btoa(sanitizeStr(eProductionData.batch_header.notes)),
                    expire: eProductionData.batch_header.expire_time,
                    eProdId: eProdId,
                    name: name ? name : eProdId + '.pdf'
                };
            };

            this.getData = function (eProdData) {
                var params = {
                    url: hostname + '/eproduction/' + EnvironmentService.firm_id() + '/' + eProdData.eProdId,
                    container: 'onfile-' + EnvironmentService.environment() + '-' + EnvironmentService.market(),
                    path: EnvironmentService.firm_id() + '/' + eProdData.eProdId + '/' + eProdData.name,
                    notes: eProdData.eProdNotes,
                    expire: eProdData.expire
                };

                return $http.post(pdfParserUrl, JSON.stringify(params))
                    .then(function (res) {
                        if (res.data.status !== 'success') {
                            throw new Error(res.data.message || 'Internal server error');
                        }

                        return res.data.url;
                    });
            };

            this.getNumberOfPages = function (fileLink) {
                PDFJS.workerSrc = '/assets/js/pdf.worker.js';

                return q.resolve(fileLink)
                    .then(PDFJS.getDocument)
                    .then(function (doc) {
                        return doc.pdfInfo.numPages;
                    });
            };

            this.addPageNumbersToFiles = function (files) {
                var promises = [];
                var _this = this;

                for (var i = 0; i < files.length; i++) {
                    promises.push(
                        (function (index) {
                            return _this.getNumberOfPages(files[index].url)
                                .then(function (numberOfPages) {
                                    files[index].number_of_pages = numberOfPages;
                                });
                        })(i)
                    );
                }

                return q.all(promises)
                    .then(function () {
                        return files;
                    });
            };

            this.getPdfLink = function (layout, params, fullLink) {
                var paramsStr = Object.keys(params)
                    .map(function (key) {
                        return 'params[' + key + ']=' + params[key];
                    }).join('&');
                var url = '/pdf/convert?layout=' + layout + '&' + paramsStr;

                if (fullLink) {
                    if (window.location.origin.indexOf('.local') >= 0) {
                        url = 'https://development.texas.onfile.legal' + url;
                    } else {
                        url = window.location.origin + url;
                    }

                }

                return url;
            };

            return this;
        }
    ]);
})(angular.module('onfileApp'));
