;(function (app) {
    app.directive('selectCasePopup', [
        'CaseService', 'ElasticSearchResource',

        function (CaseService, ElasticSearchResource) {
            return {
                strict: 'A',
                scope: {
                    status: '='
                },
                link: function ($) {
                    angular.extend($, {
                        cases: [],
                        selectedCase: null,
                        selectCase: function (eCase) {
                            $.$emit('CaseSelectionChanged', eCase);
                        }
                    });

                    $.searchCases = function (e) {
                        return ElasticSearchResource.searchCaseByTitleCombined(e)
                            .then(CaseService.fixNames);
                    };

                    $.searchCasesByDocketId = function (e) {
                        return ElasticSearchResource.searchCaseByDocketId(e)
                            .then(CaseService.fixNames);
                    };
                },
                templateUrl: '/assets/app/shared/directives/selectCasePopup/template.html?{{onfileApplicationVersion}}'
            };
        }
    ]);
})(angular.module('onfileApp'));
