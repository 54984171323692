var appMinute = 60;

var app = angular
    .module('onfileApp', [
        'ngResource', 'ui.router', 'ngAnimate',
        'ngSanitize', 'ui.select', 'ngFileUpload',
        'angular-filepicker', 'angularMoment', 'as.sortable',
        'firebase', 'toastr', 'ngMaterial',
        'ngclipboard', 'ui.mask'
    ])

    // Adds interceptor for handling an redirect response.
     .config(['$httpProvider', function ($httpProvider) {
        $httpProvider.interceptors.push('authInterceptor');
        $httpProvider.interceptors.push('loaderInterceptor');
        $httpProvider.interceptors.push('errorInterceptor');
    }])

    .config(['uiMask.ConfigProvider', function (uiMaskConfigProvider) {
        uiMaskConfigProvider.addDefaultPlaceholder(false);
    }])

    // Overwriting custom error handler
    .factory('$exceptionHandler', ['$window', '$log', function ($window, $log) {
        return function myExceptionHandler(exception, cause) {
            var errorMessage = (function (exception) {
                var formatted = '';
                var properties = '';

                formatted += 'Exception: "' + exception.toString() + '"\n';
                formatted += 'Caused by: ' + cause + '\n';

                properties += (exception.message) ? 'Message: ' + exception.message + '\n' : '';
                properties += (exception.fileName) ? 'File Name: ' + exception.fileName + '\n' : '';
                properties += (exception.lineNumber) ? 'Line Number: ' + exception.lineNumber + '\n' : '';
                properties += (exception.stack) ? 'Stack Trace: ' + exception.stack + '\n' : '';

                if (properties) {
                    formatted += properties;
                }

                return formatted;
            })(exception);

            // Propagate only unexpected errors.
            if (NotReportedError.prototype.isPrototypeOf(exception) === false) {
                $window.onerror(errorMessage);
                $log.error(exception);
            }
        };
    }])

    .run([
        '$rootScope',
        '$location',
        '$window',
        '$state',
        'HeadingService',
        'MarketRulesService',
        'PermissionsService',
        function (
            $rootScope,
            $location,
            $window,
            $state,
            HeadingService,
            MarketRulesService,
            PermissionsService) {

                $rootScope.batesAvailable = null;
                $rootScope.eProductionAvailable = null;

                PermissionsService.getPermissions()
                    .then(function (resp) {
                        $rootScope.batesAvailable = !!resp.bates;
                        // @TODO disable when bates are fixed.
                        $rootScope.batesAvailable = false;
                        $rootScope.eProductionAvailable =
                            resp.eproduction !== false;
                        if (!$rootScope.eProductionAvailable &&
                            window.location.href.toLowerCase().indexOf('eproduction') !== -1) {
                                $state.go('dashboard');
                            }
                    });

                $rootScope.$on(
                    '$locationChangeStart',
                    function (event, toState, toParams, fromState) {
                        // if (!$rootScope.eProductionAvailable &&
                        //     toState &&
                        //     toState.toLowerCase().indexOf('eproduction') !== -1)
                        //         event.preventDefault();

                        if (!$window.ga)
                            return;

                        $window.ga(
                            'send',
                            'pageview',
                            {page: $location.path()});
                    });

                $rootScope.$on(
                    '$locationChangeSuccess',
                    function (event) {
                        if (!window.setActiveFlag)
                            return;
                        window.setActiveFlag();
                    });

                $rootScope.onMdAutocompleteTextChangedSetValidity =
                    function (model, query) {
                        if (query && query.length === 0)
                            model.$setValidity('md-require-match', null);
                    };

                $rootScope.cardsHeading = HeadingService.cardsHeading;
                $rootScope.currentPageType = HeadingService.currentPageType;
                $rootScope.filterPlaceHolder = HeadingService.filterPlaceHolder;
                $rootScope.HeadingService = HeadingService;

                if (window.guestMode) {
                  $state.go('auth');
                }
        }
    ])

    .run(['$rootScope', 'MarketRulesService', function ($rootScope, MarketRulesService) {
        // @HACK for async loading of configuration.
        MarketRulesService.loaded.then(function () {

            $rootScope.IsEnabled = function (feature) {
                return MarketRulesService.enabled(feature);
            };

            $rootScope.IsBlocked = $rootScope.IsDisabled = function (feature) {
                return !$rootScope.IsEnabled(feature);
            };

            $rootScope.IsRequired = function (feature) {
                if (feature === 'filing_attorney' && $rootScope.isIndividualFirm) {
                    return false;
                }

                return MarketRulesService.isRequired(feature);
            };
        });
    }])
    .run(['$rootScope', 'SiteConfigurationService', function ($rootScope, SiteConfigurationService) {
        SiteConfigurationService.getConfigurationForPage('menu')
            .then(function (e) {
                $rootScope.menuConfigs = e;
            });

        $rootScope.TryTranslate = function (key) {
            return SiteConfigurationService.getString(key);
        };
    }])
    .run(['$rootScope', 'FirebaseService', function ($rootScope, FirebaseService) {
        $rootScope.inboxMessages = null;
        $rootScope.getUnreadMessages = function (messages) {
            var count = 0;

            if (messages && messages.hasOwnProperty('length')) {
                messages.map(function (message) {
                    if (!message.read) {
                        count++;
                    }
                });
            }

            return count;
        };

        FirebaseService.getHashChannel('users', 'inbox').then(function (messages) {
            $rootScope.inboxMessages = messages;
        });

        // ///cleanup
        // FirebaseService.getIdChannel('auth_users','cases_drafts', true).then(function (drafts) {
        //     drafts.remove().then(function(){
        //         console.log('cases_drafts removed');
        //     });
        // });
        //
        // FirebaseService.getIdChannel('auth_users','efilings_drafts', true).then(function (drafts) {
        //     drafts.remove().then(function(){
        //         console.log('filings_drafts removed');
        //     });
        // });

        // FirebaseService.getIdChannel('auth_users','eservices_drafts', true).then(function (drafts) {
        //     drafts.remove().then(function(){
        //         console.log('filings_drafts removed');
        //     });
        // });

        // this.saveNode = function(path, item){
        //     var node = ref.child(path);
        //     return node.set(item);
        // };

        // FirebaseService.
        // saveNode('/eproduction/guest-headers/mfxgjstn',
        //     {'service_contacts' : ['test@test.com','test@gmail.com']});
    }])

    .run(['NotifierService', function (NotifierService) {
        NotifierService.watchOnGuestLogin()
    }])

    .value('idleSecondsTimeout', 300 * appMinute)
    .value('autoLogoutSecondsTimeout', 350 * appMinute )

    .run(['ReminderWatcherService', function (ReminderWatcherService) {
        ReminderWatcherService.watch()
    }])

    .value('dataRefreshSeconds', 60 * 60)
    .run(['RefreshDataService', function (RefreshDataService) {
        RefreshDataService.watch();
    }])

    .value(
        'emailSequencePattern',
        /^([a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,7}\s*,\s*)*([a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,7})$/
    )

    .value('emailsToNotifyOnceOnBoardingFinished', [
        'mzd555@gmail.com','gpoletes@gmail.com'
    ])

    .value(
        'serviceStatusCodes', {
            underReview: 1,
            accepted: 2,
            cancelled: 3,
            reviewed: 4,
            served: 5,
            deferred: 6,
            submitting: 7,
            submitted: 8,
            error: 9,
            rejected: 10,
            failed: 11
        })
    .run([
        '$rootScope', 'serviceStatusCodes',
        function ($rootScope, serviceStatusCodes) {
            var camel2title = function (camelCase) {
                // no side-effects
                return camelCase
                // inject space before the upper case letters
                    .replace(/([A-Z])/g, function (match) {
                        return " " + match;
                    })
                    // replace first char with upper case
                    .replace(/^./, function (match) {
                        return match.toUpperCase();
                    });
            };
            var camel2Dash = function (camelCase) {
                // no side-effects
                return camelCase
                // inject space before the upper case letters
                    .replace(/([A-Z])/g, function (match) {
                        return "-" + match;
                    })
                    .toLowerCase();
            };

            $rootScope.getServiceStatusCodes = function () {
                var res = [];
                for (var prop in serviceStatusCodes) {
                    res.push({
                        name: camel2title(prop),
                        value: serviceStatusCodes[prop],
                        raw: camel2Dash(prop)
                    });
                }

                return res;
            };
        }]
    )
    //Konrads : AdyPQNESSiP9c9wFlAQw2z
    //OFS : AUbpCPeYOQX29YMnO8mpxz
    .constant('filepickerApikey', 'AUbpCPeYOQX29YMnO8mpxz')
    .constant('filepickerSecurityEnabled', true)
    .constant('getForbiddenFilenamePattern', function () {
        var patterns = window.tylerAllowedSymbolsInFiles.map(function (p) {
            if (p.charAt(0) === '\\') {
                return '\\' + p;
            }

            return p;
        });

        return new RegExp('[^' + patterns.join('') +  ']', 'gi');
    })
    .constant('defaultDocumentTypeNames', [
        'Notice Of Submission',
        'public',
        'does not contain sensitive data'
    ].map(function (r) { return r.toLowerCase(); }))

    .run(['$rootScope', function ($rootScope) {
        $rootScope.getAllowedFilenameCharacters = function () {
            return window.tylerAllowedSymbolsInFiles.map(function (p) {
                return p.replace('\\', '');
            });
        };
    }])

    .run(['$rootScope', function ($rootScope) {
        const getLoader = function () {
            return angular.element('#workspace-loading');
        };

        $rootScope.showSpinner = function (message) {
            getLoader().addClass('is-visible');
            $rootScope.loading_message = message;
        };

        $rootScope.hideSpinner = function () {
            getLoader().removeClass('is-visible');
            $rootScope.loading_message = '';
        };
    }])

    .run(['$rootScope', function ($rootScope) {
        $rootScope.shortDate = 'MM/DD/YYYY';
        $rootScope.longDate = 'MM/DD/YYYY h:mma';
        $rootScope.shortDateTime = 'M/D/YY h:mma';
        $rootScope.longDateTimeWithoutAM = 'MM/DD/YYYY HH:mm';
        $rootScope.longDateTimeWithAM = 'MM/DD/YYYY hh:mma';
    }])
    .constant('angularMomentConfig', {
        timezone: (function () {
            const market = $('#application_market').val();
            const timezones = {
                default: 'America/Chicago',
                illinois: 'America/Chicago'
            };

            return timezones.hasOwnProperty(market) ? timezones[market] : timezones.default;
        })()
    })

    .run(['$rootScope', function ($rootScope) {
        const currentYear = moment().format('YYYY');

        $rootScope.copyright = 'Copyright ' + currentYear + ' OnFile Systems, Inc.';
    }])

    .run(['$rootScope', function ($rootScope) {
        $rootScope.contactUsLink = 'mailto:support@onfile.legal';
    }])

    .run(['$rootScope', function ($rootScope) {
        $rootScope.maxFileNameLength = 100;
    }])

    .run(['$rootScope', function ($rootScope) {
        $rootScope.linkToPrivacyDoc = '/assets/files/Privacy_Policy_-_guest_DL_page__170613.pdf';
    }])

    .run(['$rootScope', function ($rootScope) {
        $rootScope.caseErrorMessages = [
            {
                errorCode: 28,
                message: 'Case information is unavailable from the state eFiling manager.',
                allowFiling: false
            }, {
                errorCode: -11,
                message: 'This court\'s case management system is currently unavailable.',
                allowFiling: false
            }, {
                errorCode: 130,
                message: 'This case is secured and may not be eFiled on.',
                allowFiling: false
            }, {
                errorCode: 92,
                message: 'Case is not available for filing.',
                allowFiling: false
            }, {
                errorCode: -10,
                message: 'This court\'s case management system is currently unreachable. You may continue to file into this case, but are should do so with the understanding that the information for this case is sourced directly from the eFiling manager and may therefore be stale.',
                allowFiling: true
            }, {
                errorCode: 136,
                message: 'This court location does not allow for case searches. You may still file into this case as a "non-indexed" case.',
                allowFiling: true
            }
        ];
        $rootScope.charLimitOfEmail = 30;
    }])

    .run(['$rootScope', function ($rootScope) {
        $rootScope.cancelableRequests = [];
        $rootScope.cancelRequests = function () {
            $rootScope.cancelableRequests
                .filter(function (request) {
                    return request.$cancelRequest || request.abort;
                })
                .map(function (request) {
                    if (request.$cancelRequest) {
                        return request.$cancelRequest();
                    }

                    if (request.abort) {
                        return request.abort();
                    }
                });
        };
        $rootScope.removeFromCancelableQueue = function (request) {
            for (var i = 0; i < $rootScope.cancelableRequests.length; i++) {
                if (request === $rootScope.cancelableRequests[i]) {
                    $rootScope.cancelableRequests.splice(i, 1);
                    break;
                }
            }
        };
    }])

    .run([
      '$window',
      '$rootScope',
      'UserProfileService',
      function ($window, $rootScope, UserProfileService) {
        $rootScope.UserProfileService = UserProfileService;
        $rootScope.goHome = function() {
          var homePage = UserProfileService.getProfile().homePage,
              url =
                $window.location.origin +
                '/services' +
                '#/dashboard';
          if (homePage && homePage.url) {
            url = homePage.url;
          }

          $window.location = url;
        }

        $rootScope.isOnFavoritePage = function () {
          var profile = UserProfileService.getProfile();
          if (!profile || !profile.homePage) {
            return null;
          }
          return profile.homePage.url === $window.location.href;
        };
      }
    ])

    .run([
      '$rootScope',
      'FilingService',
      function($rootScope, FilingService) {
        $rootScope.FilingService = FilingService;
      }
    ])
    .run(['$rootScope', function ($rootScope) {
        var market = $('#application_market').val();

        $rootScope.marketString = market.charAt(0).toUpperCase() + market.slice(1);
    }])
    .run(['$rootScope', function ($rootScope) {
        $rootScope.appBaseUrl = $('#application_url').val();
        $rootScope.syncServiceUrl = $('#configSyncServiceUrl').val();
        $rootScope.parsePdfUrl = '/pdf-parse';
        $rootScope.filestackSecurityUrl = '/security';
    }])
    .run(['$rootScope', function ($rootScope) {
        var value = $('#application_isIndividualFirm').val();

        $rootScope.isIndividualFirm = isNaN(+value) ? 0 : +value;
    }])
    .run(['$rootScope', function ($rootScope) {
        $rootScope.isAmericanExpressNotAccepted = function (location) {
            if (
                location &&
                location.allowablecardtypes !== undefined &&
                location.allowablecardtypes.indexOf &&
                location.allowablecardtypes.indexOf('AMEX') === -1
            ) {
                return true;
            }

            return false;
        };
    }])
    .run([
        '$rootScope', 'SiteConfigurationService',
        function ($rootScope, SiteConfigurationService) {
            $rootScope.isUserGuideActive = false;
            $rootScope.manualLink = SiteConfigurationService.getUserGuideLink();
            $rootScope.showUserGuide = function () {
                $rootScope.isUserGuideActive = true;
            }
        }
    ])
    .run([
        '$rootScope', '$window',
        function ($rootScope, $window) {
            $rootScope.clearUserData = function () {
                $window.sessionStorage.clear();
            };
        }
    ])
    .run(['UserNotificationService', '$window', function (UserNotificationService, $window) {
        var notificationDisabledPages = [
            '/files/index'
        ];
        var isOnDisabledPage = !!notificationDisabledPages.find(function (p) {
            return $window.location.href.indexOf(p) >= 0;
        });

        if (isOnDisabledPage === false) {
            UserNotificationService.check();
        }
    }])

    .run(['$rootScope', function ($rootScope) {
        $rootScope.setLoadingMessage = function (message) {
            $rootScope.loading_message = message;
        };
    }])

    .config(function ($sceProvider) {
        $sceProvider.enabled(false);
    });

// Object.assign() polyfill (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign)
if (typeof Object.assign != 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    value: function assign(target, varArgs) { // .length of function is 2
      'use strict';
      if (target == null) { // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
      }

      var to = Object(target);

      for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index];

        if (nextSource != null) { // Skip over if undefined or null
          for (var nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}

//Array.find polyfill (https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/Array/find#Polyfill)
// https://tc39.github.io/ecma262/#sec-array.prototype.find
if (!Array.prototype.find) {
  Object.defineProperty(Array.prototype, 'find', {
    value: function(predicate) {
     // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function');
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1];

      // 5. Let k be 0.
      var k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return kValue.
        var kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return kValue;
        }
        // e. Increase k by 1.
        k++;
      }

      // 7. Return undefined.
      return undefined;
    }
  });
}

if (!String.prototype.oneOf) {
    String.prototype.oneOf = function (array) {
        if (array && Array.isArray(array)) {
            return array.indexOf(this) >= 0;
        }

        return false;
    };
}

// Routes moved to "public_html/assets/app/app.routes.js"
