(function (app) {
    app.config([
        '$stateProvider',
        function ($stateProvider) {
            var servicesList = {
                templateUrl: '/assets/app/shared/controllers/servicesList/template.html?{{onfileApplicationVersion}}',
                controller: 'EServicesListCtrl'
            };
            var casesList = {
                templateUrl: '/assets/app/components/eCases/casesList/template.html?{{onfileApplicationVersion}}',
                controller: 'ECasesListCtrl'
            };
            var casesDraftList = {
                templateUrl: '/assets/app/shared/controllers/casesDraftSidebar/template.html?{{onfileApplicationVersion}}',
                controller: 'CasesDraftSidebarCtrl'
            };

            var efilingsDraftList = {
                templateUrl: '/assets/app/shared/controllers/efilingsDraftSidebar/template.html?{{onfileApplicationVersion}}',
                controller: 'EFilingsDraftSidebarCtrl'
            };
            var eservicesDraftList = {
                templateUrl: '/assets/app/shared/controllers/eservicesDraftSidebar/template.html?{{onfileApplicationVersion}}',
                controller: 'EServicesDraftSidebarCtrl'
            };

            var servicesInboxList = {
                templateUrl: '/assets/app/shared/controllers/serviceInboxSidebar/template.html?{{onfileApplicationVersion}}',
                controller: 'ServiceInboxSidebarCtrl'
            };
            var eProductionDraftList = {
                templateUrl: '/assets/app/shared/controllers/eProductionDraftSidebar/template.html?{{onfileApplicationVersion}}',
                controller: 'EProductionDraftSidebarCtrl'
            };
            var eProductionSubmittedList = {
                templateUrl: '/assets/app/shared/controllers/eProductionSubmittedSidebar/template.html?{{onfileApplicationVersion}}',
                controller: 'EProductionSubmittedSidebarCtrl'
            };

            var getPartyFormViews = function () {
                return angular.copy({
                    person: {
                        templateUrl: '/assets/app/shared/controllers/partyInformation/_new-person/template.html?{{onfileApplicationVersion}}'
                    },
                    business: {
                        templateUrl: '/assets/app/shared/controllers/partyInformation/_new-business/template.html?{{onfileApplicationVersion}}'
                    }
                });
            };
            var getPartyInformationModules = function () {
                return angular.copy({
                    templateUrl: '/assets/app/shared/controllers/partyInformation/_party-information.html?{{onfileApplicationVersion}}',
                    controller: 'PartyInformationCtrl'
                });
            };

            // eServices
            (function ($stateProvider) {
                $stateProvider
                    .state('servicesCreate', {
                        url: '/services/create?caseId&courtId&currentTab&draftId&eProdId&reminderId',
                        params: {
                            caseId: {dynamic: false},
                            courtId: {dynamic: false},
                            currentTab: {dynamic: true},
                            draftId: {dynamic: true},
                            eProdId: {dynamic: true},
                            reminderId: {dynamic: true}
                        },

                        views: {
                            workspace: {
                                controller: 'CreateEServiceCtrl',
                                templateUrl: '/assets/app/components/eServices/create/create.html?{{onfileApplicationVersion}}'
                            }
                        }
                    })
                    .state('servicesCreate.accordion', {
                        views: {
                            partyInformation: getPartyInformationModules(),
                            serviceContacts: {
                                templateUrl: '/assets/app/shared/controllers/serviceContacts/_create-service-contacts.html?{{onfileApplicationVersion}}',
                                controller: 'CreateServiceContactCtrl'
                            },
                            eService: {
                                templateUrl: '/assets/app/components/eServices/create/_partials/documents/_create-upload-documents.html?{{onfileApplicationVersion}}',
                                controller: 'DocumentsCtrl'
                            },
                            reviewAndSubmit: {
                                templateUrl: '/assets/app/shared/controllers/reviewCase/_create-review-submit.html?{{onfileApplicationVersion}}',
                                controller: 'ReviewCtrl'
                            }
                        }
                    })

                    .state('servicesCreate.accordion.newParty', {
                        views: getPartyFormViews()
                    })

                    .state('dashboard', {
                        url: '/dashboard',

                        views: {
                            workspace: {
                                templateUrl: '/assets/app/components/dashboard/index.html?{{onfileApplicationVersion}}',
                                controller: 'DashboardCtrl'
                            }
                        }
                    })

                    .state('serviceInbox', {
                        url: '/services/received',

                        views: {
                            list: servicesInboxList
                        }
                    })

                    .state('serviceInboxView', {
                        url: '/services/received/:id',

                        params: {
                            id: {dynamic: true}
                        },

                        views: {
                            list: servicesInboxList,
                            workspace: {
                                templateUrl: '/assets/app/shared/controllers/serviceInboxMessage/template.html?{{onfileApplicationVersion}}',
                                controller: 'ServiceInboxMessageCtrl'
                            }
                        }
                    })

                    .state('servicesView', {
                        url: '/services/:id',
                        params: {
                            id: {dynamic: false}
                        },

                        views: {
                            list: servicesList,
                            workspace: {
                                controller: 'EServiceCtrl',
                                templateUrl: '/assets/app/components/eServices/view/view.html?{{onfileApplicationVersion}}'
                            }
                        }
                    }).state('services', {
                    url: '/services',

                    views: {
                        list: servicesList,
                        workspace: {
                            templateUrl: '/assets/app/components/eServices/index/index.html?{{onfileApplicationVersion}}',
                            controller: 'IndexEServiceCtrl'
                        }
                    }
                })
                    .state('draftServices', {
                        url: '/service/drafts',

                        views: {
                            list: eservicesDraftList
                        }
                    })

                    .state('draftServicesView', {
                        url: '/service/draft/:id',

                        params: {
                            id: {dynamic: true}
                        },

                        views: {
                            list: eservicesDraftList,
                            workspace: {
                                templateUrl: '/assets/app/shared/controllers/eserviceDraft/template.html?{{onfileApplicationVersion}}',
                                controller: 'EServiceDraftCtrl'
                            }
                        }
                    })

                ;
            })($stateProvider);

            // eProduction
            (function ($stateProvider) {
                $stateProvider
                    .state('eProduction', {
                        url: '/eproduction',
                        views: {
                            list: eProductionSubmittedList,
                            workspace: {
                                templateUrl: '/assets/app/components/eProduction/index/index.html?{{onfileApplicationVersion}}',
                                controller: function () {
                                }
                            }
                        }
                    })
                    .state('eProductionCreate', {
                        url: '/eproduction/create?caseId&eProdId&courtId&reminderId',
                        params: {
                            caseId: {dynamic: true},
                            eProdId: {dynamic: true},
                            courtId: {dynamic: true},
                            reminderId: {dynamic: true}
                        },
                        views: {
                            workspace: {
                                controller: 'eProductionCreateCtrl',
                                templateUrl: '/assets/app/components/eProduction/create/create.html?{{onfileApplicationVersion}}'
                            }
                        }
                    })
                    .state('eProductionDraftView', {
                        url: '/eproduction/draft/:id',
                        params: {
                            id: {dynamic: true}
                        },

                        views: {
                            list: eProductionDraftList,
                            workspace: {
                                templateUrl: '/assets/app/shared/controllers/eProductionDraft/template.html?{{onfileApplicationVersion}}',
                                controller: 'eProductionDraftCtrl'
                            }
                        }
                    })
                    .state('eProductionDrafts', {
                        url: '/eproduction/drafts',
                        views: {
                            list: eProductionDraftList
                        }
                    })
                    .state('eProductionSubmittedView', {
                        url: '/eproduction/:id',
                        params: {
                            id: {dynamic: true}
                        },

                        views: {
                            list: eProductionSubmittedList,
                            workspace: {
                                templateUrl: '/assets/app/components/eProduction/submitted/submitted.html?{{onfileApplicationVersion}}',
                                controller: 'SubmittedEProductionCtrl'
                            }
                        }
                    })

            })($stateProvider);

            // Filings
            (function ($stateProvider) {
                $stateProvider
                    .state('filings', {
                        url: '/filings',

                        views: {
                            list: servicesList,
                            workspace: {
                                templateUrl: '/assets/app/components/eFilings/index/index.html?{{onfileApplicationVersion}}',
                                controller: 'IndexEFilingsCtrl'
                            }
                        }
                    })
                    .state('recentFilings', {
                        url: '/filings/recent?id',
                        params: {
                            id: { dynamic: true }
                        },

                        views: {
                            list: {
                                templateUrl: '/assets/app/shared/controllers/recentFilingsList/template.html?{{onfileApplicationVersion}}',
                                controller: 'RecentFilingsListCtrl'
                            },
                            workspace: {
                                controller: 'EServiceCtrl',
                                templateUrl: '/assets/app/components/eServices/view/view.html?{{onfileApplicationVersion}}'
                            }
                        }
                    })
                    .state('searchFilings', {
                        url: '/filings/search?id',
                        params: {
                            id: { dynamic: true }
                        },

                        views: {
                            list: {
                                templateUrl: '/assets/app/shared/controllers/searchFilingsList/template.html?{{onfileApplicationVersion}}',
                                controller: 'SearchFilingsListCtrl'
                            },
                            workspace: {
                                controller: 'EServiceCtrl',
                                templateUrl: '/assets/app/components/eServices/view/view.html?{{onfileApplicationVersion}}'
                            }
                        }
                    })
                    .state('filingCreate', {
                        url: '/filings/create?caseId&courtId&currentTab&draftId&reminderId&envelopeId&resubmitFilingId',
                        params: {
                            caseId: {dynamic: true},
                            courtId: {dynamic: true},
                            currentTab: {dynamic: true},
                            draftId: {dynamic: true},
                            reminderId: {dynamic: true},
                            envelopeId: {dynamic: true},
                            resubmitFilingId: {dynamic: true}
                        },

                        views: {
                            workspace: {
                                controller: 'CreateFilingCtrl',
                                templateUrl: '/assets/app/components/eFilings/create/create.html?{{onfileApplicationVersion}}'
                            }
                        }
                    })
                    .state('filingCreate.accordion', {
                        views: {
                            partyInformation: getPartyInformationModules(),

                            serviceContacts: {
                                templateUrl: '/assets/app/shared/controllers/serviceContacts/_create-service-contacts.html?{{onfileApplicationVersion}}',
                                controller: 'CreateServiceContactCtrl'
                            },
                            documentFilings: {
                                templateUrl: '/assets/app/shared/controllers/documentFilings/_document-filings.html?{{onfileApplicationVersion}}',
                                controller: 'DocumentFilingCtrl'
                            },
                            reviewAndSubmit: {
                                templateUrl: '/assets/app/shared/controllers/reviewCase/_create-review-submit.html?{{onfileApplicationVersion}}',
                                controller: 'ReviewCtrl'
                            }
                        }
                    })

                    .state('filingCreate.accordion.newParty', {
                        views: getPartyFormViews()
                    })

                    .state('filingsView', {
                        url: '/filings/:id/',
                        params: {
                            id: {dynamic: false}
                        },

                        views: {
                            list: servicesList,
                            workspace: {
                                controller: 'EServiceCtrl',
                                templateUrl: '/assets/app/components/eServices/view/view.html?{{onfileApplicationVersion}}'
                            }
                        }
                    })
                    .state('draftFilings', {
                        url: '/filing/drafts',

                        views: {
                            list: efilingsDraftList
                        }
                    })

                    .state('draftFilingsView', {
                        url: '/filing/draft/:id',

                        params: {
                            id: {dynamic: true}
                        },

                        views: {
                            list: efilingsDraftList,
                            workspace: {
                                templateUrl: '/assets/app/shared/controllers/efilingDraft/template.html?{{onfileApplicationVersion}}',
                                controller: 'EFilingDraftCtrl'
                            }
                        }
                    });
            })($stateProvider);

            // Cases
            (function ($stateProvider) {
                $stateProvider
                    .state('cases', {
                        url: '/cases',

                        views: {
                            list: casesList,
                            workspace: {
                                templateUrl: '/assets/app/components/eCases/index/index.html?{{onfileApplicationVersion}}',
                                controller: 'IndexECaseCtrl'
                            }
                        }
                    })

                    .state('draftCases', {
                        url: '/cases/drafts',

                        views: {
                            list: casesDraftList
                        }
                    })

                    .state('draftCasesView', {
                        url: '/cases/draft/:id',

                        params: {
                            id: {dynamic: true}
                        },

                        views: {
                            list: casesDraftList,
                            workspace: {
                                templateUrl: '/assets/app/shared/controllers/caseDraft/template.html?{{onfileApplicationVersion}}',
                                controller: 'CaseDraftCtrl'
                            }
                        }
                    })

                    .state('caseCreate', {
                        url: '/cases/create?courtId&caseDocketId&draftId&reminderId&envelopeId&resubmitFilingId',
                        params: {
                            courtId: {dynamic: true},
                            caseDocketId: {dynamic: true},
                            draftId: {dynamic: true},
                            reminderId: {dynamic: true},
                            envelopeId: {dynamic: true},
                            resubmitFilingId: {dynamic: true}
                        },
                        views: {
                            workspace: {
                                controller: 'CreateCaseCtrl',
                                templateUrl: '/assets/app/components/eCases/create/create.html?{{onfileApplicationVersion}}'
                            }
                        }
                    })

                    .state('caseCreate.accordion', {
                        views: {
                            caseInformation: {
                                templateUrl: '/assets/app/components/eCases/create/_partials/caseInformation/_case-information.html?{{onfileApplicationVersion}}',
                                controller: 'CaseInformationCtrl'
                            },

                            serviceContacts: {
                                templateUrl: '/assets/app/shared/controllers/serviceContacts/_create-service-contacts.html?{{onfileApplicationVersion}}',
                                controller: 'CreateServiceContactCtrl'
                            },

                            partyInformation: getPartyInformationModules(),

                            documentFilings: {
                                templateUrl: '/assets/app/shared/controllers/documentFilings/_document-filings.html?{{onfileApplicationVersion}}',
                                controller: 'DocumentFilingCtrl'
                            },

                            review: {
                                templateUrl: '/assets/app/shared/controllers/reviewCase/_create-review-submit.html?{{onfileApplicationVersion}}',
                                controller: 'ReviewCaseCtrl'
                            }
                        }
                    })

                    .state('caseCreate.accordion.newParty', {
                        views: getPartyFormViews()
                    })

                    .state('caseSearch', {
                        url: '/cases/search',

                        views: {
                            list: casesList,
                            workspace: {
                                controller: 'SearchCasesCtrl',
                                templateUrl: '/assets/app/components/eCases/search/index.html?{{onfileApplicationVersion}}'
                            }
                        }
                    })

                    .state('caseView', {
                        url: '/cases/:id',
                        params: {
                            id: {dynamic: true}
                        },

                        views: {
                            list: casesList,
                            workspace: {
                                controller: 'ECaseCtrl',
                                templateUrl: '/assets/app/components/eCases/view/view.html?{{onfileApplicationVersion}}'
                            }
                        }
                    })
            })($stateProvider);

            $stateProvider
                .state('auth', {
                    url: '/guest',

                    views: {
                        workspace: {
                            controller: 'AuthCtrl',
                            templateUrl: '/assets/app/components/eProduction/Auth/auth.html?{{onfileApplicationVersion}}'
                        }
                    }
                })

                .state('files', {
                    url: '/files/index',

                    views: {
                        navigation: {
                            controller: 'NavigationCtrl',
                            templateUrl: '/assets/app/components/eProduction/Navigation/navigation.html?{{onfileApplicationVersion}}'
                        },
                        workspace: {
                            controller: 'IndexFilesCtrl',
                            templateUrl: '/assets/app/components/eProduction/Files/index/index.html?{{onfileApplicationVersion}}'
                        }
                    }
                });

            //Token generation
            (function ($stateProvider) {
                $stateProvider
                    .state('token', {
                        url: '/token',
                        views: {
                            workspace: {
                                templateUrl: '/assets/app/components/token/index/view.html?{{onfileApplicationVersion}}',
                                controller: 'TokenCtrl'
                            }
                        }
                    });
            })($stateProvider);

            // Impersonation
            (function ($stateProvider) {
                $stateProvider
                    .state('impersonation', {
                        url: '/impersonation',
                        views: {
                            workspace: {
                                templateUrl: '/assets/app/components/impersonation/index/index.html?{{onfileApplicationVersion}}',
                                controller: 'ImpersonationCtrl'
                            }
                        }
                    });
            })($stateProvider);

            //Login
            (function ($stateProvider) {
                $stateProvider
                    .state('login', {
                        url: '/login',
                        views: {
                            workspace: {
                                templateUrl: '/assets/app/components/login/index.html?{{onfileApplicationVersion}}',
                                controller: 'LoginCtrl'
                            }
                        }
                    });
            })($stateProvider);

            // Reminders
            (function ($stateProvider) {
                const sidebarList = {
                    templateUrl: '/assets/app/components/reminders/sidebar/sidebar.html?{{onfileApplicationVersion}}',
                    controller: 'RemindersSidebarCtrl'
                };

                $stateProvider
                    .state('reminders', {
                        url: '/reminders',

                        views: {
                            list: sidebarList,
                            workspace: {
                                templateUrl: '/assets/app/components/reminders/index/index.html?{{onfileApplicationVersion}}'
                            }
                        }
                    })

                    .state('viewReminder', {
                        url: '/reminders/:id',
                        params: {
                            id: {dynamic: true}
                        },

                        views: {
                            list: sidebarList,
                            workspace: {
                                templateUrl: '/assets/app/components/reminders/view/view.html?{{onfileApplicationVersion}}',
                                controller: 'ViewReminderCtrl'
                            }
                        }
                    })
            })($stateProvider);

            // Text alert
            (function ($stateProvider) {
                $stateProvider
                    .state('textAlertsSettings', {
                        url: '/settings/alerts',

                        views: {
                            workspace: {
                                templateUrl: '/assets/app/components/textNotification/settings/settings.html?{{onfileApplicationVersion}}',
                                controller: 'TextNotificationSettingsCtrl'
                            }
                        }
                    })
            })($stateProvider);

            // Text alert
            (function ($stateProvider) {
                $stateProvider
                    .state('createBates', {
                        url: '/bates/create',

                        views: {
                            workspace: {
                                templateUrl: '/assets/app/components/bates/create/create.html?{{onfileApplicationVersion}}',
                                controller: 'CreateBatesCtrl'
                            }
                        }
                    })
                    .state('createBates.accordion', {
                        views: {
                            schema: {
                                templateUrl: '/assets/app/components/bates/create/_partials/schema/schema.html?{{onfileApplicationVersion}}',
                                controller: 'BatesSchemaCtrl'
                            },
                            files: {
                                templateUrl: '/assets/app/components/bates/create/_partials/files/files.html?{{onfileApplicationVersion}}',
                                controller: 'BatesFilesCtrl'
                            },
                            download: {
                                templateUrl: '/assets/app/components/bates/create/_partials/download/download.html?{{onfileApplicationVersion}}',
                                controller: 'BatesDownloadCtrl'
                            }
                        }
                    })
            })($stateProvider);

            // Reports
            (function ($stateProvider) {
                $stateProvider
                    .state('reports', {
                        url: '/reports',

                        views: {
                            workspace: {
                                templateUrl: '/assets/app/components/reports/template.html?{{onfileApplicationVersion}}',
                                controller: 'ReportsCtrl'
                            }
                        }
                    });
            })($stateProvider);

            // Info
            (function ($stateProvider) {
                $stateProvider
                    .state('info', {
                        url: '/info',

                        views: {
                            workspace: {
                                templateUrl: '/assets/app/components/info/template.html?{{onfileApplicationVersion}}',
                                controller: 'InfoCtrl'
                            }
                        }
                    });
            })($stateProvider);

            //Login
            (function ($stateProvider) {
                $stateProvider
                    .state('requestKey', {
                        url: '/request-key',
                        views: {
                            workspace: {
                                templateUrl: '/assets/app/components/requestKey/template.html?' +
                                    '{{onfileApplicationVersion}}',
                                controller: 'RequestKeyCtrl'
                            }
                        }
                    })
                    .state('requestKeySuccess', {
                        url: '/request-key/success',
                        views: {
                            workspace: {
                                templateUrl: '/assets/app/components/requestKeySuccess/template.html?' +
                                    '{{onfileApplicationVersion}}',
                                controller: 'RequestKeySuccessCtrl'
                            }
                        }
                    });
            })($stateProvider);
        }
    ])
})(angular.module('onfileApp'));
