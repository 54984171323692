;(function (app) {
    app.service('CaseNameService', [
        'CaseNameResource', 'EnvironmentService', 'FirebaseService',
        'EServiceResource', 'CaseResource', 'CaseService',

        function (CaseNameResource, EnvironmentService, FirebaseService,
                  EServiceResource, CaseResource, CaseService) {
            const CASE_NAME_UNAVAILABLE = 'Name Unavailable';

            this.CASE_NAME_UNAVAILABLE = CASE_NAME_UNAVAILABLE;

            this.decorate = function (item, map) {
                const sample = {
                    id: null,
                    caseNameOriginal: null,
                    caseNameComposed: null,
                    caseStatus: null
                };
                map = map || { caseId: 'case_id', filingId: 'id' };

                return CaseNameResource.get({ id: item[map.caseId] })
                    .$promise
                    .then(function (obj) {
                        if (obj && obj.caseNameOriginal) {
                            return obj.caseNameOriginal;
                        }

                        return getOriginalCaseName(item[map.caseId]);
                    })
                    .then(function (originalCaseName) {
                        if (originalCaseName) {
                            throw new NotReportedError({ caseNameOriginal: originalCaseName });
                        }

                        return getComposedCaseName(item[map.caseId]);
                    })
                    .then(function (composedCaseName) {
                        if (composedCaseName) {
                            throw new NotReportedError({ caseNameComposed: composedCaseName });
                        }

                        return getCaseNameFromFilingDetails(item[map.caseId]);
                    })
                    .then(function (caseNameFromFilingDetails) {
                        if (caseNameFromFilingDetails) {
                            throw new NotReportedError({ caseNameComposed: caseNameFromFilingDetails });
                        }

                        return getCaseNameFromFiling(item[map.filingId]);
                    })
                    .then(function (caseNameFromFiling) {
                        if (caseNameFromFiling) {
                            throw new NotReportedError({ caseNameComposed: caseNameFromFiling });
                        }

                        return null;
                    })
                    .catch(function (e) {
                        sample.id = item[map.caseId];

                        if (NotReportedError.prototype.isPrototypeOf(e)) {
                            var caseName;

                            if (e.caseNameOriginal) {
                                caseName = sample.caseNameOriginal = e.caseNameOriginal;
                            } else if (e.caseNameComposed) {
                                caseName = sample.caseNameComposed = e.caseNameComposed;
                            }

                            saveCaseName(sample);

                            return caseName;
                        }

                        // some other error
                        throw e;
                    })
                    .then(function (caseName) {
                        caseName = caseName ? caseName.trim() : caseName;
                        item.case_title = item.case_title_composed = caseName || CASE_NAME_UNAVAILABLE;
                    });
            };

            const getCaseNameFromFiling = function (filingId) {
                return EServiceResource.get({ id: filingId })
                    .$promise
                    .then(function (filing) {
                        const caseName = filing.case_title;

                        if (isNameEmpty(caseName)) {
                            return null;
                        }

                        return caseName;
                    })
            };

            const getCaseNameFromFilingDetails = function (filingId) {
                return EServiceResource.get({ id: filingId, firebaseDetail: true })
                    .$promise
                    .then(function (filing) {
                        const caseName = filing.case_title;

                        if (isNameEmpty(caseName)) {
                            return null;
                        }

                        return caseName;
                    })
            };

            const getOriginalCaseName = function (caseId) {
                return CaseResource.get({ id: caseId })
                    .$promise
                    .then(function (eCase) {
                        const caseName = eCase.title;

                        if (isNameEmpty(caseName)) {
                            return null;
                        }

                        return caseName;
                    })
            };

            const getComposedCaseName = function (caseId) {
                return CaseResource.get({ id: caseId })
                    .$promise
                    .then(function (eCase) {
                        return CaseService.composeCaseName(eCase, true);
                    })
            };

            const isNameEmpty = function (caseName) {
                if (!caseName) {
                    return true;
                }

                caseName = caseName.trim().toLowerCase();

                return (!caseName || caseName === CASE_NAME_UNAVAILABLE.toLowerCase());

            };

            const saveCaseName = function (props) {
                return CaseNameResource.save(props);
            };
        }
    ]);
})(angular.module('onfileApp'));