// /**
//  * Created by murtaza on 5/13/17.
//  */
// ga('send', {
//     hitType: 'event',
//     eventCategory: 'Videos',
//     eventAction: 'play',
//     eventLabel: 'Fall Campaign'
// });


;(function (app) {
    app.service('GAService', ['$window', 'EnvironmentService',
        function ($window, EnvironmentService) {
            const addEvent = function (hitType, category, action, label) {
                if($window.ga)
                $window.ga('send',
                    {
                        hitType: hitType,
                        eventCategory: category,
                        eventAction: action,
                        eventLabel: label
                    });
            };

            this.addNewCase = function (newCaseEnvelope, newCaseResponse) {
                addEvent("NewCase", EnvironmentService.firm_id(), EnvironmentService.user_name(), newCaseResponse.id);
            };

            this.addNewEFiling = function (newEFiling, newEFilingResponse) {
                addEvent("NewEFiling", EnvironmentService.firm_id(), EnvironmentService.user_name(), newEFilingResponse.id);
            };

            this.addNewEService = function (eService, newEServiceResponse) {
                addEvent("NewEService", EnvironmentService.firm_id(), EnvironmentService.user_name(), newEServiceResponse.id);
            };

            this.addNewEProduction = function (eProduction, newEProductionResponse) {
                addEvent("NewEProduction", EnvironmentService.firm_id(), EnvironmentService.user_name(), newEProductionResponse.id);
            };
        }
    ]);
})(angular.module('onfileApp'));

