;(function (app) {
  app.controller('CasesDraftSidebarCtrl', [
    'FirebaseService',
    '$scope', '$rootScope', '$stateParams',

    function (
      FirebaseService,
      $, $rootScope, $stateParams
    ) {
      $rootScope.showList = true;
      //$rootScope.currentPageType = 'Cases Drafts';

      $rootScope.casesDrafts = null;

      angular.extend($rootScope, {
        activeDraftId: $stateParams.id
      });

      // Callback function which is called when URL state changes.
      this.uiOnParamsChanged = function (changedParams) {
        // If current active eService ID is changed - refresh variable for a view.
        if (changedParams.hasOwnProperty('id'))
          $rootScope.activeDraftId = changedParams.id;
      };

      FirebaseService.getIdChannel('auth_users', 'cases_drafts')
        .then(function (drafts) {
          $rootScope.casesDrafts = drafts;
        });
    }
  ])
})(angular.module('onfileApp'));