(function (app) {
    app.factory('SiteConfigurationService', [

        'FirebaseService', '$q', 'EnvironmentService',
        function SiteConfigurationService(FirebaseService, $q, EnvironmentService) {

            var base = this;

            this.getVisibilityRules = function () {
                return FirebaseService.getNode('/configuration/visibilityRules').$loaded();
            };

            this.getRequiredRules = function () {
                return FirebaseService.getNode('/configuration/requiredRules').$loaded();
            };

            this.getString = function (key) {
                var hasRequestedKey = this._configuration &&
                    this._configuration.hasOwnProperty('strings') &&
                    this._configuration.strings.hasOwnProperty(key);

                return hasRequestedKey ? this._configuration.strings[key] : key;
            };

            this.getConfigurationForPage = function (pageName) {
                var deferred = $q.defer();

                if (this._configuration === undefined) {
                    load().then(function (configuration) {
                        if (configuration === undefined || configuration.$value === null) {
                            deferred.resolve({
                                'header': 'Welcome to OnFileSystems',
                                'header_description': '',
                                'video_url': ''
                            });
                        }

                        base._configuration = configuration;
                        var item = configuration['pages'][pageName];

                        if (item === null || item === undefined)
                            item = configuration['pages']['default'];

                        deferred.resolve(item);
                    });
                }
                else
                    deferred.resolve(this._configuration['pages'][pageName]);

                return deferred.promise;
            };

            var messages = {
                texas: {
                    service_contact_note: 'Search public eFile Texas contact list:',
                    lockVerbiage: 'Click to lock in entries for Filer Type and Payment Account fields'
                },
                illinois: {
                    service_contact_note: 'Search public eFile Illinois contact list:',
                    lockVerbiage: 'Click to lock in entries for Payment Account field'
                },
                california: {
                    service_contact_note: 'Search public eFile California contact list:',
                    lockVerbiage: 'Click to lock in entries for Payment Account field'
                }
            };

            this.getContent = function (key) {
                var market = EnvironmentService.market();
                if (messages[market] !== null && messages[market][key] !== null)
                    return messages[market][key];
                else
                    return '%%' + key + '%%';
            };

            this.getUserGuideLink = function () {
                var market = EnvironmentService.market();

                return 'https://s3.us-east-2.amazonaws.com/ofs-assets/' + market + '/UserGuide.pdf';
            };

            function load() {
                var defer = $q.defer();
                var ref = FirebaseService.getNode('configuration');

                //$q.when(ref, function (node) {
                ref.$loaded().then(function (configuration) {
                    //console.log('configuration received : ' + JSON.stringify(configuration));
                    defer.resolve(configuration);
                });
                //});

                return defer.promise;
            }

            return this;
        }

    ]);
})(angular.module('onfileApp'));
