;(function (app) {
    app.service('RefreshDataService', [
        'FirebaseService', 'RefreshDataResource', 'EnvironmentService',
        '$interval', '$window', 'dataRefreshSeconds',
        'EmailService', 'emailsToNotifyOnceOnBoardingFinished', 'FilingDocumentsResource',

        function (
            FirebaseService, RefreshDataResource, EnvironmentService,
            $interval, $window, dataRefreshSeconds,
            EmailService, emailsToNotifyOnceOnBoardingFinished, FilingDocumentsResource
        ) {
            var firmId = EnvironmentService.firm_id();
            var userId = EnvironmentService.userId();
            var isAdmin = EnvironmentService.isAdmin();

            const refreshInterval = (dataRefreshSeconds || 1) * 1000;

            const getLastRefreshTimestamp = function () {
                return +($window.localStorage['lastRefreshTimestamp'] || 0);
            };

            const setLastRefreshTimestamp = function (lastRefreshTimestamp) {
                $window.localStorage['lastRefreshTimestamp'] = lastRefreshTimestamp;
            };

            const onBoard = function () {
                return RefreshDataResource.onBoard()
                    .$promise
                    .then(function () {
                        setLastRefreshTimestamp( +(new Date()) );
                    })
                    .then(function () {
                        watchOnBoardingNode();
                    });
            };

            const watchOnBoardingNode = function () {
                const role = isAdmin ? 'admins' : 'users';
                const path = '/filings/' + firmId + '/onboarded/' + role + '/' + userId;

                return FirebaseService.getNode(path)
                    .$loaded()
                    .then(function (node) {
                        node.$watch(function () {
                            if (node.$value === true) {
                                sendEmailSuccessfulOnBoarding();
                            }
                        });
                    });
            };

            const sendEmailSuccessfulOnBoarding = function () {
                return emailsToNotifyOnceOnBoardingFinished
                    .map(function (email) {
                        const data = {
                            from: 'Onfile',
                            when_to_send: 0,
                            to: email,
                            subject: 'OnBoarding finished'
                        };
                        const valuesToReplace = [
                            {
                                key: 'userId',
                                value: EnvironmentService.userId()
                            }, {
                                key: 'userName',
                                value: EnvironmentService.user_name()
                            }, {
                                key: 'firm',
                                value: JSON.stringify(EnvironmentService.getFirmInfo())
                            }
                        ];

                        return EmailService.sendEmail(data, 'onboarded.html', valuesToReplace);
                    });
            };

            const refresh = function () {

                var isRefreshInProcess = false;
                $interval(function () {
                    const lastRefreshTimestamp = getLastRefreshTimestamp();
                    const currentTimestamp = +(new Date());

                    if (isRefreshInProcess) {
                        return;
                    }

                    if ((currentTimestamp - lastRefreshTimestamp) > refreshInterval) {
                        isRefreshInProcess = true;

                        RefreshDataResource.refresh()
                            .$promise
                            .then(function () {
                                isRefreshInProcess = false;
                                $window.localStorage['lastRefreshTimestamp'] = +(new Date());
                            });

                        FilingDocumentsResource.synchronize();
                    }
                }, 500);
            };

            this.isOnBoarded = function () {
                return FirebaseService
                    .auth()
                    .then(function () {
                        return FirebaseService
                            .getNode('/filings/' + firmId + '/onboarded/users/' + userId)
                            .$loaded();
                    })
                    .then(function (node) {
                        if (node.$value === true) {
                            return null;
                        }

                        return FirebaseService
                            .getNodeAsArray('/filings/' + firmId + '/onboarded/admins')
                            .$loaded();
                    })
                    .then(function (res) {
                        // if User is OnBoarded
                        if (res === null) {
                            return true;
                        }

                        if (res !== null) {
                            for (var i = 0; i < res.length; i++) {
                                // If Admin is unBoarded.
                                if (res[i].$value === true) {
                                    return true;
                                }
                            }
                        }

                        return false;
                    });
            };

            this.forceRefresh = function () {
                RefreshDataResource.refresh({
                    dateFrom: moment().subtract(1, 'day').format('MM/DD/YYYY'),
                    force: true
                }).$promise
                    .then(function () {
                        $window.localStorage['lastRefreshTimestamp'] = +(new Date());
                    });

                FilingDocumentsResource.synchronize();
            };

            this.watch = function () {
                if (!userId) {
                    return false;
                }

                this.isOnBoarded()
                    .then(function (isOnBoarded) {
                        if (!isOnBoarded) {
                            return onBoard();
                        }
                    })
                    .then(function () {
                        return refresh();
                    });
            };
        }
    ]);
})(angular.module('onfileApp'));