;(function (app) {
    app.service('ReminderService', [
        'FirebaseService', '$filter', 'ToastrService', 'EmailService', 'EnvironmentService',

        function ReminderService(FirebaseService, $filter, ToastrService, EmailService,EnvironmentService) {
            const STATUS_CREATED = 'created';
            const STATUS_DELETED = 'deleted';

            const CHANEL_NAME = 'reminders';

            const self = this;

            const getNodePath = function () {
                return '/reminders/' + FirebaseService.uid;
            };

            /**
             * @return {Promise}
             */
            this.getAll = function () {
                return FirebaseService.getIdChannel(CHANEL_NAME);
            };

            this.isOverDue = function(date){
                return (new Date(date).setHours(24,0,0,0)) < (new Date()).getTime();
            };

            this.removeExpired = function (all) {
                all.$loaded().then(function (all) {
                    angular.forEach(all, function (reminder) {
                        if (reminder.due_date_timestamp < (new Date()).setDate(-31)) {
                            all.$remove(reminder);
                        }
                    });
                });
            };

            this.create = function (reminder) {
                var dueDateTimestamp = +reminder.due_date;

                ToastrService.showMessageByKey("reminder_created");

                var promise = FirebaseService.addNode(
                    getNodePath(),
                    angular.extend(reminder, {
                        case: reminder.case || null,
                        user_id: FirebaseService.uid || null,
                        due_date_timestamp: dueDateTimestamp || null,
                        status: STATUS_CREATED
                    })
                );

                //TODO: cascade to reminder create;
                promise.then(function (newReminder) {
                    reminder.reminderId = newReminder.key;
                    addEmailReminder(reminder);
                });

                return promise;
            };

            /**
             * @param id
             * @return {Promise}
             */
            this.get = function (id) {
                return FirebaseService.getIdChannelAsObject(CHANEL_NAME, id);
            };

            this.save = function (reminder) {
                var savePromise = reminder.$save();
                //TODO: should we chain ?

                return savePromise;
            };

            this.delete = function (reminder) {
                //delete email
                EmailService.deleteEmail("reminderId",reminder.$id);
                ToastrService.showMessageByKey("reminder_deleted");
                return reminder.$remove();
            };

            this.markDismissed = function (id) {
                return this.get(id)
                    .then(function (reminder) {
                        reminder.dismissed = true;

                        return self.save(reminder);
                    })
            };

            this.changeStatus = function (reminder, status) {
                return angular.extend(reminder, {
                    status: status
                }).$save();
            };

            this.getExpiring = function () {
                return this.getAll()
                    .then(function (reminders) {
                        return reminders.$loaded();
                    })
                    .then(function (reminders) {
                        return $filter('expiringReminder')(reminders);
                    })
            };

            function addEmailReminder(reminder) {
                const hour = 8;
                var notification = {
                    "when_to_send": getSpecificTimeForTheDate(reminder.due_date_timestamp, hour),
                    "subject": reminder.description,
                    "source" : reminder
                };

                reminder.url = EnvironmentService.base_url() + 'reminders/' + reminder.reminderId;
                EmailService.sendEmail(notification, "reminder.html",
                    [
                        {key: "DueDate", value: reminder.due_date},
                        {key: "Description", value: reminder.description},
                        {key: "Type", value: reminder.type},
                        {key: "ReminderId", value: reminder.reminderId},
                        {key: "ReminderUrl", value: reminder.url},
                        {key: "Name", value: EnvironmentService.first_name}
                    ]);
            }

            function getSpecificTimeForTheDate(dueDate , hour){
                return new Date((new Date(dueDate)).getFullYear(),(new Date(dueDate)).getMonth(),(new Date(dueDate)).getDate(), hour).getTime()
            }
        }
    ]);
})(angular.module('onfileApp'));