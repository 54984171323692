(function (app) {
    app.service(
        'PermissionsService',
        [   'FirebaseService',
            'EnvironmentService',
            function (FirebaseService, EnvironmentService) {
                return {
                    getPermissions: function () {
                        var path =
                            '/whitelist/' +
                            EnvironmentService.firm_id() +
                            '/permissions';

                        return FirebaseService
                            .getNode(path)
                            .$loaded();
                    }
                };
            }
        ]);
})(angular.module('onfileApp'));
