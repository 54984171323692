;(function (app) {
    app.factory('FilingTransformer', [
        'TransformerDecorator',
        function (TransformerDecorator) {
            function FilingTransformer() {
                this.getFilerName = function (defaultValue) {
                    var paths = [
                        'original.Envelope.Body.FilingDetailResponseMessage.DocumentSubmitter.EntityFiler.PersonName.PersonGivenName',
                        'original.Envelope.Body.FilingDetailResponseMessage.DocumentSubmitter.EntityFiler.PersonName.PersonMiddleName',
                        'original.Envelope.Body.FilingDetailResponseMessage.DocumentSubmitter.EntityFiler.PersonName.PersonSurName'
                    ];
                    var composed = paths
                        .map(this.getValueByKey)
                        .filter(function (item) {
                            return item;
                        })
                        .join(' ');

                    return composed || defaultValue;
                };

                this.getAttorneyName = function (attorneys) {
                    var attorney;
                    var attorneyId = this.getValueByKey(
                        'original.Envelope.Body.FilingDetailResponseMessage.FilingLeadDocument.DocumentMetadata.FilingAttorneyID.IdentificationID'
                    );

                    if (!attorneyId) {
                        return '';
                    }
                    attorney = attorneys.find(function (attorney) {
                        return attorney && attorney.attorneyID === attorneyId;
                    });
                    if (attorney) {
                        return attorney.firstName + ' ' + attorney.middleName + ' ' + attorney.lastName;
                    }

                    return '';
                };

                this.getPaymentCaptureDate = function () {
                    return this.getValueByKey(
                        'original.Envelope.Body.FilingDetailResponseMessage.FilingAcceptDate.DateTime'
                    ) || this.getValueByKey(
                        'original.Envelope.Body.FilingDetailResponseMessage.FilingSubmissionDate.DateTime'
                    );
                };

                this.getFilingFees = function () {
                    return this.getValueByKey(
                        'original.Envelope.Body.FilingDetailResponseMessage.FilingFees.AllowanceCharge'
                    ) || [];
                };

                this.getEnvelopeFees = function () {
                    return this.getValueByKey(
                        'original.Envelope.Body.FilingDetailResponseMessage.EnvelopeFees.AllowanceCharge'
                    ) || [];
                };
            }

            return {
                import: function (raw) {
                    return TransformerDecorator(
                        new FilingTransformer(),
                        raw,
                        {
                            getCaseName: 'original.Envelope.Body.FilingDetailResponseMessage.CivilCase.CaseTitleText',
                            getEnvelopeId: 'original.Envelope.Body.FilingDetailResponseMessage.DocumentIdentification.0.IdentificationID',
                            getDocketId: 'original.Envelope.Body.FilingDetailResponseMessage.CivilCase.CaseDocketID',
                            getMatter: 'original.Envelope.Body.FilingDetailResponseMessage.FilingLeadDocument.DocumentFileControlID',
                            getFilingType: 'computed.typeCode',
                            getPaymentAccount: 'original.Envelope.Body.FilingDetailResponseMessage.Payment.AccountName',
                            getFilingDescription: 'original.Envelope.Body.FilingDetailResponseMessage.FilingLeadDocument.DocumentDescriptionText',
                            getSubmissionDate: 'original.Envelope.Body.FilingDetailResponseMessage.FilingSubmissionDate.DateTime',
                            getAcceptanceDate: 'original.Envelope.Body.FilingDetailResponseMessage.FilingAcceptDate.DateTime',
                            getStatus: 'original.Envelope.Body.FilingDetailResponseMessage.FilingStatus.FilingStatusCode'
                        }
                    );
                }
            };
        }
    ]);
})(angular.module('onfileApp'));