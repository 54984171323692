;(function (app) {
    app.directive('successPopup', [
        '$rootScope', '$state', '$cacheFactory',

        function ($rootScope, $state, $cacheFactory) {
            return {
                strict: 'A',
                scope: {
                    status: '=',
                    filingId: '=',
                    filingEnvelopeId: '=',
                    resourceType: '@'
                },
                link: function ($) {
                    Object.assign($, {
                        /**
                         * Redirects to filing detail page and refreshes cached filings list
                         * @param id
                         */
                        goToFiling: function (id) {
                            var state = $.resourceType === 'service' ? 'servicesView' : 'filingsView';

                            // Removes Services/Filings list from Cache in order to upload new list with created Filing
                            $cacheFactory.get('$http').remove('/services');

                            // Changing page to created Filing details
                            // to support new firebase might need to revisit
                            $state.transitionTo(state, { id: id, status: 'submitting' });
                        },
                        hide: function () {
                            $.status = false;
                        },
                        goTo: function (route) {
                            return $state.transitionTo(route);
                        }
                    });
                },
                templateUrl: '/assets/app/shared/directives/successPopup/template.html?{{onfileApplicationVersion}}'
            };
        }
    ]);
})(angular.module('onfileApp'));
