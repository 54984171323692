;(function (app) {
    app.controller('CreateCaseCtrl', [
        'NewCaseService', 'CaseResource', 'FirebaseService',
        '$scope', '$rootScope', '$state',
        '$firebaseArray', '$q', 'RefreshDataService',
        'MarketRulesService', 'GAService', 'TextNotificationService',
        'CaseService', 'EnvironmentService',

        function (
            NewCaseService, CaseResource, FirebaseService,
            $, $rootScope, $state,
            $firebaseArray, $q, RefreshDataService,
            MarketRulesService, GAService, TextNotificationService,
            CaseService, EnvironmentService
        ) {
            MarketRulesService.loaded.then(function () {
                $.isServiceContactsEnabled = MarketRulesService.enabled('serviceContacts');
            });


            //for non-indexedCases
            var caseDocketId = $state.params.caseDocketId;
            var courtId = $state.params.courtId;
            var draftId = $state.params.draftId;
            var envelopeId = $state.params.envelopeId;
            var resubmitFilingId = $state.params.resubmitFilingId;
            var transitionParams = {};

            if (draftId) {
                transitionParams.draftId = draftId;
            } else if (envelopeId) {
                transitionParams.envelopeId = envelopeId;
            }

            $rootScope.showList = false;
            $rootScope.serviceType = 'case';
            $rootScope.baseState = 'caseCreate.accordion';

            $.isEProductionPackage = false;
            $.draftCase = null;

            $.case = new NewCaseService();

            var caseInformationForm;
            var initialTabIndex = 0;
            var isCaseInformationFilled = false;

            if (courtId !== undefined)
                $.case.court_code = courtId;

            if (envelopeId !== undefined) {
                $.case.envelope_id = envelopeId;
            }

            if (caseDocketId !== undefined) {
                $.case.caseDocketId = caseDocketId;
                $.case.nonIndexedCaseFiling = true;
            } else {
                $.case.nonIndexedCaseFiling = false;
            }

            $.activeTabIndex = initialTabIndex;

            $rootScope.isCaseInformationStepValid = false;
            $rootScope.isPartiesInformationStageisValid = false;
            $rootScope.isServiceContactStageIsValid = false;
            $rootScope.isDocumentStageIsValid = false;

            var initializeDraft = function () {
                var defer = $q.defer();
                // Creating of a new draft_case object
                FirebaseService
                    .getIdChannel('auth_users', 'cases_drafts')
                    .then(function (drafts) {
                        var draftData = {
                            item: $.case.plainObject(),
                            time: Date.now()
                        };
                        if (resubmitFilingId && resubmitFilingId.length > 9) {
                            draftData.item.resubmittedFrom = resubmitFilingId;
                        }
                        drafts
                            .$add(draftData)
                            .then(function (ref) {
                                draftId = drafts[drafts.length - 1].$id;
                                $.case.draftId = draftId;
                                $.draftCase = $firebaseArray(ref);
                                $q.resolve();
                            });
                    });

                return defer.promise;
            };

            $.calculateTabIndex = function (standard) {
                if ($.isServiceContactsEnabled) {
                    return standard;
                }

                return standard - 1;
            };

            $.$on('caseInformationValidationState', function (event, form) {
                caseInformationForm = form;
                isCaseInformationFilled = !!(form.$valid && form.$submitted);

                $rootScope.isCaseInformationStepValid = isCaseInformationFilled;

                if (isCaseInformationFilled) {
                    $.case = angular.merge({
                        court_code: $.case.court.code,
                        category_code: $.case.category.code,
                        filing_type: 'Initial'
                    }, $.case);

                    $rootScope.$broadcast('CaseChanged', $.case);
                }
            });

            if (draftId) {
                $.case.draftId = draftId;
                var promise = recreateFromDraft();
                $q.when(promise, function () {
                    $state.transitionTo('caseCreate.accordion', transitionParams);
                });
            } else {
                if ($.court && $.court !== null) {
                    initializeDraft();
                }

                $state.transitionTo('caseCreate.accordion', transitionParams);
            }

            $.$watch('activeTabIndex', function (value) {
                //beyond step 1 save drafts
                if (value > 0) {
                    updateCase();
                }

                // if (caseInformationForm && caseInformationForm.hasOwnProperty('$setSubmitted')) {
                //   caseInformationForm.$setSubmitted();
                // }
                //
                // if (value === initialTabIndex || isCaseInformationFilled)
                //   return;
                //
                // $.activeTabIndex = initialTabIndex;

            });

            $.toggleTab = function (index) {
                if (index === 'prev') {
                    $.activeTabIndex--;
                } else if (index === 'next' || index === undefined) {
                    $.activeTabIndex++;
                } else {
                    $.activeTabIndex = index;
                }

                return $.activeTabIndex;
            };

            $.nextTab = function () {
                return ++$.activeTabIndex;
            };

            $.prevTab = function () {
                return --$.activeTabIndex;
            };

            $.updateResubmittedFiling = function (newId) {
                if (!resubmitFilingId || resubmitFilingId.length < 10) {
                    return;
                }

                var nodePath =
                    '/filings/' +
                    EnvironmentService.firm_id() +
                    '/filings/data/' +
                    resubmitFilingId;

                return FirebaseService
                    .updateNode(nodePath, { resubmitted: newId });
            };

            $.createService = function () {
                var filtered = CaseService.prepareBeforeSend( $.case.prepare() );

                CaseResource.save(filtered)
                    .$promise
                    .then(function (res) {
                        if (!res || !res.hasOwnProperty('id')) {
                            throw res;
                        }

                        GAService.addNewCase($.case, res);

                        if ($.case.text_alert_on_change) {
                            TextNotificationService.saveNotificationForFiling(res.id, {case: $.case});
                        }
                        RefreshDataService.forceRefresh();

                        completeTheDraft();
                        // Forming variables for success popup
                        $.creationStatus = true;
                        $.newFilingId = res.id;

                        $.updateResubmittedFiling(res.id);
                    });
            };

            $.isValidToSubmit = function () {
                return true;
            };

            function updateCase() {
                if ($.case.hasOwnProperty('court') && $.case.court !== null) {

                    if ($.draftCase === null) {
                        var promise = initializeDraft();
                        promise.then(function () {
                            updateCase();
                        });
                    } else {
                        var item = $.draftCase.$getRecord('item');
                        var time = $.draftCase.$getRecord('time');

                        item = angular.extend(item, $.case.plainObject());
                        time.$value = Date.now();

                        $.draftCase.$save(item);
                        $.draftCase.$save(time);
                    }
                }
            }

            function completeTheDraft() {
                FirebaseService.getIdChannel('auth_users', 'cases_submitted', true)
                    .then(function (submitted) {

                        //HACK
                        //try this         item = angular.extend(item, $.case.plainObject());

                        var draft = $.draftCase.$getRecord('item');

                        delete draft.$id;
                        delete draft.$priority;
                        draft.parties.forEach(function (item) {
                            delete item.$valid;
                            delete item.$index;
                        });

                        draft.filings.forEach(function (item) {
                            delete item.$valid;
                            delete item.$index;

                            item.docs.forEach(function (item) {
                                delete item.$valid;
                                delete item.$index;
                            });
                        });

                        draft = angular.fromJson(angular.toJson(draft));
                        submitted.push().update({item: draft, time: Date()});
                    });

                if (draftId) {
                    FirebaseService.getIdChannel('auth_users', 'cases_drafts/' + draftId, true)
                        .then(function (drafts) {
                            drafts.remove();
                        });
                }
            }

            function recreateFromDraft() {
                var defer = $q.defer();

                FirebaseService.getIdChannel('auth_users', 'cases_drafts/' + draftId)
                    .then(function (draft) {
                        $.draftCase = draft;
                        $.draftCase.$loaded()
                            .then(function () {
                                $.case = new NewCaseService($.draftCase.$getRecord('item'));
                                $.case.draft = true;

                                defer.resolve();
                                // if ($.case && $.case.hasOwnProperty('court') && $.case.court) {
                                //   $rootScope.forceSelectCourt($.case.court);
                                // }
                            });
                    });

                return defer.promise;
            }
        }
    ]);
})(angular.module('onfileApp'));
