;(function (app) {
    app.factory('SearchQueryComposerBuilder', [
        function () {
            function SearchQueryComposer() {
                this.getQuery = function () {
                    return this.query;
                };

                this.setQuery = function (query) {
                    this.query = query;

                    return this;
                };

                this.flushQueryMust = function () {
                    this.query.message.body.query.bool.must = [];

                    return this;
                };

                this.getCurrentFrom = function () {
                    return this.query.message.body.from || 0;
                };

                this.attachFiler = function (userIds) {
                    var queries;

                    queries = userIds.map(function (userId) {
                        return {
                            match: {
                                'doc.document_submitter.email': userId
                            }
                        };
                    });
                    this.query.message.body.query.bool.must.push({
                        bool: {
                            should: queries
                        }
                    });

                    return this;
                };

                this.attachPagination = function (pagination) {
                    this.query.message.body.size = pagination && pagination.size || 25;
                    this.query.message.body.from = pagination && pagination.from || 0;

                    return this;
                };

                this.attachFilingType = function () {
                    var allowedTypes = ['filing', 'service_and_filing'];
                    var subQuery = allowedTypes.map(function (t) {
                        return { match: { 'doc.type_code': t } };
                    });

                    this.query.message.body.query.bool.must.push({
                        bool: { should: subQuery }
                    });

                    return this;
                };

                this.attachSorting = function (sorting) {
                    if (Array.isArray(sorting) === false) {
                        sorting = [sorting];
                    }

                    if (Array.isArray(this.query.message.body.sort) === false) {
                        this.query.message.body.sort = [];
                    }

                    this.query.message.body.sort = this.query.message.body.sort.concat(
                        this.query.message.body.sort, sorting
                    );

                    return this;
                };

                this.attachFilledDateSorting = function () {
                    this.attachSorting({
                        'doc.document_filed_date': {
                            order: 'desc',
                            missing: '_last'
                        }
                    });

                    return this;
                };

                this.attachStatusCode = function (code) {
                    this.query.message.body.query.bool.must.push({
                        match: {
                            'doc.status_code': code
                        }
                    });

                    return this;
                };

                this.attachEnvironment = function (env) {
                    this.query.message.body.query.bool.must.push({
                        match: {
                            'doc.environment': env
                        }
                    });

                    return this;
                };

                this.attachFirmId = function (firmId) {
                    this.query.message.body.query.bool.must.push({
                        match: {
                            'doc.firmId': firmId
                        }
                    });

                    return this;
                };

                this.attachDateRange = function (dateFrom, dateTo) {
                    var subQuery = {};

                    if (!dateFrom && !dateTo) {
                        return this;
                    }

                    if (dateFrom) {
                        subQuery.gte = dateFrom;
                    }

                    if (dateTo) {
                        subQuery.lte = dateTo;
                    }

                    this.query.message.body.query.bool.must.push({
                        range: {
                            'doc.document_filed_date': subQuery
                        }
                    });

                    return this;
                };

                this.attachCaseNumber = function (search) {
                    this.query.message.body.query.bool.must.push({
                        simple_query_string: {
                            query: '*' + search + '*',
                            fields: ['doc.case_docket_id'],
                            analyze_wildcard: true,
                            default_operator: 'and'
                        }
                    });

                    return this;
                };

                this.attachEnvelopeNumber = function (search) {
                    this.query.message.body.query.bool.must.push({
                        simple_query_string: {
                            query: '*' + search + '*',
                            fields: ['doc.envelope_number'],
                            analyze_wildcard: true,
                            default_operator: 'and'
                        }
                    });

                    return this;
                };

                this.attachReference = function (search) {
                    this.query.message.body.query.bool.must.push({
                        simple_query_string: {
                            query: '*' + search + '*',
                            fields: ['doc.all.DocumentFileControlID'],
                            analyze_wildcard: true,
                            default_operator: 'and'
                        }
                    });

                    return this;
                };

                this.attachCaseTitle = function (search) {
                    this.query.message.body.query.bool.must.push({
                        simple_query_string: {
                            query: '*' + search + '*',
                            fields: ['doc.case_title'],
                            analyze_wildcard: true,
                            default_operator: 'and'
                        }
                    });

                    return this;
                };

                this.attachDescription = function (search) {
                    this.query.message.body.query.bool.must.push({
                        simple_query_string: {
                            query: '*' + search + '*',
                            fields: ['doc.all.DocumentDescriptionText'],
                            analyze_wildcard: true,
                            default_operator: 'and'
                        }
                    });

                    return this;
                };

                this.attachCaseId = function (caseId) {
                    this.query.message.body.query.bool.must.push({
                        match: {
                            'doc.case_id': caseId
                        }
                    });

                    return this;
                };

                this.attachFilingCode = function (search) {
                    this.query.message.body.query.bool.must.push({
                        match: {
                            'doc.filing_code': search
                        }
                    });

                    return this;
                };
            }

            return {
                build: function () {
                    return new SearchQueryComposer();
                }
            };
        }
    ]);
})(angular.module('onfileApp'));
