;(function (app) {
  app.filter('orFilter', function orFilter() {
    return function (array) {
      var result = [];

      if (!array || !array.hasOwnProperty('length'))
        return result;

      for (var i = 0, item; i < array.length; i++) {
        item = array[i];

        for (var j = 1; j < arguments.length; j++) {

          for (var property in arguments[j]) {
            if (!arguments[j].hasOwnProperty(property))
              continue;

            if (item[property] == arguments[j][property]) {
              result.push(item);
              break;
            }
          }

        }
      }

      return result;
    };
  });
})(angular.module('onfileApp'));
