;(function (app) {
    var csvType = 'text/csv;charset=utf-8';

    app.service('CsvExportService', ['$timeout', function ($timeout) {
        this.export = function (items, fields, titles) {
            if (fields === undefined && titles === undefined) {
                return this.exportCompactFormat(items);
            }

            var csvContent = this.appendPrefix(titles.join(',') + "\n");

            items.forEach(function (item, index) {
                var dataString = (function (item, fields) {
                    var field;
                    var mutator;
                    var res = [];

                    for (var i = 0; i < fields.length; i++) {
                        field = fields[i];

                        if (typeof field === 'string') {
                            res.push( item[field] );
                        } else {
                            for (var prop in field) {
                                mutator = field[prop];

                                res.push( mutator(item[prop]) );
                            }
                        }
                    }

                    return res.join(',');
                })(item, fields);

                csvContent += index < items.length ? dataString + "\n" : dataString;
            });

            return csvContent;
        };

        this.exportCompactFormat = function (items) {
            if (!items && items.length === undefined) {
                throw new Error('Items for CSV Export are not defined');
            }

            var titles = Object.keys(items[0]);
            var heading = this.appendPrefix(titles.join(',') + "\n");
            var csvContent = items
                .map(function (o) {
                    return titles.map(function (t) {
                        var item = o[t];
                        if (item && item.indexOf(',') >= 0) {
                            item = '"' + item + '"';
                        }

                        return item || '';
                    }).join(',');
                })
                .join("\n");

            return heading + csvContent;
        };

        this.download = function (csvContent, filename) {
            filename = filename || 'download';
            var isIE = !!navigator.msSaveBlob;

            // For IE
            if (isIE) {
                var blob = new Blob([csvContent], { type: csvType + ';' });

                return navigator.msSaveBlob(blob, filename);
            }

            var encodedUri = encodeURI(csvContent);
            var link = document.createElement('a');

            link.setAttribute('href', encodedUri);
            link.setAttribute('download', filename || 'download');

            document.body.appendChild(link); // Required for FF

            $timeout(function () { link.click(); }, 0);
        };

        this.exportAndDownload = function (options) {
            var content = this.export(options.items, options.fields, options.titles);

            return this.download(content, options.filename);
        };

        this.appendPrefix = function (data) {
            var isIE = !!navigator.msSaveBlob;

            if (isIE) {
                return data;
            }

            return 'data:' + csvType + ',' + data;
        };
    }]);
})(angular.module('onfileApp'));
