;(function (app) {
    app.factory('EFilingResource', [
        '$resource', 'urlEncodedResource',

        function ($resource, urlEncodedResource) {
            return $resource('/filings', {}, {
                save: urlEncodedResource({
                    method: 'POST',
                    url: '/filings/create'
                })
            });
        }]);
})(angular.module('onfileApp'));