;(function (app) {
  app.factory('AntiVirusResource', ['$resource',
    function ($resource) {
      return $resource('/AntiVirus?url=:name', {name : '@name'}, {
        query: {
          method: 'GET',
          isArray: false,
          cache: false
        }
      });
    }]);
})(angular.module('onfileApp'));