(function (app) {
    app.controller('ECasesListCtrl', [
        'EServiceResource', 'CourtResource', 'CaseService',
        '$scope', '$rootScope', '$controller',
        '$stateParams', '$state', 'ElasticSearchResource',
        'SearchQueryComposerBuilder', 'EnvironmentService',

        function (
            EServiceResource, CourtResource, CaseService,
            $, $rootScope, $controller,
            $stateParams, $state, ElasticSearchResource,
            SearchQueryComposerBuilder, EnvironmentService
        ) {
            $.pagination = {
                from: 0,
                size: 25
            };
            $.cases = [];

            $rootScope.search = '';
            $rootScope.loading_message = '';
            $rootScope.hideLoader = true;
            $rootScope.cases = [];
            $rootScope.caseListItemSelected = null;

            $.loadServices = function () {
                var query = SearchQueryComposerBuilder.build()
                    .setQuery(ElasticSearchResource.getQueryObject())
                    .flushQueryMust()
                    .attachFirmId(EnvironmentService.firm_id())
                    .attachPagination($.pagination)
                    .getQuery();

                $rootScope.startLoader({ show: true, message: 'Loading Cases' });

                return ElasticSearchResource.searchES(query, { force: true })
                    .finally(function () {
                        return $rootScope.stopLoader();
                    })
                    .then(function (cases) {
                        return cases.map(function (eCase) {
                            eCase.cardTitle = generateTitle(eCase);

                            return CaseService.populateName(eCase);
                        });
                    })
                    .then(function (cases) {
                        return cases.map(function (eCase) {
                            return CaseService.populateName(eCase);
                        });
                    })
                    .then(function (cases) {
                        $.cases = $.cases.concat(cases);
                        $.pagination.from += cases.length;

                        if (cases.length < $.pagination.size) {
                            $rootScope.hideLoadMore = true;
                        }
                    });
            };

            var generateTitle = function (item) {
                var parts = [
                    item.case_title_composed,
                    item.court_name,
                    item.docket_id,
                    item.case_judge,
                    item.category_text
                ];

                return parts.filter(function (p) { return p; })
                    .join(' ');
            };

            $rootScope.$watch(
                'caseListItemSelected',
                function (caseListItemSelected) {
                    if (caseListItemSelected && caseListItemSelected.id) {
                        $state.transitionTo('caseView', { id: caseListItemSelected.id });
                    }
                }
            );

            // inherits of a behavior from CreateEServiceCtrl
            angular.merge(this, $controller('EServicesListCtrl', { $scope: $ }));

            // Callback function which is called when URL state changes.
            this.uiOnParamsChanged = function (changedParams) {
                // If current active eService ID is changed - refresh variable for a view.
                if (changedParams.hasOwnProperty('id')) {
                    $.caseId = changedParams.id;
                }
            };
            this.uiOnParamsChanged($stateParams);
        }
    ]);
})(angular.module('onfileApp'));