(function (app) {
    app.directive('fileManager', [
        'FileResource',
        function (FileResource) {
            return {
                strict: 'A',
                scope: {
                    path: '=?',
                    additionalData: '=?',
                    onDataReceivedCallback: '&',
                    onFileDeletedCallback: '&',
                    onFileDownloadClickedCallback: '&',
                    onFilePreviewClickedCallback: '&',
                    onFolderDownloadClickedCallback: '&'
                },
                link: function ($) {
                    var fileExplorer;
                    var body = jQuery('body');

                    $.visualize = function (rootPath) {
                        if (fileExplorer === undefined) {
                            fileExplorer = jQuery('.file-explorer');
                        } else {
                            fileExplorer.removeData('fileTree').undelegate('li a', 'click');
                        }

                        fileExplorer.fileTree({
                            script: '/files/index',
                            root: rootPath,
                            additional: $.additionalData
                        });

                        fileExplorer
                            .off('filetreeexpand')
                            .on('filetreeexpand', function (e, data) {
                                var items = fileExplorer.find('li');

                                items.removeClass('expanded');
                                data.li.addClass('expanded');
                            });

                        fileExplorer
                            .off('dataReceived')
                            .on('dataReceived', function (e, data) {
                                compileAngularElement('.icon_get_app');

                                if ($.onDataReceivedCallback && typeof $.onDataReceivedCallback === 'function') {
                                    $.onDataReceivedCallback({ data: data });
                                }
                            });
                    };

                    $.deleteFile = function (path) {
                        return FileResource.delete({ path: path, additional: $.additionalData })
                            .$promise
                            .then(function (data) {
                                if ($.onFileDeletedCallback && typeof $.onFileDeletedCallback === 'function') {
                                    $.onFileDeletedCallback({ data: data });
                                }

                                $.visualize($.path);

                                return data;
                            });
                    };

                    $.$on('filesChanged', function () {
                        $.visualize($.path);
                    });

                    $.$watch('additionalData', function (additionalData) {
                        if (additionalData) {
                            $.visualize($.path);
                        }
                    });

                    attachEventHandler('click', '.size-badge', function () {
                        jQuery(this).closest('a').click();
                    });

                    attachEventHandler('click', '.directory', function (e) {
                        e.stopPropagation();

                        var innerChild;
                        var target = jQuery(e.target);
                        var isDir = target.hasClass('directory');

                        if (isDir === false) {
                            return false;
                        }

                        innerChild = target.children()[0];

                        if (innerChild) {
                            innerChild.click();
                        }
                    });

                    attachEventHandler('click', '[data-file-manager-delete]', function (e) {
                        var path = jQuery(e.target).attr('rel');

                        $.deleteFile(path);
                    });

                    attachEventHandler('click', '[data-file-manager-download]', function (e) {
                        e.stopPropagation();

                        var target = jQuery(e.target);
                        var url = target.attr('data-url');
                        var rel = target.attr('data-rel');

                        $.onFileDownloadClickedCallback({ url: url, rel: rel });
                    });

                    attachEventHandler('click', '[data-file-manager-download-folder]', function (e) {
                        e.stopPropagation();

                        var target = jQuery(e.target);
                        var files = JSON.parse(target.attr('data-files'));

                        $.onFolderDownloadClickedCallback({ files: files });
                    });

                    attachEventHandler('click', '[data-file-manager-preview]', function (e) {
                        e.stopPropagation();

                        var target = jQuery(e.target);
                        var url = target.attr('data-url');
                        var rel = target.attr('data-rel');

                        $.onFilePreviewClickedCallback({ url: url, rel: rel });
                    });

                    function compileAngularElement(elSelector) {
                        var elSelector = (typeof elSelector === 'string') ? elSelector : null;

                        // The new element to be added
                        if (elSelector != null) {
                            var $div = jQuery(elSelector);

                            // The parent of the new element
                            var $target = jQuery('[ng-app]');

                            angular.element($target).injector().invoke(['$compile', function ($compile) {
                                var $scope = angular.element($target).scope();
                                $compile($div)($scope);
                                // Finally, refresh the watch expressions in the new element
                                $scope.$apply();
                            }]);
                        }
                    }

                    function attachEventHandler(event, target, handler) {
                        return body.off(event, target)
                            .on(event, target, handler);
                    }
                },
                templateUrl: '/assets/app/shared/directives/fileManager/template.html?{{onfileApplicationVersion}}'
            };
        }
    ]);
})(angular.module('onfileApp'));
