(function (app) {
  app.factory('DocumentTypeResource', ['$resource',
    function ($resource) {
      return $resource('/documentTypeCodes?court_code=:court_code', {court_code: '@court_code',
            filing_code_id  :'@filing_code_id'}, {
        query: {
          method: 'GET',
          isArray: true,
          cache: true
        }
      });
    }]);
})(angular.module('onfileApp'));