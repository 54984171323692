(function (app) {
  app.factory('OptionalServiceResource', [
    '$resource',
    function ($resource) {
      return $resource('/optionalServices?court_code=:courtCode&filing_code_id=:filingCodeId', {
        courtCode: '@courtCode',
        filingCodeId: '@filingCodeId'
      }, {
        query: {
          method: 'GET',
          isArray: true,
          cache: true
        }
      });
    }
  ]);
})(angular.module('onfileApp'));