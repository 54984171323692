(function (app) {
  app.factory('FirebaseService', [
    'FirebaseTokenResource', '$firebaseArray', '$q', '$firebaseObject',
    function (FirebaseTokenResource, $firebaseArray, $q, $firebaseObject) {
      var factory = function FirebaseService() {
        var _this = this;

        var ref = firebase.database().ref();

        this.uid = null;
        this.token = null;
        this.email = null;
        this.isAuth = false;
        this.emailHash = null;

        // "uid":"4fc2fa40-68c9-40df-983b-c448f1715edf",
        //     "firmId":"95656595-1573-4beb-9176-ef9eb2bdb9ea",
        //     "emailHash":"Z3BvbGV0ZXNAb25maWxlc3lzdGVtcy5jb20=",
        //     "email":"gpoletes@onfilesystems.com"

        const modifyQuery = function (query, options) {
            if (options && angular.isFunction(options)) {
                return options(query);
            }

            if (options && options.hasOwnProperty('orderByChild') && options.orderByChild) {
                query = query.orderByChild(options.orderByChild);
            }

            if (options && options.hasOwnProperty('equalTo') && options.equalTo) {
                query = query.equalTo(options.equalTo);
            }

            return query;
        };

        this.authParams = {
          resource: FirebaseTokenResource.get,
          data: {}
        };

        var authRequest = null;

        this.auth = function (options) {
          var force = options ? !!options.force : false;

          if (authRequest && force === false) {
            return authRequest;
          }

          return authRequest =
            _this.authParams
            .resource(_this.authParams.data)
            .$promise
            .then(function (res) {
              for (var prop in res) {
                if (!res.hasOwnProperty(prop))
                  continue;

                _this[prop] = res[prop];
              }

              return firebase.auth()
                .signInWithCustomToken(res.token)
                .then(function (res) {
                  _this.isAuth = true;
                  authRequest = null;

                  return res;
                });
            });
        };

        function getChannel(channelName, authEntity, node, refOnly) {
          var func = function () {
            var path = channelName + '/' + _this[authEntity];

            if (node) {
              if (node.charAt(0) !== '/') {
                node = '/' + node;
              }

              path += node;
            }

            if (refOnly !== undefined && refOnly)
              return ref.child(path);

            return $firebaseArray(ref.child(path));
          };

          // If user is Auth create a promise and return it.
          if (_this.isAuth) {
            return $q(function (resolve) {
              resolve(func());
            });
          }

          // Otherwise authorise User and return a promise with data.
          return _this.auth().then(func);
        }

        this.getHashChannel = function (channelName, node) {
          return getChannel(channelName, 'emailHash', node);
        };

        this.getIdChannel = function (channelName, node, refOnly) {
          return getChannel(channelName, 'uid', node, refOnly);
        };

        this.getIdChannelAsObject = function (channelName, node) {
          return this.getIdChannel(channelName, node, true).then(function (data) { return $firebaseObject(data) });
        };

        //firmId
        this.getfirmIdChannel = function (channelName, node, refOnly) {
          return getChannel(channelName, 'firmId', node, refOnly);
        };

        this.getNode = function (path, options) {
          const node = modifyQuery(ref.child(path), options);

          return $firebaseObject(node);
        };

        this.getRef = function (path) {
            return ref.child(path);
        };

        this.getNodeAsArray = function (path, options) {
          const node = modifyQuery(ref.child(path), options);

          return $firebaseArray(node);
        };

        this.saveNode = function (path, item) {
          var node = ref.child(path);
          return node.set(item);
        };

        this.updateNode = function (path, item) {
          var node = ref.child(path);
          return node.update(item);
        };

        this.addNode = function addNode(path, item) {
          return ref.child(path).push(item);
        };

        this.getEProductionNode = function (firmId, packageId) {
          return _this.getNode('/eproduction/packages/' + firmId + '/' + packageId);
        };

        this.getEProductionGuestHeaderNode = function (packageId) {
          return _this.getNode('/eproduction/guest_headers/' + packageId);
        };

        this.removeNode = function (path) {
          return this.getNode(path).$remove();
        };

        this.getFilingNode = function (id, status) {
          var key = 'filings/' + id;
          var allowedStatuses = [
            'served', 'submitted', 'submitting',
            'cancelled'
          ];

          if (status) {
            if (allowedStatuses.indexOf(status) === -1) {
              throw new Error('filing status' + status + 'is not allowed');
            }

            key += '/' + status;
          }

          return _this.getNode(key);
        };

        this.purifyObject = function (object) {
          var result = {};

          for (var prop in object) {
            if (object.hasOwnProperty(prop) === false) {
              continue;
            }

            if (prop.indexOf('$') === 0) {
              continue;
            }

            if (object[prop]) {
              result[prop] = object[prop];
            }
          }

          return result;
        };

          this.removeRedundantNodes = function (object) {
              var redundantNodes = [
                  'StopWatch', 'all'
              ];
              object = this.purifyObject(object);

              for (var prop in object) {
                  if (object.hasOwnProperty(prop) === false) {
                      continue;
                  }

                  if (redundantNodes.indexOf(prop) >= 0 || typeof object[prop] === 'function') {
                      delete object[prop];
                      continue;
                  }

                  if (typeof object[prop] === 'object') {
                      object[prop] = this.removeRedundantNodes(object[prop]);
                  }
              }

              return object;
          };

          this.objectExists = function (firebaseObject) {
              if (!firebaseObject) {
                  return false;
              }

              if (firebaseObject && firebaseObject.hasOwnProperty('$value') && firebaseObject.$value === null) {
                  return false;
              }

              return true;
          };

        return this;
      };

      return new factory();
    }
  ]);
})(angular.module('onfileApp'));
