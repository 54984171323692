/* eslint-disable */
(function (app) {
    app.directive('searchCase', [
        'CaseResource', 'CourtResource', 'FilerTypeResource',
        'CaseService', 'PaymentAccountResource', 'CaseCategoryResource',
        'DamageAmountResource', 'ProcedureRemedyResource', 'ServiceContactService',
        'AttorneyResource', '$stateParams', '$state',
        '$q', 'FirebaseService', 'ToastrService',
        'AttorneyService', 'MarketRulesService', '$rootScope',
        'CaseTypeResource', 'FilingCreateCacheService', 'FilerTypeService',
        'EnvironmentService', 'SiteConfigurationService', 'CaseSearchService','ElasticSearchResource',
        'eProductionService',
        function (
            CaseResource, CourtResource, FilerTypeResource,
            CaseService, PaymentAccountResource, CaseCategoryResource,
            DamageAmountResource, ProcedureRemedyResource, ServiceContactService,
            AttorneyResource, $stateParams, $state,
            $q, FirebaseService, ToastrService,
            AttorneyService, MarketRulesService, $rootScope,
            CaseTypeResource, FilingCreateCacheService, FilerTypeService,
            EnvironmentService, SiteConfigurationService, CaseSearchService,ElasticSearchResource,
            eProductionService
        ) {
            return {
                strict: 'A',
                scope: {
                    caseChangedCallback: '&',
                    formChangedCallback: '&',

                    // When case is selected initiates changing of URL state to state, which is stored in this property
                    selectedCaseState: '@',
                    //New case state from app.routes
                    newCaseState: '@',
                    serviceType: '@',
                    containerClass: '@',

                    selectedCaseStateParams: '=?'
                },
                controllerAs: 'vm',
                link: function ($) {
                    angular.extend($, {
                        firstLineCharsLimit: 54,
                        secondLineCharsLimit: 70,
                        debounceMs: 400,
                        nonIndexedSearchResultFailed: false,
                        caseTab: 'case-existing',
                        caseSearchType: 'case-search-case',
                        caseSearchPartyType: '',
                        cases: [],
                        case: {},
                        caseSearch: {
                            case: {
                                number: ''
                            },
                            person: {
                                first_name: '',
                                middle_name: '',
                                last_name: ''
                            },
                            entity: {
                                entity_name: ''
                            },
                            court: {}
                        },
                        foundCases: false,
                        composeCaseName: CaseService.composeCaseName,
                        isEProductionPackage: !!$stateParams.eProdId,
                        types: [],
                        FilingCreateCacheService: FilingCreateCacheService,
                        isNewAttorneyButtonVisible: true,
                        lockVerbiage: SiteConfigurationService.getContent('lockVerbiage'),
                        caseSearchService: CaseSearchService
                    });

                    $.rules = MarketRulesService;
                    $.rules = MarketRulesService;
                    var draftNode = 'efilings_drafts';

                    if ($.serviceType === 'eservice') {
                        draftNode = 'eservices_drafts';
                    } else if ($.serviceType === 'efiling') {
                        draftNode = 'efilings_drafts';
                    }

                    CourtResource.query().$promise
                        .then(function (res) {
                            $.courts = res;
                        });

                    $.loadAttorneys = function (params) {
                        // Loads Firm's attorneys
                        return AttorneyResource.query().$promise
                            .then(function (attorneys) {
                                for (var i = 0; i < attorneys.length; i++) attorneys[i] = new AttorneyService(attorneys[i]);
                                $.attorneys = attorneys;
                            })
                            .then(function () {
                                if (!params || !params.selectedId) {
                                    return $.attorneys;
                                }

                                var selectedAttoerney = $.attorneys.find(function (a) {
                                    return a.attorneyID === params.selectedId;
                                });

                                if (selectedAttoerney) {
                                    $.case.attorney = selectedAttoerney;
                                }

                                return $.attorneys;
                            });
                    };

                    $.loadAttorneys();

                    function LoadOrCreateCase(caseId, courtCode, draftId) {
                        return CaseService
                            .direct(caseId, { with: 'participants,service_contacts,attorneys' })
                            .then(function (tylerCase) {
                                CaseService.updateDetails(tylerCase);
                                return checkCaseForErrors(tylerCase);
                            })
                            .then(function () {
                                if (draftId !== undefined) {
                                    return FirebaseService
                                        .getIdChannel('auth_users', draftNode + '/' + draftId)
                                        .then(function (draft) {
                                            $.draft = draft;

                                            return $.draft.$loaded();
                                        })
                                        .then(function (draft) {
                                            // If there is some data in $.case than extend it with data from draft.
                                            $.case = $.case ?
                                                angular.merge($.case, draft.$getRecord('item')) :
                                                draft.$getRecord('item')
                                            ;
                                            $.case.draft = true;

                                            return $.case;
                                        });
                                }

                                return CaseService.loadWithoutImport(caseId, courtCode)
                                    .then(function (eCase) {
                                        $.case = eCase;
                                        angular.extend($.case, {detailed: true});

                                        return $.case;
                                    });
                            });
                    }

                    /**
                     * Fetching a Case by tracking number and Court code.
                     *
                     * @param caseId
                     * @param courtCode
                     * @returns {*}
                     */
                    function findCase(caseId, courtCode, draftId) {
                        return LoadOrCreateCase(caseId, courtCode, draftId)
                            .then(function (eCase) {
                                return FilerTypeResource
                                    .query({ court_code: courtCode })
                                    .$promise
                                    .then(function (filerTypes) {
                                        if (eCase) {
                                            eCase.filerTypes = filerTypes.hasOwnProperty('length') ? filerTypes : [];
                                            eCase.filerType = FilerTypeService.getDefaultFilerType(filerTypes);
                                        }

                                        return eCase;
                                    });
                            })
                            .then(function (eCase) {
                                CaseCategoryResource.get({
                                    court_code: eCase.court_code,
                                    code: eCase.category_code
                                }).$promise
                                    .then(function (category) {
                                        eCase.category = category;
                                    });

                                // Loads Damage Amount codes
                                DamageAmountResource.query({courtCode: eCase.court_code}).$promise
                                    .then(function (damageAmounts) {
                                        $.damageAmounts = damageAmounts;
                                    });

                                // Loads Procedure Remedy codes
                                ProcedureRemedyResource.query({courtCode: eCase.court_code}).$promise
                                    .then(function (procedureRemedies) {
                                        $.procedureRemedies = procedureRemedies;
                                    });

                                return eCase;
                            })
                            .then(function (eCase) {
                                if (eCase.hasOwnProperty('service_contacts') && eCase.service_contacts != null) {
                                    for (var i = 0; i < eCase.service_contacts.length; i++) {
                                        eCase.service_contacts[i] = new ServiceContactService(eCase.service_contacts[i]);
                                    }
                                } else {
                                    eCase.service_contacts = [];
                                }

                                return eCase;
                            })
                            .then(function (eCase) {
                                // Fetches Payment accounts
                                PaymentAccountResource.query().$promise
                                    .then(function (paymentAccounts) {
                                        if (
                                            paymentAccounts === null || paymentAccounts === undefined ||
                                            paymentAccounts.length === 0
                                        ) {
                                            ToastrService.showMessageByKey('no_payment_account');
                                        }
                                        $.paymentAccounts = paymentAccounts;
                                    });

                                return CaseTypeResource.query({court_code: eCase.court_code})
                                    .$promise
                                    .then(function (types) {
                                        var type = types.find(function (item) {
                                            return item.code === eCase.type_code_id;
                                        });
                                        eCase.type = type && type.name ? type.name : eCase.type;

                                        return eCase;
                                    });
                            })
                            .then(function (eCase) {
                                $.$emit('CaseChanged', $.case);
                                // If there is a callback passed call it
                                $.caseChangedCallback({caseId: eCase.id});

                                FilingCreateCacheService.populateData($.case);

                                return eCase;
                            });
                    }

                    $.hideDropDown = true;

                    $.serviceType = function () {
                        return serviceType;
                    };
                    $.resetScroll = function () {
                        keyCount = 1;
                        scroll = 0;
                    };

                    var keyCount = 1;
                    var scroll = 0;
                    $.handleOtherKey = function (e) {

                        if (e.keyCode == 38) {
                            var current = angular.element('.dropdown-active');
                            var prev = current.prev();
                            if (prev.length > 0) {
                                prev.addClass('dropdown-active');
                                current.removeClass('dropdown-active')
                                if (keyCount % 6 === 0) {
                                    scroll = scroll - 130;
                                    angular.element(angular.element('.dropdown-content')[0]).scrollTop(scroll);
                                }
                            }
                            keyCount--;

                            e.preventDefault();
                        }

                        if (e.keyCode == 40) {
                            var current = angular.element('.dropdown-active');
                            var next = current.next();
                            if (next.length > 0) {
                                next.addClass('dropdown-active');
                                current.removeClass('dropdown-active');
                                if (keyCount % 6 === 0) {
                                    scroll = scroll + 130;
                                    angular.element(angular.element('.dropdown-content')[0]).scrollTop(scroll);
                                }
                            }

                            keyCount++;

                            e.preventDefault();
                        }
                    };

                    $.filterCourts = function (query) {
                        var results = query ? $.courts.filter(createFilterFor(query)) : $.courts;
                        return results;
                    };

                    $.findCourtByCode = function (code) {
                        var foundCourt = $.courts.find(function (court) {
                            return code.indexOf(court.code) >= 0;
                        });

                        return foundCourt && foundCourt.name ? foundCourt.name : '';
                    };

                    function createFilterFor(query) {
                        var lowercaseQuery = angular.lowercase(query);

                        return function filterFn(item) {
                            return (angular.lowercase(item.name).indexOf(lowercaseQuery) === 0);
                        };

                    }

                    $.selectCourt = function (court) {
                        $.caseSearch.court = court;
                        return true;
                    };

                    var waiverTypeNotAllowed = function () {
                        return $state.$current.name.indexOf('service') >= 0;
                    };

                    //for an eService dont display waiver account type
                    $.paymentAccountWaiverTypeNotAllowed = function () {
                        return waiverTypeNotAllowed();
                    };

                    $.filterPaymentAccount = function () {
                        return function (item) {
                            if (item.active && ( !waiverTypeNotAllowed() || item.paymentAccountTypeCode !== 'WV')) {
                                return true;
                            }

                            return false;
                        };
                    };

                    // Function for searching a Court by name
                    $.searchCourt = function (search) {
                        CourtResource.query({search: search}).$promise
                            .then(function (res) {
                                $.courts = res;
                            });
                    };

                    $.isHavingErrors = function (item) {
                        return item && Object.keys(item.$error).length;
                    };

                    var nonIndexedCourt;
                    var nonIndexCaseNumber;
                    // Function for searching od a Case by params
                    $.searchCaseBy = function (searchBy) {
                        var searchData = angular.extend({}, $.caseSearch[searchBy]);
                        var getError = function (cases) {
                            for (var i = 0; i < cases.length; i++) {
                                if (cases[i] && cases[i].error && cases[i].error.code !== undefined) {
                                    return cases[i].error;
                                }
                            }

                            return null;
                        };

                        if ($.caseSearch.court && $.caseSearch.court.hasOwnProperty('code')) {
                            searchData.court_code = $.caseSearch.court.code;
                        }

                        CaseResource.search(searchData).$promise
                            .then(function (cases) {
                                var error = getError(cases);

                                if (cases != null && cases.length == 1 && cases[0] === 'non-index-case-not-found-result') {
                                    $.nonIndexedSearchResultFailed = true;
                                    nonIndexedCourt = $.caseSearch.court.code;
                                    nonIndexCaseNumber = $.caseSearch.case.number;
                                } else if (error && error.alias === 'ERROR_CMS_UNREACHABLE') {
                                    $.foundCases = cases;
                                } else {
                                    $.foundCases = cases;
                                    $.nonIndexedSearchResultFailed = false;
                                    nonIndexedCourt = null;
                                    nonIndexCaseNumber = null;
                                }
                            });
                    };

                    /**
                     * Selects a Case
                     *
                     * @param caseObject
                     * @returns {boolean}
                     */
                    $.selectCase = function (caseObject) {
                        var stateParams = {};

                        if (!caseObject || !$.selectedCaseState) {
                            return false;
                        }

                        if ($.selectedCaseStateParams) {
                            stateParams[$.selectedCaseStateParams.caseId] = caseObject.id;
                        } else {
                            stateParams = {
                                caseId: caseObject.id,
                                courtId: caseObject.court_code
                            };
                        }

                        $state.transitionTo(
                            $.selectedCaseState,
                            angular.extend($stateParams, stateParams)
                        );

                        return true;
                    };

                    $.goToNonIndexedNewCase = function () {
                        $state.transitionTo($.newCaseState, angular.extend($stateParams, {
                            caseDocketId: nonIndexCaseNumber,
                            courtId: nonIndexedCourt
                        }));
                    };

                    // If Case is selected.
                    if (
                        $stateParams.hasOwnProperty('caseId') && $stateParams.hasOwnProperty('courtId') &&
                        $stateParams.caseId && $stateParams.courtId
                    ) {
                        findCase($stateParams.caseId, $stateParams.courtId, $stateParams.draftId);
                    } else if ($stateParams.eProdId && $stateParams.caseId) {
                        eProductionService.loadById($stateParams.eProdId, EnvironmentService.firm_id())
                            .then(function (data) {
                                var caseId;
                                try {
                                    caseId = data.d.batch_header.case_id;
                                } catch (e) {
                                    caseId = $stateParams.caseId;
                                }

                                return CaseService.direct(caseId);
                            })
                            .then(function (eCase) {
                                return $state.transitionTo(
                                    $.selectedCaseState,
                                    angular.extend($stateParams, {
                                        courtId: eCase && eCase.court_code
                                    })
                                );
                            });
                    }

                    $.searchCases = function (e) {
                        //$.cases = $.caseSearchService.search(e.toLowerCase(), 50);
                        return ElasticSearchResource.searchCaseByTitleCombined(e)
                            .then(fixCaseNames);
                    };

                    $.searchCasesByDocketId = function (e) {
                        return ElasticSearchResource.searchCaseByDocketId(e)
                            .then(fixCaseNames);
                    };

                    $.searchCasesByCourt = function (e) {
                        return ElasticSearchResource.searchCaseByCourt(e)
                            .then(fixCaseNames);
                    };

                    $.searchByParticipants = function (e) {
                        return ElasticSearchResource.searchByParticipants(e)
                            .then(fixCaseNames);
                    };

                    $.$watch('existingCase.$valid', function (isValid) {
                        if (isValid === undefined)
                            return;

                        $.formChangedCallback({isValid: isValid});
                    });

                    var checkCaseForErrors = function (tylerCase) {
                        var filter = function (e) {
                            return e.errorCode === parseInt(tylerCase.errors[i].ErrorCode);
                        };

                        if (tylerCase.errors && tylerCase.errors.length) {
                            var error;
                            var isFilingAllowed = true;

                            for (var i = 0; i < tylerCase.errors.length; i++) {
                                error = $rootScope.caseErrorMessages.find(filter);

                                if (error && error.allowFiling === false) {
                                    isFilingAllowed = false;
                                    break;
                                }
                            }

                            $.$emit('CaseHasErrors', {errors: tylerCase.errors});

                            if (isFilingAllowed === false) {
                                throw new Error('Case has errors');
                            }
                        }
                    };

                    var fixCaseNames = function (items) {
                        return items.map(function (item) {
                            item.case_title = $.composeCaseName(item);
                            item.participantsString = CaseService.participantsToString(item);

                            return item;
                        });
                    };

                    $rootScope.$on('NewAttorneyCreated', function (e, data) {
                        $.loadAttorneys({ selectedId: data.id });
                    });

                    $.publicSearch = {
                        docketId: function (params) {

                        }
                    };
                },
                templateUrl: '/assets/app/shared/directives/searchCase/template.html?{{onfileApplicationVersion}}'
            };
        }
    ]);
})(angular.module('onfileApp'));
/* eslint-enable */
