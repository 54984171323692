;(function (app) {
    app.service('FilerService', [
        function () {
            this.composeName = function (user) {
                var parts = [];
                var nameProps = ['firstName', 'lastName'];

                nameProps.map(function (p) {
                    if (user && user[p]) {
                        parts.push(user[p]);
                    }
                });

                if (user && user.email) {
                    parts.push('(' + user.email + ')');
                }

                return parts.join(' ');
            };
        }
    ]);
})(angular.module('onfileApp'));
