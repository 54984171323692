;(function (app) {
  app.controller('EProductionDraftSidebarCtrl', [
    'FirebaseService', 'eProductionService', 'EnvironmentService',
    '$scope', '$rootScope', '$stateParams','$q',
    function (
      FirebaseService, eProductionService, EnvironmentService, $, $rootScope, $stateParams,$q) {
      $rootScope.showList = true;

      $rootScope.eProdDrafts = null;

      angular.extend($rootScope, {
        activeEProdDraft: $stateParams.id
      });

      this.uiOnParamsChanged = function (changedParams) {
        if (changedParams.hasOwnProperty('id'))
          $rootScope.activeEProdDraft = changedParams.id;
      };
      eProductionService.loadDrafts(EnvironmentService.firm_id()).then(function(result){
        if (result == null) {
          return;
        }

        $rootScope.eProdDrafts = result
          .filter(function(draft) {
            try {
              return draft.batch_header.status === "draft";
            } catch (e) {
              return false;
            }

          });
      });
      this.uiOnParamsChanged({id: $stateParams.id});

    }
  ]);
})(angular.module('onfileApp'));
