;(function (app) {
    app.controller('DocumentFilingCtrl', [
        'FilingCodeResource', 'PaymentAccountResource', 'UploadDocumentService',
        'FilingComponentCodeResource', 'DocumentTypeResource',
        'OptionalServiceResource', 'OptionalServiceService', 'AntiVirusResource',
        '$scope', '$rootScope', '$timeout',
        'emailSequencePattern', '$q', 'ToastrService',
        '$window', 'filepickerApikey', 'EnvironmentService',
        'SecurityService', 'CaseService', 'FilingCreateCacheService',
        'DocumentResource', 'PermissionsService', 'defaultDocumentTypeNames',
        'getForbiddenFilenamePattern', 'isHavingErrors',

        function (
            FilingCodeResource, PaymentAccountResource, UploadDocumentService,
            FilingComponentCodeResource, DocumentTypeResource,
            OptionalServiceResource, OptionalServiceService, AntiVirusResource,
            $, $rootScope, $timeout,
            emailSequencePattern, $q, ToastrService,
            $window, filepickerApikey, EnvironmentService,
            SecurityService, CaseService, FilingCreateCacheService,
            DocumentResource, PermissionsService, defaultDocumentTypeNames,
            getForbiddenFilenamePattern, isHavingErrors
        ) {
            var maxSizeMb = 35;
            var maxSize = maxSizeMb * 1024 * 1024;
            var filingComponents = {
                all: [],
                selected: {}
            };
            $.addNewFiling = true;
            // Form validation
            $.isHavingErrors = isHavingErrors;

            angular.extend($, {
                optionalServices: [],
                filings: [],
                filing: {docs: []},
                filingCodes: [],
                paymentAccounts: [],
                showFilePicker: true,
                maxSizeMb: maxSizeMb,
                countTotalFee: OptionalServiceService.countTotalFee,
                emailSequencePattern: emailSequencePattern,
                allowMultiples: false,
                envelopeOverTheLimit: false,
                FilingCreateCacheService: FilingCreateCacheService,
                isMakingFileSearchableAllowed: false,
                showSplitButton: true,

                makeFileSearchable: function (file, index) {
                    var provider = $.getProvider(file);
                    file.searchable = 'in_progress';

                    return SecurityService.getMetadataSecParams()
                        .then(function (secParams) {
                            return $q.all([
                                DocumentResource.makeSearchable({
                                    file: file,
                                    security: secParams
                                }).$promise,

                                SecurityService.getCustomSecParams(['remove', 'store'])
                            ]);
                        })
                        .then(function (data) {
                            var processedFile = data[0];
                            var secParams = data[1];
                            var hasLink = (processedFile && processedFile.hasOwnProperty('searchable_pdf_url'));

                            if (!hasLink) {
                                return false;
                            }

                            if (provider === 'filestack') {
                                var storeParams = Object.assign(
                                    { filename: file.filename },
                                    secParams,
                                    getStoreParams()
                                );

                                return $window.filepicker.storeUrl(
                                    processedFile.searchable_pdf_url,
                                    storeParams,
                                    function (blob) {
                                        // @hack If file is stored manually there is no `container` property returned.
                                        blob.container = EnvironmentService.s3BucketName();
                                        blob.searchable = true;
                                        filestackUploaded([blob], index);
                                    }
                                );
                            }

                            if (provider === null) {
                                return DocumentResource.updateContent(processedFile)
                                    .$promise
                                    .then(function (p) {
                                        p.container = EnvironmentService.s3BucketName();
                                        p.searchable = true;
                                        $.documentUploaded(p, index);
                                    });
                            }
                        });
                }
            });

            PermissionsService.getPermissions()
                .then(function (p) {
                    $.isMakingFileSearchableAllowed = p && p.searchablePdf;
                });

            initController();

            // Computing of a size of uploaded files (in percents)
            var filesFilling = function () {
                var percent;
                var sizeSum;
                var filings = angular.copy($.filings);

                filings.push(angular.copy($.filing));
                sizeSum = (function (filings) {
                    if (!filings || !filings.length) {
                        return 0.0;
                    }

                    return filings.reduce(function (sum, filing) {
                        if (filing && Object.prototype.hasOwnProperty.call(filing, 'docs') && filing.docs.length) {
                            return sum + getSize(filing.docs);
                        }

                        return sum;
                    }, 0.0);
                })(filings);

                percent = (function (sizeSum, maxSize) {
                    var res = Math.min(sizeSum / maxSize * 100, 100);

                    return (res < 1 ? 0 : Math.floor(res));
                })(sizeSum, maxSize);

                return {
                    percent: percent,
                    size: (sizeSum / (1024 * 1024)).toFixed(2)
                };
            };

            $.getProvider = function (file) {
                return file.url_filestack ? 'filestack' : null;
            };

            $.envelopeSize = filesFilling;

            function getFilingCode(name) {
                var filter = $.filingCodes.filter(function(filingCode) {
                    return name === filingCode.name;
                });
                if(filter != null && filter.length > 0 ) {
                    return filter[0];
                }
                return null;
            }

            function fetchFilingCode() {
                var cachedFilingsMeta = FilingCreateCacheService.get('filingsMeta');
                if (cachedFilingsMeta && cachedFilingsMeta.filingCode) {
                    return getFilingCode(cachedFilingsMeta.filingCode.name);
                } else {
                    return null;
                }
            }

            function initController(eCase) {
                if (($.case === undefined || $.case.court === null ) && eCase === undefined)
                    return;

                if (eCase === undefined)
                    eCase = $.case;

                var defaultFilingType = 'Subsequent';
                var filingType = (function (filingType, isNonIndexedCase) {
                    var isOtherType = [defaultFilingType, 'Initial', 'Both'].indexOf(filingType) < 0;

                    return (!filingType || isOtherType || isNonIndexedCase) ?
                        defaultFilingType : filingType;
                })(eCase.filing_type, eCase.nonIndexedCaseFiling);

                FilingCodeResource.query({
                    filing_type: filingType,
                    court_code: eCase.court_code,
                    type_code_id: eCase.type_code_id || eCase.type.code,
                    category_code: eCase.category_code
                }).$promise
                    .then(function (filingCodes) {
                        $.filingCodes = filingCodes;

                        //HACK:
                        var filingCode = fetchFilingCode();
                        if (filingCode) {
                            $.filing.filing_code = filingCode;
                        }
                    })
                    .catch(function () {
                        // @TODO handle error
                    });

                // If there is Filings present in Case and filings array is empty - use them.
                if (
                    $.filings.length <= 0 && eCase.hasOwnProperty('filings') &&
                    eCase.filings && eCase.filings.hasOwnProperty('length') &&
                    eCase.filings.length
                ) {
                    $.filings = eCase.filings;
                }


                FilingComponentCodeResource.query({court_code: eCase.court_code})
                    .$promise
                    .then(function (res) {
                        filingComponents.all = res;
                    });

                var cachedFilingsMeta = FilingCreateCacheService.get('filingsMeta');
                if (cachedFilingsMeta) {
                    $.filing.description = cachedFilingsMeta.description;
                }
            }

            function getFilingComponent(filingcodeid) {

                var selected = {};

                for (var i = 0; i < filingComponents.all.length; i++) {
                    if (filingComponents.all[i].filingcodeid != filingcodeid)
                        continue;

                    if (filingComponents.all[i].name === 'Lead Document') {
                        selected.lead = filingComponents.all[i];
                    } else {
                        selected.attach = filingComponents.all[i];
                    }
                }

                return selected;
            }

            $.validateCourtesyCopies = function () {
                var isValid = $.filing.courtesy_copies === undefined || $.filing.courtesy_copies === '' || $.emailSequencePattern.test($.filing.courtesy_copies);
                $.newFiling.newFilingCourtesyCopies.$setValidity('pattern', isValid);
            };

            // When a Case is selected
            $rootScope.$on('CaseChanged', function (e, eCase) {
                initController(eCase);
            });

            $.$watch('filing.filing_code', function (filingCode) {
                if ($.addOptionalCourtServices === true)
                    $.loadOptionalServices(filingCode);
            });

            $.documentScanned = function () {
                var result = true;
                window.angular.forEach($.case.filings, function (f) {
                    window.angular.forEach(f.docs, function (d) {
                        if (d.is_ok !== true) {
                            result = false;
                        }
                    });
                });
                return result;
            };

            var assignDefaultDocumentType = function (doc) {
                if (doc && $.documentTypes && $.documentTypes.length > 0) {
                    var neededType;
                    var types = $.documentTypes;

                    for (var i = 0; i < defaultDocumentTypeNames.length; i++) {
                        neededType = types.find(function (t) {
                            return t.name.toLowerCase() === defaultDocumentTypeNames[i];
                        });

                        if (neededType) {
                            doc.document_type = neededType;
                            break;
                        }
                    }

                    try {
                        doc.document_type = doc.document_type || types[types.length - 1];
                    } catch (e) {
                        console.error(e);
                    }
                }
            };

            // Callback for Document Uploaded event
            $.documentUploaded = function (doc, index) {
                doc.isNew = true;
                doc.scan_status = 'Scanning';
                doc.file_scanned = false;
                doc.is_ok = false;

                if (index !== undefined) {
                    $.filing.docs[index] = doc;
                } else {
                    $.filing.docs.push(doc);
                }

                $.checkFilesSize();
                assignDefaultDocumentType(doc);

                return AntiVirusResource.query({ name: doc.url })
                    .$promise
                    .then(function (res) {
                        if (res.result) {
                            doc.scan_status = res.result;
                            doc.file_scanned = true;
                            doc.is_ok = res.result === 'Clean';
                        }
                    })
                    .then(function () {
                        $.case = CaseService.compose($.case, $.filings);

                        return $.case;
                    });
            };

            $.envelopeOverLimitMessage = function () {
                return ToastrService.getMessage('envelope_size');
            };

            // Callback for Document uploaded event from Filestack
            var filestackUploaded = function (fpfiles, index) {
                fpfiles.map(function (file) {
                    (new UploadDocumentService()).updateFile(file)
                        .then(function (doc) {
                            doc.isNew = true;
                            doc.scan_status = 'Scanning';
                            doc.file_scanned = false;
                            doc.is_ok = false;

                            if (index !== undefined) {
                                return (new UploadDocumentService())
                                    .removeFile($.filing.docs[index])
                                    .then(function () {
                                        $.filing.docs[index] = doc;

                                        return doc;
                                    });
                            }
                            $.filing.docs.push(doc);

                            return doc;
                        })
                        .then(function (doc) {
                            $.checkFilesSize();

                            assignDefaultDocumentType(doc);

                            return AntiVirusResource.query({name: doc.url})
                                .$promise
                                .then(function (res) {
                                    if (res.result) {
                                        doc.scan_status = res.result;
                                        doc.file_scanned = true;
                                        doc.is_ok = res.result === 'Clean';
                                    }
                                });
                        })
                        .then(function () {
                            $.case = CaseService.compose($.case, $.filings);

                            return $.case;
                        });
                });
            };

            function fillFiling() {
                var lastFiling =
                    $.filings.length && $.filings[$.filings.length-1] ?
                        $.filings[$.filings.length-1] :
                        null;
                var description;
                var filingCode;

                if (lastFiling) {
                    if (lastFiling.description) {
                        description = lastFiling.description;
                    }
                    if (lastFiling.filing_code && lastFiling.filing_code.name) {
                        filingCode = getFilingCode(lastFiling.filing_code.name);
                    }
                } else {
                    var cachedFiling = FilingCreateCacheService.get('filingsMeta');
                    if (cachedFiling && cachedFiling.description) {
                        description = cachedFiling.description;
                    }
                    filingCode = fetchFilingCode();
                }

                if (description) {
                    $.filing.description = description;
                }
                if (filingCode) {
                    $.filing.filing_code = filingCode;
                }
            }

            function resetForm(addNewFiling) {
                if (addNewFiling === undefined) {
                    addNewFiling = true;
                }

                $.filing = {docs: []};

                // Autofill newFiling field
                if ($.FilingCreateCacheService.cacheFilings) {
                    fillFiling();
                }

                // Drop selections of Optional Services
                for (var i = 0; i < $.optionalServices.length; i++) {
                    $.optionalServices[i].selected = false;
                }

                $.newFiling.$setPristine();
                $.addOptionalCourtServices = false;
                $.addNewFiling = addNewFiling;
                $.isEditing = false;
                $.isEditingIndex = -1;
                setAllowMultiples();

                return true;
            }

            function getStoreParams() {
                return {
                    location: 'S3',
                    access: 'public',
                    container: EnvironmentService.s3BucketLocation($.case.draftId)
                };
            }

            $.resetForm = function (addNewFiling) {
                resetForm(addNewFiling);
            };

            $.dragControlListeners = {
                orderChanged: function () {
                    // @TODO handle changing of an Order of Documents
                }
            };

            /**
             * Checks whether Document form is valid
             * @returns {boolean}
             */
            $.isValid = function () {
                var isValid = !!$.newFiling.$valid;
                $rootScope.isDocumentStageIsValid = isValid;

                if (isValid === false) {
                    ToastrService.showMessageByKey('lead_missing_service');
                }

                return isValid;
            };

            // Adds filing to filing list and flushes current filing model
            $.saveFiling = function (split) {
                // if filing form is not opened - skip filing saving
                if (!$.addNewFiling) {
                    return true;
                }

                if ($.isHavingErrors($.newFiling)) {
                    return false;
                }

                // If there are no filings selected - validation fails
                if (!$.filing || !$.filing.docs.length) {
                    ToastrService.showMessageByKey('lead_missing');
                    return false;
                }

                $.filing.optional_services = OptionalServiceService.getSelected($.optionalServices);

                var filing = $.filing;
                // Adds Filing Component information do each Document in the Filing
                for (var i = 0; i < filing.docs.length; i++) {
                    var selected = getFilingComponent(filing.filing_code.code);
                    filing.docs[i].filing_component =
                        (i === 0 || split ? selected.lead : selected.attach);
                }

                // If this filing already exists
                var copy;
                if ($.filing.hasOwnProperty('listIndex') && $.filings.hasOwnProperty($.filing.listIndex)) {
                    copy = window.angular.copy($.filing);
                    $.filings[$.filing.listIndex] = copy;
                } else {
                    $.filing.listIndex = $.filings.length;
                    split ? pushAsSeparateFilings($.filing) : pushFiling($.filing);
                }

                // Close add new filing form
                $.addNewFiling = false;

                // if at least one service selected and filing is then saved, box with selected services stays open
                if (!$.addOptionalCourtServices) {
                    return true;
                }

                for (i = 0; i < $.filings.length; i++) {
                    if ($.filings[i].optional_services) {
                        $.addOptionalCourtServices = true;
                        break;
                    }
                }

                return true;
            };

            var pushFiling = function (filing) {
                var copy = window.angular.copy(filing);
                $.filings.push(copy);

                return $.filings;
            };

            var pushAsSeparateFilings = function (filing) {
                var originalListIndex = isNaN(+filing.listIndex) ? 0 : +filing.listIndex;

                $.filing.docs.map(function (d, i) {
                    $.filings.push(Object.assign({}, filing, {
                        docs: [ d ],
                        listIndex: originalListIndex + i
                    }));
                });

                return $.filings;
            };

            var getSize = function (files) {
                var sizeSum = 0;
                var size = 0;

                if (!files || !files.hasOwnProperty('length') || !files.length) {
                    return 0;
                }

                for (var j = 0; j < files.length; j++) {
                    size = parseFloat(files[j].file_size);
                    size = isNaN(size) ? 0 : size;

                    sizeSum += size;

                }

                return sizeSum;
            };

            var setAllowMultiples = function () {
                $.allowMultiples = filingComponents.selected.hasOwnProperty('attach');

                return $.allowMultiples;
            };

            $.editFiling = function (index) {
                $.isEditingIndex = index;
                $.filing = angular.extend({}, $.filings[index]);
                $.addOptionalCourtServices = $.filing.optional_services !== undefined && $.filing.optional_services.length > 0;

                $.addNewFiling = true;
                $.isEditing = true;

                //happening only in case of drafts

                //loadOptionalServices
                if ($.optionalServices === null || $.optionalServices.length === 0) {
                    var deferred = $q.defer();
                    $.loadOptionalServices($.filing.filing_code, deferred);
                    $q.when(deferred.promise, function () {
                        OptionalServiceService.setSelected($.filing.optional_services, $.optionalServices);
                    });
                }
                else
                    OptionalServiceService.setSelected($.filing.optional_services, $.optionalServices);

                return true;
            };

            /**
             * Loads and filters Optional Services
             *
             * @param filingCode
             * @returns {boolean}
             */
            $.loadOptionalServices = function (filingCode, deferred) {
                if (!filingCode || !filingCode.hasOwnProperty('code')) {
                    return false;
                }

                // If Optional Services with the same filing code are loaded - uses them.
                if (
                    $.optionalServices && $.optionalServices.hasOwnProperty('length') && $.optionalServices.length &&
                    $.optionalServices[0] && $.optionalServices[0].hasOwnProperty('filingCodeId') &&
                    $.optionalServices[0].filingCodeId == filingCode.code
                ) {
                    return true;
                }

                // Drop optional services
                $.optionalServices = [];

                var courtCode = (function (eCase) {
                    if (eCase.hasOwnProperty('court_code'))
                        return eCase.court_code;

                    if (eCase.hasOwnProperty('court') && eCase.court && eCase.court.hasOwnProperty('code') && eCase.court.code)
                        return eCase.court.code;

                })($.case);

                OptionalServiceResource
                    .query({
                        courtCode: courtCode,
                        filingCodeId: filingCode.code
                    })
                    .$promise
                    .then(function (optionalServices) {
                        for (var i = 0; i < optionalServices.length; i++) {
                            optionalServices[i].selected = false;
                            $.optionalServices.push(optionalServices[i]);
                        }
                    }).then(function () {
                    if (deferred != null) {
                        deferred.resolve(true);
                    }
                });

                return true;
            };

            $.disableUploadBtn = function () {
                return Object.keys(filingComponents.selected).length === 0 &&
                    filingComponents.selected.constructor === Object;
            };

            $.filterFilingComponents = function (filingCode) {
                if (!filingCode) {
                    $.allowMultiples = false;
                    throw 'Not valid filingCode';
                }

                filingComponents.selected = {};

                for (var i = 0; i < filingComponents.all.length; i++) {
                    if (filingComponents.all[i].filingcodeid != filingCode.code)
                        continue;

                    if (filingComponents.all[i].name === 'Lead Document') {
                        filingComponents.selected.lead = filingComponents.all[i];
                    } else {
                        filingComponents.selected.attach = filingComponents.all[i];
                    }
                }

                setAllowMultiples();
                $.documentTypes = [];

                DocumentTypeResource.query({court_code: $.case.court_code, filing_code_id: filingCode.code}).$promise
                    .then(function (documentTypes) {
                        // sort by name
                        documentTypes.sort(function(a, b) {
                            var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                            var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }

                            // names must be equal
                            return 0;
                        });

                        $.documentTypes = documentTypes;
                    });


                return filingComponents.selected;
            };

            var _documentTypes = {};

            $.getdocumentTypes = function (filingCode) {

                if (filingCode === null)
                    return;

                var deferred = $q.defer();


                if (_documentTypes[filingCode.code])
                    deferred.resolve(_documentTypes[filingCode.code]);
                else

                    DocumentTypeResource.query({
                        court_code: $.case.court_code,
                        filing_code_id: filingCode.code
                    }).$promise
                        .then(function (documentTypes) {
                            _documentTypes[filingCode.code] = documentTypes;
                            deferred.resolve(documentTypes);
                        });


                return deferred.$promise;
            };

            /**
             * @returns {boolean}
             */
            $.isMoreDocumentsAllowed = function () {
                var filingComponent;
                var index = $.filing.hasOwnProperty('listIndex') ? $.filing.listIndex : $.filings.length;

                // If there is no filing code selected yet - returns false
                if (!filingComponents.selected.hasOwnProperty('attach') || !filingComponents.selected.hasOwnProperty('lead')) {
                    return false;
                }

                // If there ano documents uploaded yet - returns true
                if ($.filing.docs.length === 0) {
                    return true;
                }

                // Gets proper filing component according to the current filing index
                filingComponent = (index > 0) ? filingComponents.selected.attach : filingComponents.selected.lead;

                return filingComponent.allowmultiple === 'True';
            };


            /**
             * @returns {boolean}
             */
            $.canUploadMoreDocument = function () {
                // If there ano documents uploaded yet - returns true
                if ($.filing.docs.length === 0) {
                    return true;
                }

                //if at least one document is uploaded.

                return filingComponents.selected.hasOwnProperty('attach');
            };

            $.openFilestackDialog = function () {
                var multipleMode = filingComponents.selected.hasOwnProperty('attach');

                var dialogParams = {
                    services: ['COMPUTER', 'BOX', 'DROPBOX', 'GOOGLE_DRIVE', 'SKYDRIVE', 'FTP'],
                    container: 'modal',
                    multiple: multipleMode,
                    folders: multipleMode,
                    extensions: ['.txt', '.rtf', '.doc', '.docx', '.pdf', '.png', '.jpg', '.jpeg', '.gif', '.bmp', '.tif', '.tiff', '.odt', '.odp', '.html', '.xls', '.xlsx', '.htm', '.ppt', '.pptx', '.ods', '.psd', '.ai', 'svg'],
                };

                $window.filepicker.setKey(filepickerApikey);
                SecurityService.getDialogSecParams().then(function(secParams) {
                    window.angular.extend(dialogParams,secParams);
                    $window.filepicker.pickAndStore(
                        dialogParams,
                        getStoreParams(),
                        function (result) {
                            filestackUploaded(result);
                        });
                });
            };

            $.deleteDocument = function (index) {
                $.filing.docs.splice(index, 1);
                $.checkFilesSize();
                ToastrService.showMessageByKey('document_deleted');
            };

            $.isFormEmpty = function (form) {
                var models = {};
                var ret = true;

                for (var prop in form) {
                    if (!form.hasOwnProperty(prop) || prop[0] === '$') {
                        continue;
                    }

                    models[prop] = form[prop];

                    if (models[prop].$viewValue) {
                        ret = false;
                        break;
                    }
                }
                return ret;
            };

            $.setDocumentStageValid = function () {
                $rootScope.isDocumentStageIsValid = true;
                return true;
            };

            $.verify = function () {
                var result = $.filings != null
                    && $.filings.length > 0
                    && $.filings[0].docs != null
                    && $.filings[0].docs.length > 0
                    &&
                    ( $.newFiling.$pristine || ($.filing && $.filing.docs.length == 0));

                if (!result)
                    ToastrService.showMessageByKey('lead_missing');

                return result;

            };

            $.removeFiling = function (index) {
                $.filings.splice(index, 1);
            };

            $.toggleSplitButton = function (isVisible) {
                $.showSplitButton = !!isVisible;

                return true;
            };

            var fileNameRegexp = getForbiddenFilenamePattern();
            var fix = function (index) {
                return $.newFiling['documentTitle[' + index + ']'].$$rawModelValue.replace(fileNameRegexp, '');
            };
            $.fixFileNames = function () {
                $.filing.docs.map(function (c, index) {
                    c.file_name = fix(index);
                });
            };

            $.checkFilesSize = function () {
                return $.envelopeOverTheLimit = !(new UploadDocumentService())
                    .checkEnvelopeSize(null, filesFilling());
            };
        }
    ]);
})(window.angular.module('onfileApp'));
