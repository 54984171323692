;(function (app) {
    app.filter('pad', function padFilter() {
        return function (num, size) {
            var s = num + '';

            while (s.length < size) {
                s = '0' + s;
            }

            return s;
        };
    });
})(angular.module('onfileApp'));
