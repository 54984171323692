(function (app) {
    app.factory('FirmResource', [
        '$resource',
        function ($resource) {
            return $resource('', {id: '@id'}, {
                isWhitelisted: {
                    url: '/auth/isFirmWhitelisted?id=:id',
                    method: 'GET',
                    isArray: false,
                    cache: false
                }
            });
        }]);
})(angular.module('onfileApp'));
