;(function (app) {
  app.factory('NewCaseService', function () {

    function NewCaseService(data) {
      var _this = this;

      this.service_contacts = [];
      this.procedureRemedies = [];
      this.parties = [];
      this.court = null;
      this.category = null;
      this.type = null;
      this.filerType = null;
      this.attorney = null;
      this.damageAmount = null;
      this.filingPartyIndex = null;
      this.title = '';
      this.filing = [];
      this.filings = [];

      function construct(data) {
        return angular.merge(_this, data);
      }

      function insertAttorney() {
        if ( !(_this.filingPartyIndex >= 0 && _this.parties && _this.parties.length &&
          _this.parties.hasOwnProperty(_this.filingPartyIndex))
        ) {
          return _this;
        }

        var filingParty = _this.parties[_this.filingPartyIndex];

        _this.attorney = filingParty.attorney;
      }

      this.prepare = function () {
        if (!this.attorney)
          insertAttorney.call(this);

        return this;
      };

      this.pack = function () {
        return JSON.stringify(this);
      };

      this.plainObject = function () {
        return JSON.parse( JSON.stringify(this) );
      };

      if (data) {
        construct(data);
      }

      return _this;
    }

    NewCaseService.unpack = function (string) {
      return angular.extend(new NewCaseService(), JSON.parse(string));
    };

    return NewCaseService;
  });
})(angular.module('onfileApp'));