;(function (app) {
    app.service('EServiceService', [
        'EServiceResource', 'FirebaseService', 'EnvironmentService',
        'DataProvider',

        function (
            EServiceResource, FirebaseService, EnvironmentService,
            DataProvider
        ) {
            const self = this;

            const getDetailedPath = function (id) {
                return DataProvider.getPath(EServiceResource.detailedKey, id);
            };

            const getCommonPath = function (id) {
                return DataProvider.getPath(EServiceResource.commonKey, id);
            };

            const removeRedundantData = function (data) {
                const result = {};

                for (var prop in data) {
                    if (data && data.hasOwnProperty(prop) && (['case', 'all', 'StopWatch'].indexOf(prop) === -1)) {
                        result[prop] = data[prop];
                    }
                }

                return result;
            };

            this.updateCommon = function (id, data) {
                const path = getCommonPath(id);

                return FirebaseService.saveNode(
                    path,
                    FirebaseService.purifyObject(data)
                );
            };

            this.updateDetailed = function (id, commonServiceData) {
                return EServiceResource.get({
                    id: id,
                    direct: true
                })
                    .$promise
                    .then(function (service) {
                        const path = getDetailedPath(id);
                        const data = removeRedundantData(
                            Object.assign({}, service, commonServiceData)
                        );

                        return FirebaseService.saveNode(
                            path,
                            FirebaseService.purifyObject(data)
                        );
                    });
            };

            this.updateProp = function (id, prop, value) {
                var path = getDetailedPath(id) + '/' + prop;

                return FirebaseService.saveNode(path, value);
            };

            this.loadOrImport = function (id) {
                const getDetailed = function () {
                    return EServiceResource.get({id: id, firebaseDetail: true}).$promise;
                };

                return EServiceResource.get({id: id})
                    .$promise
                    .then(function (service) {
                        if (FirebaseService.objectExists(service) === false) {
                            throw service;
                        }

                        return service;
                    })

                    // Update list data
                    .catch(function () {

                        return EServiceResource
                            .get({id: id, firebaseDetail: true})
                            .$promise
                            .then(function (service) {
                                if (FirebaseService.objectExists(service) === false) {
                                    return self.updateDetailed(id, {})
                                        .then(function () {
                                            return getDetailed();
                                        })
                                }

                                return service;
                            })
                            .then(function (service) {
                                return self.updateCommon(id, service);
                            })
                            .then(function () {
                                return getDetailed();
                            })
                    });
            }
        }
    ]);
})(angular.module('onfileApp'));