;(function (app) {
    app.controller('ReportsCtrl', [
        '$scope', '$rootScope', 'UsersResource',
        'AttorneyResource', 'CaseService', 'PaymentAccountResource',
        function (
            $, $rootScope, UsersResource,
            AttorneyResource, CaseService, PaymentAccountResource
        ) {
            $rootScope.showList = false;
            angular.extend($, {
                filers: [],
                attorneys: [],
                cases: [],
                paymentAccounts: []
            });

            UsersResource.query()
                .$promise
                .then(function (users) {
                    $.filers = users || [];
                    $.filers.unshift({
                        name: 'ALL',
                        userID: null
                    });
                });
            AttorneyResource.query()
                .$promise
                .then(function (attorneys) {
                    $.attorneys = attorneys || [];
                    $.attorneys.unshift({
                        firstName: 'ALL',
                        attorneyID: null
                    });
                });
            CaseService.getList()
                .then(function (cases) {
                    $.cases = cases.map(function (eCase) {
                        return Object.assign({}, eCase, {
                            case_title_composed: CaseService.composeCaseName(eCase)
                        });
                    });
                    $.cases.unshift({
                        case_title_composed: 'ALL',
                        id: null
                    });
                });
            PaymentAccountResource.query()
                .$promise
                .then(function (paymentAccounts) {
                    $.paymentAccounts = paymentAccounts || [];
                    $.paymentAccounts.unshift({
                        name: 'ALL',
                        userID: null
                    });
                });
        }
    ]);
})(angular.module('onfileApp'));