(function (app) {
    app.controller('ViewReminderCtrl', [
        '$rootScope', '$scope', '$stateParams',
        '$state',
        'ReminderService', 'FirebaseService', 'CaseNameService',

        function ($rootScope, $, $stateParams,
                  $state,
                  ReminderService, FirebaseService, CaseNameService) {
            $rootScope.showList = true;

            angular.extend($, {
                id: $stateParams.id,
                reminder: null,

                changeStatus: function (reminder, status) {
                    if (status === 'deleted') {
                        ReminderService.delete(reminder)
                            .then(function () {
                                $state.go('reminders');
                            });
                    } else {
                        ReminderService.changeStatus(reminder, status)
                            .then(function () {
                                $state.go('reminders');
                            });
                    }
                },
                isOverDue: ReminderService.isOverDue
            });

            this.uiOnParamsChanged = function (changedParams) {
                // If current active eService ID is changed - refresh variable for a view.
                if (changedParams.hasOwnProperty('id')) {
                    $.id = changedParams.id;
                }

                ReminderService.get($.id)
                    .then(function (reminder) {
                        return reminder.$loaded();
                    })
                    .then(function (reminder) {
                        $.reminder = FirebaseService.objectExists(reminder) ? reminder : null;

                        return $.reminder;
                    })
                    .then(function () {
                        if ($.reminder.case) {
                            CaseNameService.decorate($.reminder.case, {
                                caseId: 'id',
                                filingId: 'id'
                            });
                        }
                    });
            };

            this.uiOnParamsChanged({id: $.id});
        }
    ]);
})(angular.module('onfileApp'));