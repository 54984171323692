;(function (app) {
    app.service('FilingCreateCacheService', [
        '$window', '$q',
        function ($window, $q) {

            var base =this;

            this.populateData = function (eCase) {
                var cacheKey = getKey(eCase.id);
                var cacheData = getData(cacheKey);

                if (!cacheData) {
                    return false;
                }

                if (cacheData.paymentAccount) {
                    eCase.payment_account = cacheData.paymentAccount;
                }

                if(cacheData.filerType){
                    var filerTypeName = cacheData.filerType;
                    var type = eCase.filerTypes.filter(function(type){
                        return type.name === filerTypeName;
                    });

                    if(type !== null && type.length > 0)
                        eCase.filerType = type[0];
                }

                if (
                    cacheData.serviceContactsMeta &&
                    cacheData.serviceContactsMeta[eCase.id] &&
                    cacheData.serviceContactsMeta[eCase.id].length
                ) {
                    var commonServiceMeta = cacheData.serviceContactsMeta[eCase.id];
                    base.cacheServiceContacts = true;

                    eCase.service_contacts.map(function (item, index) {
                        var serviceMeta = null;
                        for (var i = 0; i < commonServiceMeta.length; i++) {
                            if (commonServiceMeta[i].id === item.id) {
                                serviceMeta = commonServiceMeta[i];
                                break;
                            }
                        }
                        if (serviceMeta) {
                            eCase.service_contacts[index].included = !serviceMeta.excluded;
                        }
                    });
                }
            };

            this.save = function (eCase) {
                var filingsMeta;
                var allServiceAccountMeta = {};
                var deferred = $q.defer();
                var cacheKey = getKey();
                var oldData = getData(cacheKey);
                var lastFiling = eCase.filings ?
                    eCase.filings[eCase.filings.length - 1] : eCase.filing;
                var serviceAccountMeta = eCase.service_contacts.map(function (item) {
                    return {
                        id: item.id,
                        excluded: !item.included
                    };
                });

                if (oldData && oldData.serviceAccountMeta) {
                    allServiceAccountMeta = oldData.serviceAccountMeta;
                }

                allServiceAccountMeta[eCase.id] = this.cacheServiceContacts ? serviceAccountMeta : undefined;

                if (this.cacheFilings) {
                    filingsMeta = {
                        description: lastFiling.description,
                        filingCode: lastFiling.filing_code,
                        referenceId: lastFiling.reference_id
                    };
                }

                saveData(cacheKey, {
                    paymentAccount: this.cacheMainInfo ? eCase.payment_account : undefined,
                    filerType : eCase.filerType.name,
                    serviceContactsMeta: allServiceAccountMeta,
                    filingsMeta: filingsMeta
                });
                deferred.resolve();

                return deferred.promise;
            };

            this.get = function (property) {
                var cacheKey = getKey();
                var cacheData = getData(cacheKey);

                if (cacheData && cacheData[property]) {
                    return cacheData[property];
                }

                return null;
            };

            this.clearPaymentAccount = function () {
                return this.clear('paymentAccount');
            };

            this.clearFilings = function () {
                return this.clear('filingsMeta');
            };

            this.clearServiceContacts = function (caseId) {
                var key = getKey();
                var data = getData(key);

                if (data && data['serviceContactsMeta'] && data['serviceContactsMeta'][caseId]) {
                    data['serviceContactsMeta'][caseId] = undefined;
                    saveData(key, data);
                }
            };

            this.clear = function (property) {
                var key = getKey();
                var data = getData(key);

                if (data && data[property]) {
                    data[property] = undefined;
                    saveData(key, data);
                }
            };

            var getData = function (key) {
                return JSON.parse($window.localStorage.getItem(key));
            };

            var saveData = function (key, data) {
                return $window.localStorage.setItem(key, JSON.stringify(data));
            };

            var getKey = function () {
                return 'filingCreateCache';
            };

            this.caseId = null;
            this.cacheMainInfo = this.get('paymentAccount') !== null;
            this.cacheFilings = this.get('filingsMeta') !== null;

        }
    ]);
})(angular.module('onfileApp'));
