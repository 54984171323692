;(function (app) {
    app.controller('CreateBatesCtrl', [
        '$scope', '$rootScope', '$state',
        'BatesModel',

        function (
            $, $rootScope, $state,
            BatesModel
        ) {
            $rootScope.showList = false;
            $rootScope.toLastStep = function () {
                $.activeTabIndex = 2;
            };

            $.isBatesProcessFinished = function () {
                return BatesModel.storage.bates_status === BatesModel.STATUS_BATES_FINISHED;
            };

            $rootScope.$watch('batesAvailable', function (batesAvailable) {
                if (batesAvailable === null || batesAvailable === undefined) {
                    return;
                }

                return batesAvailable ?  $state.transitionTo('createBates.accordion') : $state.transitionTo('dashboard');
            });
        }
    ]);
})(angular.module('onfileApp'));