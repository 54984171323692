;(function (app) {
  app.factory('AttorneyService', function () {
    return function AttorneyService(data) {
      function init(data) {
        angular.extend(this, data);
        if(data && !data.hasOwnProperty('name') && this.hasOwnProperty('firstName'))
        {
          this.fullName = this.firstName + (this.middleName ?
                  ( ' ' + this.middleName) : '')
              + ' ' + this.lastName;
        }
        else if(data.hasOwnProperty('name'))
          this.fullName = data.name;
        else
          this.fullName = null;
      }

      if (data) {
        init.call(this, data);
      }

      return this;
    };
  });
})(angular.module('onfileApp'));