;(function (app) {
    var extCache = {};

    app.constant('utils', {
        capitalize: function (s) {
            var words = s.match(/[A-Za-z][a-z]*/g);
            var mapFn = function mapFn(word) {
                return word.charAt(0).toUpperCase() + word.substring(1);
            };

            return words.map(mapFn).join(' ');
        },
        buildToggler: function ($mdSidenav) {
            return function (componentId) {
                return function() {
                    $mdSidenav(componentId).toggle();
                };
            };
        },
        getOr: function (fn, defaultValue) {
            try {
                return fn();
            } catch (e) {
                return defaultValue;
            }
        },
        getFileStorageProvider: function (file) {
            return file && file.url && file.url.indexOf('cdn.fs.onfile.legal') >= 0 ?
                'filestack' : null;
        },
        getExtension: function (filename) {
            return filename && filename.split && typeof filename.split === 'function' && filename.split('.').pop();
        },
        getFileType: function (ext, map) {
            var item;
            var type = ext;

            if (!extCache || !extCache[ext]) {
                item = map.find(function (entry) {
                    return entry[0] === ext;
                });

                if (item && item[1]) {
                    type = item[1];
                }

                extCache[ext] = type;
            }

            return extCache[ext];
        }
    });
})(angular.module('onfileApp'));
