;(function (app) {
    app.controller('BatesDownloadCtrl', [
        '$scope', '$window', '$filter',
        '$rootScope', '$state', 'BatesModel',
        'DownloadZipService', 'CsvExportService', 'BatesSchemaResource',
        'SecurityService',

        function ($, $window, $filter,
                  $rootScope, $state, BatesModel,
                  DownloadZipService, CsvExportService, BatesSchemaResource, SecurityService) {
            angular.extend($, {
                limit: 25,
                isPopupVisible: false,
                batesModel: BatesModel,
                newSchemaName: '',

                closePackage: function () {
                    return BatesModel
                        .setClosedStatus()
                        .then(function () {
                            const name = $.newSchemaName.trim();

                            // If one of the User's schemas is selected
                            if ($.batesModel.storage.schema.default === false) {
                                return updateUserSchema($.batesModel.storage.schema, $.batesModel.storage.files);
                            } else if (name !== '') {
                                // If one of the default schemas is selected and a User wants to save it to collection
                                return createNewUserSchema(name, $.batesModel.storage.files);
                            }
                        })
                        .then(function () {
                            return BatesModel.deleteFilesFromFilestack();
                        })
                        .then(function () {
                            return BatesModel.getPreviewObject()
                                .then(function (deletePreview) {
                                    if (deletePreview && deletePreview.url) {
                                        return BatesModel.deletePreview(deletePreview);
                                    }
                                });
                        })
                        .finally(function () {
                            $.batesModel.unwatch();
                            $.batesModel.storage = {};

                            $window.location.reload();
                        });

                },
                closeConfirmPopup: function () {
                    return $.closePackage()
                        .then(function () {
                            $state.go('case');
                        });
                },
                showConfirmPopup: function () {
                    $.isPopupVisible = true;
                },
                downloadZip: function () {
                    const links = BatesModel.getBatesLinks();

                    $rootScope.loading_message = 'Downloading labeled files';
                    SecurityService.getMetadataSecParams().then(function(secParams){
                      if (secParams) {
                        links.map(function(l){
                          l.url += "?signature=" + secParams.signature;
                          l.url += "&policy=" + secParams.policy;
                        });

                      }
                      DownloadZipService.remoteFilesToZip(links)
                          .then($.showConfirmPopup)
                          .then(function () {
                              BatesModel.storage.downloaded = true;

                              return BatesModel.storage.$save();
                          })
                          .finally(function () {
                              $rootScope.loading_message = '';
                          });
                  });
                },
                downloadIndexFile: function () {
                    const sizeFieldMutator = {
                        size: function (item) {
                            return $filter('bytes')(item);
                        }
                    };

                    return CsvExportService.exportAndDownload({
                        items: BatesModel.storage.files,
                        titles: ['Filename', 'Bates number', 'File size'],
                        fields: ['output_filename', 'bates_number', sizeFieldMutator],
                        filename: 'Labeled_file_index.csv'
                    });
                }
            });

            const updateUserSchema = function (schema, files) {
                return BatesSchemaResource.update(
                    schema.id,
                    Object.assign({}, schema, {
                        startNumber: getStartNumberForNextRun(),
                        lastUsedBates: getLastUsedBates()
                    })
                ).$promise;
            };

            const createNewUserSchema = function (newSchemaName, files) {
                const newSchema = Object.assign(
                    {},
                    BatesModel.storage.schema,
                    {
                        id: null,
                        name: newSchemaName,
                        startNumber: getStartNumberForNextRun(),
                        lastUsedBates: getLastUsedBates()
                    }
                );

                return BatesSchemaResource.save(newSchema).$promise;
            };

            const getLastUsedBates = function () {
                return {
                    startNumber: parseInt(BatesModel.lastBatesApplied()),
                    prefix: BatesModel.storage.schema.prefix,
                    suffix: BatesModel.storage.schema.suffix,
                    numberOfDigits: BatesModel.storage.schema.numberOfDigits
                };
            };

            const getStartNumberForNextRun  = function(){
               return  BatesModel.lastBatesApplied() + 1;
            }
        }
    ]);
})(angular.module('onfileApp'));
