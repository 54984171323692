;(function (app) {
    app.directive('filingReport', [
        'UsersResource', 'AttorneyResource', 'CaseService',
        'ReportResource', 'moment', '$rootScope',
        'ToastrService', 'FilingTransformer', '$filter',
        'CsvExportService', 'caseSearchFilter', 'ReportsService',

        function (
            UsersResource, AttorneyResource, CaseService,
            ReportResource, moment, $rootScope,
            ToastrService, FilingTransformer, $filter,
            CsvExportService, caseSearchFilter, ReportsService
        ) {
            return {
                strict: 'A',
                scope: {
                    filers: '=?',
                    attorneys: '=?',
                    cases: '=?'
                },
                link: function ($) {
                    angular.extend($, {
                        serviceStatusCodes: [{name: 'ALL'}].concat($rootScope.getServiceStatusCodes()),
                        dateFormat: $rootScope.longDate,
                        dateFormatForView: $rootScope.shortDate,
                        query: {
                            filer: null,
                            attorney: null,
                            case: null,
                            date: {
                                from: moment().subtract(7, 'days').toDate(),
                                to: moment().toDate()
                            },
                            status: null
                        },
                        disableFilingReportFilters: false,
                        rawFilingDetails: null,

                        filterItems: caseSearchFilter(),
                        calculateFilingsReport: function () {
                            if (!$.query.date.from || !$.query.date.to) {
                                ToastrService.showError('Please select date range');
                                return false;
                            }

                            var query = {
                                filerId: null,
                                filerEmail: null,
                                caseId: $.query.case ? $.query.case.id : null,
                                attorneyId: $.query.attorney ? $.query.attorney.attorneyID : null,
                                date: {
                                    from: $.query.date && $.query.date.from ? formatDate($.query.date.from) : null,
                                    to: $.query.date && $.query.date.to ? formatDate($.query.date.to) : null
                                },
                                status: $.query.status && $.query.status.raw ? $.query.status.raw : null
                            };
                            var request = ReportResource.filings(query);

                            if ($.query.filer) {
                                query.filerId = $.query.filer ? $.query.filer.userID : null;
                                query.filerEmail = $.query.filer ? $.query.filer.email : null;
                            }

                            $rootScope.loading_message = 'Generating report';
                            $rootScope.cancelableRequests = [request];

                            return request.$promise
                                .then(function (rawFilingDetails) {
                                    $.rawFilingDetails = rawFilingDetails.map(function (filing) {
                                        return FilingTransformer.import(filing);
                                    });
                                })
                                .finally(function () {
                                    $rootScope.removeFromCancelableQueue(request);
                                    $rootScope.loading_message = '';
                                });
                        },
                        export: function () {
                            var items = ReportsService.transformFilingsReportForImport({
                                rawFilingDetails: $.rawFilingDetails,
                                dateFormat: $.dateFormat,
                                attorneys: $.attorneys
                            });
                            var filename = 'Filing-report_' +
                                $filter('amDateFormat')(new Date(), $rootScope.shortDate) + '.csv';

                            return CsvExportService.exportAndDownload({
                                items: items,
                                filename: filename
                            });
                        }
                    });

                    var formatDate = function (date) {
                        return date ? moment(date).format('MM/DD/YYYY') : null;
                    };

                    $.$watch('disableFilingReportFilters', function (val) {
                        if (val === true) {
                            $.query = Object.assign({}, $.query, {
                                filer: null,
                                attorney: null,
                                case: null
                            });
                        }
                    });
                },
                templateUrl: '/assets/app/components/reports/filing/template.html?{{onfileApplicationVersion}}'
            };
        }
    ]);
})(angular.module('onfileApp'));
