;(function (app) {
    app.controller('NavigationCtrl', [
        '$scope', 'EProductionHelper',

        function ($, EProductionHelper) {
            $.logout = function () {
                EProductionHelper.removeAuth();
                window.location.replace('/login');
            };
        }
    ]);
})(angular.module('onfileApp'));