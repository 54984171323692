(function (app) {
    app.factory('ServiceContactResource', ['$resource', 'urlEncodedResource',
        function ($resource, urlEncodedResource) {
            return $resource('/contacts', {}, {
                query: urlEncodedResource({
                    url: '/contacts',
                    method: 'GET',
                    isArray: true,
                    cache: true
                }),
                create: urlEncodedResource({
                    url: '/contacts/create',
                    method: 'POST'
                }),
                validate: urlEncodedResource({
                    url: '/contacts/validate',
                    method: 'POST'
                }),
                detachFromCase: {
                    url: '/case/:caseId/contact/:id',
                    method: 'DELETE'
                },
                attachToCase: urlEncodedResource({
                    url: '/contacts/attach',
                    method: 'POST'
                })
            });
        }]);
})(angular.module('onfileApp'));
