;(function (app) {
    app.controller('ReviewCaseCtrl', [
        'FeeResource', 'CaseService', '$rootScope',
        '$scope', '$timeout', 'TextNotificationService',
        'FeesService',
        function (
            FeeResource, CaseService, $rootScope,
            $, $timeout, TextNotificationService,
            FeesService
        ) {
            angular.extend($, {
                composeCaseName: CaseService.composeCaseName
            });

            TextNotificationService.getNode()
                .then(function (settings) {
                    $.globalTextNotificationEnabled = settings.enabled;
                    processAlertPreference();
                });

            var timer;
            var processAlertPreference = function () {
                TextNotificationService.getNode()
                    .then(function (settings) {
                        $.globalTextNotificationEnabled = settings.enabled;
                    })
                    .then(function () {
                        // Set text notification enabled by default
                        $.case.text_alert_on_change = $.globalTextNotificationEnabled;

                        if (!$.globalTextNotificationEnabled) {
                            if (!timer) timer = $timeout(processAlertPreference, 500);
                        }
                        else {
                            if (timer)
                                $timeout.cancel(timer);
                        }
                    });
            };

            $.calculateFees = function () {
                return FeesService.calculate($.case);
            };

            $.formatedDate = (function (date) {
                var weeks = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
                var months = [
                    'Jan', 'Feb', 'Mar',
                    'Apr', 'May', 'Jun',
                    'Jul', 'Aug', 'Sep',
                    'Oct', 'Nov', 'Dec'
                ];

                return weeks[date.getDay()] + ', ' + months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
            })(new Date());
        }
    ]);
})(angular.module('onfileApp'));
