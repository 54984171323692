(function (app) {
    app.factory('CourtResource', ['$resource', 'urlEncodedResource',
        function ($resource, urlEncodedResource) {
            return $resource('/courts/:id', { id: '@id' }, {
                query: {
                    method: 'GET',
                    isArray: true,
                    cache: true
                },
                contact: {
                    method: 'GET',
                    isArray: false,
                    cache: true,
                    url: '/courts/courtcontact/:id'
                },
                getContact: urlEncodedResource({
                    method: 'POST',
                    isArray: false,
                    cache: true,
                    url: '/courts/courtContactSecure'
                })
            });
        }]);
})(angular.module('onfileApp'));