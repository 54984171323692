;(function (app) {
    app.value('jsonEncodedResource', function (object) {
        var transformRequest = object.hasOwnProperty('transformRequest') ? object.transformRequest : undefined;

        angular.merge(object, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json'
            },
            transformRequest: function (data) {
                data = transformRequest ? transformRequest(data) : data;

                return JSON.stringify(data);
            }
        });

        return object;
    });
})(angular.module('onfileApp'));