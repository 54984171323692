(function (app) {
  app.filter('filingTypeFilter', function searchFilter() {
    return function (array, type) {
      if (type === 'all') {
        return array;
      }

      var result = [];
      var item;

      for (var i = 0; i < array.length; i++) {
        item = array[i];
        if (( type === 'accepted' &&
              item.status_string === 'accepted') ||
            ( type === 'pending' &&
              ( item.status_string === 'underReview' ||
                item.status_string === 'submitting' ||
                item.status_string === 'submitted' ||
                item.status_string === 'reviewed' ||
                item.status_string === 'deferred')) ||
            ( type === 'failed' &&
              ( // item.status_string === 'cancelled' ||
                item.status_string === 'rejected' ||
                item.status_string === 'failed' ||
                item.status_string === 'cancelled' ||
                item.status_string === 'error'))) {
                    result.push(item);
        }
      }

      return result;
    };
  });
})(angular.module('onfileApp'));
