(function (app) {
  app.factory('StateResource', [
    '$resource',
    function ($resource) {
      return $resource('/states', {}, {
        query: {
          method: 'GET',
          isArray: true,
          cache: true
        },
        forUSA: {
          url: '/states?country_code=US',
          method: 'GET',
          isArray: true,
          cache: true
        }
      });
    }
  ]);
})(angular.module('onfileApp'));