(function (app) {
    app.controller('TokenCtrl', [
        '$scope', '$stateParams', '$rootScope',
        'LoginTokenResource',

        function ($, $stateParams,$rootScope,
                  LoginTokenResource) {
            $.token = {
                noOfDays: 7,
                tag: 'onfile',
                token: ''
            };

            $rootScope.showList = false;
            $.generateToken = function () {
                return LoginTokenResource.generate({
                    tag: $.token.tag,
                    days: $.token.noOfDays
                })
                    .$promise
                    .then(function (res) {
                        $.token.token = res.token;
                    });
            }
        }
    ]);
})(angular.module('onfileApp'));