// Extends resource's params to send a Form Data instead of a raw data in request body

;(function (app) {
  app.value('urlEncodedResource', function (object) {
    var transformRequest = object.hasOwnProperty('transformRequest') ? object.transformRequest : undefined;

    angular.merge(object, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      transformRequest: function (data) {
        var serialize = function (obj, prefix) {
          var k;
          var v;
          var result;
          var str = [];

          for (var p in obj) {
            if (!obj.hasOwnProperty(p))
              continue;

            if (typeof obj[p] === 'function')
              continue;

            if (p.charAt(0) === '$')
              continue;

            if (p === false || p === undefined)
              continue;

            k = prefix ? prefix + '[' + p + ']' : p;
            v = obj[p];
            result = (typeof v === 'object') ? serialize(v, k) : encodeURIComponent(k) + '=' + encodeURIComponent(v);

            // if (result)
              str.push(result);
          }

          return str.join('&');
        };

        data = transformRequest ? transformRequest(data) : data;
        return serialize(data);
      }
    });

    return object;
  });
})(angular.module('onfileApp'));