(function (app) {
  app.directive('pricingModal', [
    function () {
      return {
        strict: 'A',
        scope:{
          additionalClasses : '@'

        },
        templateUrl: '/assets/app/shared/directives/pricingModal/template.html'
      };
    }
  ]);
})(angular.module('onfileApp'));