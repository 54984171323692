// Extends resource's params to send a Form Data instead of a raw data in request body

;(function (app) {
  app.service('EProductionHelper', [

    '$state', 'FirebaseTokenResource',

    function ($state, FirebaseTokenResource) {
      function EProductionHelper() {
        var _this = this;
        var regexp = /^\/([a-zA-Z0-9-]+)\/([a-zA-Z0-9-]+)\/(.*)/;
        var E_PRODUCTION_AUTH_KEY = 'eProductionAuth';
        var storage = window.sessionStorage;

        this.getFirmId = function (pathname) {
          var res = pathname.match(regexp);

          return res[2];
        };

        this.getPackageId = function (pathname) {
          var res = pathname.match(regexp);

          return res[3];
        };

        // Sets User as authenticated for this firm and package
        this.setAuth = function (data) {
          storage.setItem(E_PRODUCTION_AUTH_KEY, JSON.stringify(data));
        };

        // Sets User as authenticated for this firm and package
        this.removeAuth = function () {
          storage.removeItem(E_PRODUCTION_AUTH_KEY);
        };

        // Sets User as authenticated for this firm and package
        this.getAuth = function () {
          return JSON.parse(
            storage.getItem(E_PRODUCTION_AUTH_KEY)
          );
        };

        this.configureFirebaseService = function (FirebaseService, data) {
          data = data || _this.getAuth();

          FirebaseService.authParams = {
            resource: FirebaseTokenResource.guest,
            data: {
              firmId: data.firmId,
              packageId: data.packageId,
              email: data.email
            }
          };

          return FirebaseService;
        };

        // Checks whether User is authenticated for this firm and package
        this.checkAuth = function () {
          return !!window.localStorage.getItem(E_PRODUCTION_AUTH_KEY);
        };
      }

      return new EProductionHelper();
    }

  ]);
})(angular.module('onfileApp'));