;(function (app) {
  app.controller('CaseDraftCtrl', [
    'FirebaseService',
    '$scope', '$rootScope', '$stateParams',
    '$state','ToastrService',

    function (
      FirebaseService,
      $, $rootScope, $stateParams,
      $state,ToastrService
    ) {
      angular.extend($, {
        caseDraft: null
      });

      this.uiOnParamsChanged = function (changedParams) {
        $rootScope.$watch('casesDrafts', function (casesDrafts) {
          if (!casesDrafts)
            return false;

          casesDrafts.$loaded()
            .then(function (casesDrafts) {
              $.caseDraft = casesDrafts.$getRecord(changedParams.id);
            });
        });
      };

      $.deleteDraft = function () {
        $rootScope.casesDrafts
          .$remove($.caseDraft)
          .then(function () {
            ToastrService.showMessageByKey("draft_deleted");
            $state.go('draftCases');
          });
      };

      this.uiOnParamsChanged({id: $stateParams.id});
    }
  ])
})(angular.module('onfileApp'));