/**
 * Session expired handler interceptor. If HTTP response code is 401 shows a popup window.
 */
;(function (app) {
  app.factory('authInterceptor', ['$q', function ($q) {
    var authModal = angular.element('.auth-error');

    return {
      responseError: function (response) {
        if (response.status === 401) {
          authModal.addClass('visible');
        }

        return $q.reject(response);
      }
    }
  }]);
})(angular.module('onfileApp'));