;(function (app) {
    app.service('TextNotificationService', [
        'FirebaseService', 'EnvironmentService','ToastrService',

        function (FirebaseService, EnvironmentService,ToastrService) {
            var getNodePromise = null;

            this.getNode = function () {
                if (getNodePromise === null) {
                    getNodePromise = FirebaseService
                        .getIdChannelAsObject('auth_users', 'text_notifications')
                        .then(function (e) {
                            return e.$loaded();
                        });
                }

                return getNodePromise;
            };

            this.setMode = function (isEnabled) {
                return this.getNode()
                    .then(function (obj) {
                        obj.enabled = !!isEnabled;
                        ToastrService.showMessageByKey('alert_preference_changed');
                        return obj.$save();
                    });
            };

            this.setPhone = function (phone) {
                return this.getNode()
                    .then(function (obj) {
                        obj.phone = phone;
                        ToastrService.showMessageByKey('phone_saved');

                        return obj.$save();
                    });
            };

            this.saveNotificationForFiling = function (filingId, data) {
                var userId = EnvironmentService.userId();
                var path = '/alerts/filings/' + filingId + '/' + userId;

                var filing_codes;
                if (data.case.filings) {
                    filing_codes = data.case.filings.map(function (item) {
                        var filing_code = item['filing_code'];

                        return { code: filing_code.code, name: filing_code.name };
                    });
                } else if (data.case.filing) {
                    filing_codes = [ data.case.filing.description || data.case.filing.matter || '' ];
                }

                return FirebaseService.saveNode(path, {
                    enabled: true,
                    filingcodes: filing_codes
                });
            };
        }
    ]);
})(angular.module('onfileApp'));