(function (app) {
  app.factory('PartyCodeResource', [
    '$resource',
    function ($resource) {
      return $resource('/partyCodes', {}, {
        query: {
          method: 'GET',
          isArray: true,
          cache: true
        }
      });
    }
  ]);
})(angular.module('onfileApp'));