;(function (app) {
    app.factory('EnvironmentService', ['$location',
        function ($location) {
            function EnvironmentService() {
                this.market = function () {
                    return $('#application_market').val();
                };

                this.environment = function () {
                    return $('#application_environment').val();
                };

                this.firm_id = function () {
                    return $('#application_firm_id').val();
                };

                this.isAdmin = function () {
                    return $('#application_is_admin').val();
                };

                this.user_name = function () {
                    return $('#application_logged_in_user').val();
                };

                this.userId = function () {
                    return $('#application_logged_in_user_id').val();
                };

                this.first_name = function () {
                    return $('#application_logged_in_user_first_name').val();
                };

                this.base_url = function () {
                    return $location.$$host + '/services/#/';
                };

                this.email = function () {
                    return $('#application_logged_in_user_email').val();
                };

                this.s3BucketLocation = function (draftId) {
                    return 'onfile-' + this.environment() + '-' + this.market() + '/' + this.firm_id() + '/' + draftId;
                };

                this.s3BucketName = function () {
                    return 'onfile-' + this.environment() + '-' + this.market();
                };

                var _firm;
                this.firm = function (firm) {
                    if (firm !== undefined)
                        _firm = firm;
                    else
                        return _firm;
                };

                this.getFirmInfo = function () {
                    return JSON.parse(
                        $('#application_firm').val()
                    );
                };

                this.isProduction = function () {
                    return this.environment() === 'production';
                };

                this.getUsersIdentifiers = function () {
                    return [this.userId(), this.email()];
                };

                return this;
            }

            return new EnvironmentService();
        }]
    );
})(angular.module('onfileApp'));