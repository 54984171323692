;(function (app) {
  app.controller('ServiceInboxSidebarCtrl', [
    '$scope', '$rootScope', '$stateParams',

    function (
      $, $rootScope, $stateParams
    ) {
      $rootScope.showList = true;
      angular.extend($, {
        activeMessageId: $stateParams.id
      });

      // Callback function which is called when URL state changes.
      this.uiOnParamsChanged = function (changedParams) {
        // If current active eService ID is changed - refresh variable for a view.
        if (changedParams.hasOwnProperty('id'))
          $.activeMessageId = changedParams.id;
      };

      $.convertRegisterActionDescriptionText = function(court_code,filing_code_id){
        FilingCodeResource.get(
            { court_code : court_code ,
              filing_code_id: filing_code_id
            })
            .$promise
            .then(function(res){
              return res.name;
            });

        return null;
      }
    }
  ])
})(angular.module('onfileApp'));