(function (app) {
    app.controller('ECaseCtrl', [
        'CaseResource', 'EServiceResource', 'CaseService',
        '$scope', '$stateParams', '$rootScope',
        'FirebaseService', '$firebaseArray', '$q',
        '$state', 'CourtResource', 'eProductionService',
        'EnvironmentService', '$window', 'filepickerApikey',
        'downloadFile', 'SearchQueryComposerBuilder', 'ElasticSearchResource',
        
        function (
            CaseResource, EServiceResource, CaseService,
            $, $stateParams, $rootScope,
            FirebaseService, $firebaseArray, $q,
            $state, CourtResource, eProductionService,
            EnvironmentService, $window, filepickerApikey,
            downloadFile, SearchQueryComposerBuilder, ElasticSearchResource
        ) {
            angular.extend($, {
                error: '',
                case: {},
                submissions: [],
                tab: 'submissions',
                isCreateReminderPopupVisible: false,
                setPopupVisible: function () {
                    $.isCreateReminderPopupVisible = true;
                },
                producedFiles: [],
                privilegeLogsCount : 0,

                limit: 25,
                begin: 0
            });

            $window.filepicker.setKey(filepickerApikey);

            $rootScope.showList = true;

            // Link to function that composes eservices title
            $.composeCaseName = CaseService.composeCaseName;

            // Gets ServiceInformationHistory by Case Tracking ID
            $.getHistory = function (eCase) {
                eCase.serviceContactHistory = undefined;
                CaseResource.history({
                    case_id: eCase.id,
                    court_code: eCase.court_code
                }).$promise
                    .then(function (items) {
                        eCase.serviceContactHistory = items;
                    });
            };

            $.filterByCaseId = function (submissions, caseId) {
                const res = [];

                for (var i = 0; i < submissions.length; i++) {
                    if (submissions[i] && submissions[i].case_id && submissions[i].case_id === caseId) {
                        res.push(submissions[i]);
                    }
                }

                return res;
            };

            function loadContact(court) {
                CourtResource.getContact({ id: court }).$promise.then(function (contact) {
                    $.contact = contact;
                });
            }

            const loadEProducedFiles = function (eCaseId) {
                return eProductionService.loadForCase({
                    caseId: eCaseId,
                    firmId: EnvironmentService.firm_id()
                }).then(function (eProductions) {
                    $.producedFiles = (function (eProductions) {
                        const res = [];
                        const rootPath = eProductions.$ref().path.toString();

                        eProductions.forEach(function (eProduction) {
                            var file;

                            if (
                                !eProduction || !eProduction.batch_body || !eProduction.batch_body.files ||
                                !eProduction.batch_header || eProduction.batch_header.status !== 'submitted'
                            ) {
                                return;
                            }

                            for (var fileId in eProduction.batch_body.files) {
                                if (eProduction.batch_body.files.hasOwnProperty(fileId) === false) {
                                    continue;
                                }

                                file = angular.merge({}, eProduction.batch_body.files[fileId], {
                                    pathToNode: rootPath + '/' + eProduction.$id + '/batch_body/files/' + fileId,
                                    updatedAt: eProduction.batch_header ? eProduction.batch_header.updated_at : null,
                                    eProductionId: eProduction.$id
                                });

                                res.push(file);
                            }
                        });

                        return res;
                    })(eProductions);
                });
            };

            $.commitDownload = function (file) {
                return downloadFile({
                    url: file.url,
                    filename: file.filename
                });
            };

            $.resumeDraft = function (id) {
                var draft;//.. = $.drafts[id];

                for (var i = 0; i < $.drafts.length; i++) {
                    if ($.drafts[i].$id === id) {
                        draft = $.drafts[i];
                        break;
                    }
                }

                if (draft === null)
                    return;

                if (draft.draftType === 'eFiling') {
                    $state.go('filingCreate', {
                        caseId: draft.item.id,
                        courtId: draft.item.court_code,
                        currentTab: 0,
                        draftId: draft.$id
                    });
                }
                //servicesCreate({caseId : eServiceDraft.item.id ,courtId : eServiceDraft.item.court_code ,currentTab : 0, draftId: eServiceDraft.$id})

                else if (draft.draftType === 'eService-only') {
                    $state.go('servicesCreate', {
                        caseId: draft.item.id,
                        courtId: draft.item.court_code,
                        currentTab: 0,
                        draftId: draft.$id
                    });
                }
            };

            $.deleteDraft = function (item) {
                var id = item.id;
                var type = item.type;

                if (type === 'eFiling') node = 'efilings_drafts';
                if (type === 'eService-only') node = 'eservices_drafts';

                if (type === undefined)
                    return;

                var todelete;

                for (var i = 0; i < $.drafts.length; i++) {
                    if ($.drafts[i].$id === id) {
                        todelete = $.drafts[i];
                        $.drafts.splice(i, 1);
                    }
                }

                FirebaseService.getIdChannel('auth_users', node + '/' + todelete.$id, true)
                    .then(function (remoteDraft) {
                        remoteDraft.remove();
                    });
            };

            $.deleteEProdFile = function (file) {
                return FirebaseService
                    .removeNode(file.pathToNode)
                    .then(function () {
                        return deleteFsFile(file);
                    })
                    .then(function () {
                        loadEProducedFiles($.case.id);
                    });
            };

            $.getDescription = function (service) {
                if (service && service.all && service.all.DocumentDescriptionText) {
                    return service.all.DocumentDescriptionText;
                }

                if (service && service.filing_code) {
                    return service.filing_code;
                }

                return '';
            };

            const deleteFsFile = function (file) {
                const defer = $q.defer();

                $window.filepicker.remove(file, function () {
                    defer.resolve(true);
                }, function (err) {
                    defer.reject(err);
                });

                return defer.promise;
            };

            var loadDrafts = function (eCase) {
                var efilingDefer = $q.defer();
                var eServiceDefer = $q.defer();
                var efilings = [];
                var eservices = [];
                FirebaseService.getIdChannel('auth_users', 'efilings_drafts')
                    .then(function (drafts) {
                        drafts.$loaded().then(function (loaded) {
                            for (var i = 0; i < loaded.length; i++) {
                                if (loaded[i].item.id === eCase.id) {
                                    loaded[i].draftType = 'eFiling';
                                    efilings.push(loaded[i]);
                                }
                            }
                            efilingDefer.resolve(true);
                        });
                    });

                FirebaseService.getIdChannel('auth_users', 'eservices_drafts')
                    .then(function (drafts) {
                        drafts.$loaded().then(function (loaded) {
                            for (var i = 0; i < loaded.length; i++) {
                                if (drafts[i].item.id === eCase.id && (!drafts[i].item.hasOwnProperty('eProdId'))) {
                                    loaded[i].draftType = 'eService-only';
                                    eservices.push(loaded[i]);
                                }
                            }
                            eServiceDefer.resolve(true);
                        });
                    });

                $q.all([efilingDefer.promise, eServiceDefer.promise]).then(function () {
                    var combined = efilings.concat(eservices);
                    $.drafts = combined;
                });
            };
            // Callback for ui.router directive when state is changed.
            this.uiOnParamsChanged = function (changedParams) {
                var submittedEProds = eProductionService.geteProdSubmittedServiceIds();

                $.error = '';
                $.tab = 'submissions';

                // Gets Case information through the Service
                CaseResource.get({id: changedParams.id}).$promise
                    // Gets all filings for this case
                    .then(function (eCase) {
                        $.case = eCase;

                        if ($.case && $.case.errors && $.case.errors.length) {
                            throw new NotReportedError({ errors: $.case.errors });
                        }

                        loadAllSubmissions(eCase)
                            .then(function (services) {
                                var base = {};

                                base.services = services;
                                submittedEProds.then(function (submitted) {
                                    for (var i = 0; i < submitted.length; i++) {
                                        for (var j = 0; j < base.services.length; j++) {
                                            if (base.services[j].id === submitted[i].id) {
                                                base.services[j].eProdId = submitted[i].eProdId;
                                            }
                                        }
                                    }

                                    $.submissions = base.services;
                                });
                            });

                        return eCase;
                    })
                    .then(function (eCase) {
                        $.tab === 'serviceContactHistory' ? $.getHistory(eCase) : void(0);
                        loadDrafts(eCase);
                        return eCase;
                    })
                    .then(function (eCase) {
                        if (eCase.court_code) {
                            loadContact(eCase.court_code);
                        }

                        return loadEProducedFiles(eCase.id);
                    })
                    .catch(function (res) {
                        if (res instanceof NotReportedError) {
                            $.error = res.errors.reduce(
                                function (carry, error) {
                                    const genericError = $rootScope.caseErrorMessages
                                        .find(function (message) {
                                            return message.errorCode === error.ErrorCode;
                                        });
                                    const errorMessage = genericError ? genericError.message : error.ErrorText;

                                    return carry + errorMessage;
                                },
                                ''
                            );
                        } else if (res.status !== 404) {
                            throw res;
                        } else {
                            $.error = res.data.error_message;
                        }

                    });
            };
            this.uiOnParamsChanged({id: $stateParams.id});

            var loadAllSubmissions = function (eCase) {
                var services = [];
                var defer = $q.defer();
                var pagination = { size: 25, from: 0 };
                var load = function () {
                    var query = SearchQueryComposerBuilder.build()
                        .setQuery(ElasticSearchResource.getFilingsQueryObject())
                        .attachFirmId(EnvironmentService.firm_id())
                        .attachCaseId(eCase.id)
                        .attachPagination(pagination)
                        .getQuery();

                    // Gets Services (submissions) by Case Tracking ID
                    ElasticSearchResource.searchES(query)
                        .then(function (res) {
                            pagination.from += res.length;

                            if (res && res.length) {
                                services = services.concat(res);
                            }

                            if (res < pagination.size) {
                                return defer.resolve(services);
                            }

                            load();
                        });
                };

                load();

                return defer.promise;
            }
        }
    ]);
})(angular.module('onfileApp'));