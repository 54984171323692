;(function (app) {
  app.factory('FirebaseTokenResource', ['$resource',
    function ($resource) {
      return $resource('/token/get', {}, {
        get: {
          method: 'GET',
          isArray: false,
          cache: false
        },
        guest: {
          url: '/eproduction/token',
          method: 'GET',
          isArray: false,
          cache: false
        }
      });
    }]);
})(angular.module('onfileApp'));