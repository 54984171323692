;(function (app) {
    app.directive('eProductionFiles', [
        '$q', 'FirebaseService', 'EProductionHelper',
        'IpResource', 'downloadFile', 'AccessLogItemService',
        'DownloadZipService', 'SecurityService', '$rootScope',
        'prepareProxyLink', 'utils', '$filter',
        'CsvExportService', 'extensionTypeMap', 'PubSubService',

        function (
            q, FirebaseService, EProductionHelper,
            IpResource, downloadFile, AccessLogItemService,
            DownloadZipService, SecurityService, $rootScope,
            prepareProxyLink, utils, $filter,
            CsvExportService, extensionTypeMap, PubSubService
        ) {
            return {
                strict: 'A',
                scope: {
                    email: '=?',
                    firmId: '=?',
                    packageId: '=?',
                    emailHash: '=?',
                    guestMode: '=?'
                },
                link: function ($) {
                    var batchBody;
                    var packageNode;
                    var email;
                    var packageId;
                    var guestHeaderNode;
                    var serviceContacts;

                    if ($.guestMode) {
                        $.email = $.email || (EProductionHelper.getAuth()).email;
                        $.firmId = $.firmId || (EProductionHelper.getAuth()).firmId;
                        $.packageId = $.packageId || (EProductionHelper.getAuth()).packageId;
                        $.emailHash = $.emailHash || (EProductionHelper.getAuth()).emailHash;
                    }

                    $.guestMode = $.guestMode === undefined ? true : !!$.guestMode;
                    $.fileManagerMode = true;

                    $.orderByElement = 'email';
                    $.orderByDirection = 'false';
                    // if passed Package Id is changed
                    $.$watch('packageId', function (value) {
                        if (value) {
                            packageId = value;
                            getInfo();
                        }
                    });

                    angular.extend($, {
                        limit: 50,
                        begin: $.begin || 0,
                        currentPage: 1,

                        countPages: function () {
                            return Math.ceil(
                                ($.package && $.package.files) ? ($.package.files.length / $.limit) : 0
                            );
                        },
                        getPages: function () {
                            return $.countPages() ? new Array($.countPages()) : [];
                        },
                        showPage: function (index) {
                            if (index === 'last' || index > $.countPages()) {
                                $.currentPage = $.countPages();
                            } else if (index === 'first' || !index || index <= 0) {
                                $.currentPage = 1;
                            } else {
                                $.currentPage = parseInt(index);
                            }

                            if (isNaN($.currentPage) || $.currentPage <= 0) {
                                $.currentPage = 1;
                            }

                            $.begin = ($.currentPage - 1) * $.limit;
                            $.begin = $.begin < 0 ? 0 : $.begin;
                        }
                    });

                    angular.extend($, {
                        package: null,
                        isDownloading: false,
                        begin: 0,

                        itemsToArray: function (items) {
                            var i;
                            var result = [];

                            if (!items) {
                                return result;
                            }

                            for (var prop in items) {
                                if (items.hasOwnProperty(prop) === false) {
                                    continue;
                                }

                                for (i = 0; i < items[prop].length; i++) {
                                    // Filters only file download access logs
                                    if ([
                                        AccessLogItemService.TYPE_FILE,
                                        AccessLogItemService.TYPE_LOGIN,
                                        AccessLogItemService.TYPE_DOWNLOAD_ALL_STARTS,
                                        AccessLogItemService.TYPE_DOWNLOAD_ALL_ENDS
                                    ].indexOf(items[prop][i].type) >= 0) {
                                        result.push(items[prop][i]);
                                    }
                                }
                            }

                            return result;
                        },

                        exportLogs: function (logs) {
                            //make it more dynamic
                            var csvContent = "data:text/csv;charset=utf-8,user,filename,time\n";

                            logs.forEach(function (log, index) {
                                var dataString = log.email + "," + log.filename + "," + new Date(log.timestamp);
                                csvContent += index < logs.length ? dataString + "\n" : dataString;
                            });

                            var encodedUri = encodeURI(csvContent);
                            window.open(encodedUri);
                        },

                        // Processes File download request
                        commitDownload: function (file) {
                            var promise = q.resolve();

                            $.isDownloading = true;

                            if ($.guestMode) {
                                // Gets user's IP address
                                promise = IpResource.get()
                                    .$promise

                                    // Saves user's IP
                                    .then(function (res) {
                                        AccessLogItemService.init({
                                            ip: res.ip,
                                            email: $.email,
                                            filename: file.display_name,
                                            emailHash: $.emailHash,
                                            type: AccessLogItemService.TYPE_FILE
                                        });

                                        return packageNode.$loaded();
                                    })

                                    // Saves Ip, User's email and File url to access log
                                    .then(function (item) {
                                        return AccessLogItemService.insertToLog(item)
                                            .$save()
                                            .catch(function (e) {
                                                // Do nothing...
                                            });
                                    });
                            }

                            // Forces a browser to start file download
                            return promise
                                .then(function () {
                                    var relativePath = getRelativePath(file, packageId);
                                    var filenameWithoutExtension = relativePath.split('.').splice(-1).join('.');
                                    var links = [{
                                        url: file.secured_url,
                                        name: filenameWithoutExtension,
                                        ext: utils.getExtension(file.filename)
                                    }];

                                    return DownloadZipService.remoteFilesToZip(
                                        links,
                                        relativePath,
                                        function (progress) {
                                            $rootScope.setLoadingMessage('Downloading file... ' + progress + '% ready');
                                        }
                                    );
                                })
                                .finally(function () {
                                    $.isDownloading = false;
                                });
                        },
                        downloadAll: function () {
                            var preDownloadPromise = $.guestMode ?
                                saveAction(AccessLogItemService.TYPE_DOWNLOAD_ALL_STARTS) : q.resolve();

                            return preDownloadPromise
                                .then(function () {
                                    return $.package.files.map(function (file) {
                                        var relativePath = getRelativePath(file, packageId);

                                        return {
                                            url: file.secured_url,
                                            name: relativePath,
                                            ext: utils.getExtension(file.filename)
                                        };
                                    });
                                })
                                .then(function (links) {
                                    return DownloadZipService.remoteFilesToZip(
                                        links,
                                        $.package.case_title,
                                        function (progress) {
                                            $rootScope.loading_message = 'Downloading files... ' + progress + '% ready';
                                        }
                                    );
                                })
                                .then(function () {
                                    return $.guestMode ?
                                        saveAction(AccessLogItemService.TYPE_DOWNLOAD_ALL_ENDS) : q.resolve();
                                });
                        },
                        getProvider: function (file) {
                            return utils.getFileStorageProvider(file);
                        },
                        isPreviewable: function (file) {
                            var previewableExtensions = ['pdf'];

                            return file && file.extension && previewableExtensions.indexOf(file.extension) >= 0;
                        },
                        downloadFileList: function () {
                            var sizeFilter = $filter('filesize');
                            var items = $.package.files.map(function (file) {
                                var ext = utils.getExtension(file.filename);

                                return {
                                    filename: file.filename,
                                    ext: utils.getFileType(ext, extensionTypeMap),
                                    uploadedAt: $filter('amDateFormat')(file.time, $rootScope.longDate),
                                    size: sizeFilter(file.size)
                                };
                            });
                            var fileName = 'Produced File List ' +
                                $filter('amDateFormat')(new Date(), $rootScope.longDate) +'.csv';

                            return CsvExportService.exportAndDownload({
                                items: items,
                                titles: ['File name', 'File type', 'File size', 'Date produced'],
                                fields: ['filename', 'ext', 'size', 'uploadedAt'],
                                filename: fileName
                            });
                        },
                        downloadFileCallback: function (url) {
                            var file = $.package.files.find(function (file) {
                                return file && file.url === url;
                            });

                            // S3 compatible format
                            url = url.split(' ').join('+');

                            if (!file) {
                                var fileName = url.split('/').pop();

                                file = {
                                    display_name: fileName,
                                    secured_url: url,
                                    filename: fileName
                                };
                            }
                            file.secured_url = url;

                            return $.commitDownload(file);
                        },
                        previewClickedCallback: function (url) {
                            $.isPreviewActive = true;
                            $.previewLink = url;
                        },
                        folderDownloadClickedCallback: function (files) {
                            var links = files.map(function (url) {
                                var file = {
                                    secured_url: url,
                                    filename: '',
                                    display_name: ''
                                };
                                var relativePath = getRelativePath(file, packageId);

                                return {
                                    url: file.secured_url,
                                    name: relativePath,
                                    ext: utils.getExtension(file.filename)
                                };
                            });

                            return DownloadZipService.remoteFilesToZip(
                                links,
                                $.package.case_title,
                                function (progress) {
                                    $rootScope.loading_message = 'Downloading files... ' + progress + '% ready';
                                }
                            );
                        },
                        fileManagerFilesReceivedCallback: function (data) {
                            return PubSubService.emit('fileManagerFilesReceived', data);
                        }
                    });
                    var getInfo = function () {
                        // Gets Package node
                        if ($.guestMode) {
                            EProductionHelper.configureFirebaseService(FirebaseService);
                        }

                        packageNode = FirebaseService.getEProductionNode($.firmId, $.packageId);
                        guestHeaderNode = FirebaseService.getEProductionGuestHeaderNode($.packageId);

                        // Loads package information
                        guestHeaderNode.$loaded()
                            .then(function (data) {
                                serviceContacts = (function (contacts) {
                                    var res = {};

                                    contacts.map(function (contact) {
                                        res[contact.email] = contact.name;
                                    });

                                    return res;
                                })(data.service_contacts || []);

                                return packageNode.$loaded();
                            })
                            .then(function (data) {
                                var item;

                                $.package = {
                                    files: [],
                                    access_log: (function (accessLog) {
                                        for (var prop in accessLog) {
                                            if (!accessLog[prop] || !accessLog[prop].map) {
                                                continue;
                                            }

                                            accessLog[prop] = accessLog[prop].map(function (item) {
                                                item.name = serviceContacts[item.email] || '';

                                                return item;
                                            });
                                        }

                                        return accessLog;
                                    })(data.access_log),
                                    service_id: data.batch_header.filing_id,

                                    expire_at: data.batch_header.submitted_at + data.batch_header.expire_time * 24 * 60 * 60 * 1000,
                                    created_at: data.batch_header.created_at,
                                    case_number: data.batch_header.case_number,
                                    case_id: data.batch_header.case_id,
                                    sender_name: data.batch_header.sender_name,
                                    case_title: data.batch_header.case_title,
                                    submitted_at: data.batch_header.submitted_at,
                                    id: data.$id
                                };
                                $.package.access_log_array = $.itemsToArray($.package.access_log);

                                if (data && data.batch_body && data.batch_body.files) {
                                    item = data.batch_body.files;
                                    batchBody = data.batch_body;

                                    Object.keys(item).forEach(function (k) {
                                        var res = batchBody.files[k];
                                        res.extension = res.filename.split('.').pop();
                                        $.package.files.push(res);
                                    });
                                }

                                return $.package;
                            })
                            .then(function (ePackage) {
                                $.uploaderParams = {
                                    caseId: ePackage.case_id,
                                    eProdId: ePackage.id,
                                    firmId: $.firmId || undefined,
                                    hideDeleteButton: true
                                };

                                var notFilestackFilesIndexes = [];
                                var links = ePackage.files
                                    .map(function (file, i) {
                                        if ($.getProvider(file) !== 'filestack') {
                                            notFilestackFilesIndexes.push(i);
                                        }

                                        return file.url;
                                    });

                                return SecurityService.addParamsToLink(links)
                                    .then(function (links) {
                                        return links.map(function (l, i) {
                                            if (notFilestackFilesIndexes.indexOf(i) >= 0) {
                                                return ePackage.files[i].url;
                                            }

                                            return l;
                                        });
                                    });
                            })
                            .then(function (links) {
                                links.map(function (url, i) {
                                    $.package.files[i].secured_url = url;
                                });

                                return $.package;
                            })
                            .then(function (ePackage) {
                                $.$emit('eProductionPackage.loaded', ePackage);

                                return ePackage;
                            });
                    };

                    var saveAction = function (type) {
                        // Gets user's IP address
                        return IpResource.get()
                            .$promise

                            // Saves user's IP
                            .then(function (res) {
                                AccessLogItemService.init({
                                    ip: res.ip,
                                    email: $.email,
                                    emailHash: $.emailHash,
                                    type: type
                                });

                                return packageNode.$loaded();
                            })

                            // Saves Ip, User's email and File url to access log
                            .then(function (item) {
                                return AccessLogItemService.insertToLog(item)
                                    .$save()
                                    .catch(function (e) {
                                        // Do nothing...
                                    });
                            });
                    };

                    var getRelativePath = function (file, packageId) {
                        return utils.getOr(function () {
                            var url = file.secured_url;
                            var parts = url.split(packageId);
                            var relativePath = parts[1].replace(file.filename, file.display_name);

                            if (relativePath.charAt(0) === '/') {
                                relativePath = relativePath.substr(1);
                            }

                            return relativePath;
                        }, file.display_name);
                    };

                    getInfo();
                },
                templateUrl: '/assets/app/shared/directives/eProductionFiles/e-production-files.html?' +
                    '{{onfileApplicationVersion}}'
            };
        }
    ]);
})(angular.module('onfileApp'));
