(function (app) {
  app.directive('statusLabel', [
    'serviceStatusCodes',
    function (serviceStatusCodes) {
      return {
        strict: 'A',
        scope: {
          code: '=',
          string: '=',
          additionalClasses: '@'
        },
        link: function ($) {
          var classes = {
            'c-label--success': [
              serviceStatusCodes.accepted, serviceStatusCodes.served
            ],
            'c-label--warning': [
              serviceStatusCodes.deferred, serviceStatusCodes.submitting, serviceStatusCodes.underReview,
              serviceStatusCodes.submitted, serviceStatusCodes.reviewed
            ],
            'c-label--danger': [
              serviceStatusCodes.cancelled, serviceStatusCodes.error, serviceStatusCodes.rejected,
              serviceStatusCodes.failed
            ]
          };

          var classes_string = {
            'c-label--success': [
              'accepted', 'served'
            ],
            'c-label--warning': [
              'deferred', 'submitting', 'underReview',
              'submitted', 'reviewed'
            ],
            'c-label--danger': [
              'cancelled', 'error', 'rejected',
              'failed'
            ]
          };

          $.getClass = function (code) {
            for (var _class in classes) {
              for (var i = 0; i < classes[_class].length; i++) {
                if (classes[_class][i] == code)
                  return _class;
              }
            }

            for (var _class in classes_string) {
              for (var i = 0; i < classes_string[_class].length; i++) {
                if (classes_string[_class][i] == code)
                  return _class;
              }
            }

            return '';
          }
        },
        templateUrl: '/assets/app/shared/directives/statusLabel/template.html'
      };
    }
  ]);
})(angular.module('onfileApp'));