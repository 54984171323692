;(function (app) {
    app.factory('ToastrService', [
        'toastr',
        function (toastr) {
            function ToastrService() {
                const TYPE_SUCCESS = 'success';
                const TYPE_ERROR = 'error';

                var messages = {
                    service_contact_is_required: {
                        message: 'At least one Service Contact is required',
                        title: '',
                        type: TYPE_ERROR
                    },
                    link_copied: {
                        message: 'Link to File download page copied to clipboard',
                        title: '',
                        type: TYPE_SUCCESS
                    },
                    file_moved_to_privilege_log: {
                        message: 'File moved to Privilege Log. Click "To be logged" tab to view.',
                        title: '',
                        type: TYPE_SUCCESS
                    },
                    file_moved_to_e_production: {
                        message: 'File moved back to "To be produced" queue.',
                        title: '',
                        type: TYPE_SUCCESS
                    },
                    invalid_file_uploaded: {
                        message: 'You may drag and drop PDF files only. To upload non-PDF files (and convert them to PDF), please click LOAD FROM YOUR CLOUD DRIVE.',
                        title: '',
                        type: TYPE_ERROR,
                        options: { timeOut : 20000}
                    },
                    files_uploaded: {
                        message: 'Files were successfully uploaded',
                        title: '',
                        type: TYPE_SUCCESS
                    },
                    download_instructions_missing: {
                        message: 'File Download Instructions are not loaded into the envelope',
                        title: '',
                        type: TYPE_ERROR
                    }
                };

                messages['document_deleted'] = {message: 'Document deleted', title: '', type: 'success'};
                messages['eprod_note_saved'] = {message: 'Notes saved to File Download', title: '', type: 'success'};
                messages['pending_submission_cancelled'] = {
                    message: 'Cancellation request submitted',
                    title: '',
                    type: 'success'
                };
                messages['no_payment_account'] = {
                    message: 'Add at least one payment account',
                    title: '',
                    type: 'error'
                };
                messages['parties_required'] = {
                    message: 'One or more required parties missing',
                    title: '',
                    type: 'error'
                };
                messages['eprod_not_found'] = {message: 'eProduction record not found', title: '', type: 'error'};
                messages['parties_invalid'] = {
                    message: 'Information found to be missing in one or more of the parties.',
                    title: '',
                    type: 'error'
                };
                messages['party_being_edited'] = {
                    message: 'Please finish the party information.',
                    title: '',
                    type: 'error'
                };
                messages['lead_missing'] = {message: 'Lead document missing', title: '', type: 'error'};
                messages['lead_missing_service'] = {
                    message: 'Filing form incomplete or lead document missing',
                    title: '',
                    type: 'error'
                };
                messages['something_wrong'] = {message: 'Something wrong', title: '', type: 'error'};
                messages['envelope_size'] = {
                    message: 'Reduce total file size of this envelope or submit filing(s) in one or more additional envelopes'
                    , title: 'Total file size of envelope exceeds state limit of 35MB. ', type: 'error'
                };
                messages['procedure_required'] = {
                    message: 'Procedure Remedy is required.'
                    , title: 'Procedure Remedy is required by the selected court for your case category', type: 'error'
                };
                messages['draft_deleted'] = {
                    message: 'Draft successfully deleted'
                    , title: 'Draft deleted', type: 'success'
                };
                messages['reminder_deleted'] = {
                    message: 'Reminder successfully deleted'
                    , title: 'Reminder deleted', type: 'success'
                };
                messages['no_document_found'] = {
                    message: 'Please attache at least one document with the service'
                    , title: 'Missing document attachment', type: 'error'
                };

                messages['reminder_created'] = {
                    message: 'Reminder successfully created.'
                    , title: 'New reminder', type: 'success'
                };

                messages['invalid_token'] = {
                    message: 'Invalid or expired token'
                    , title: 'Invalid token', type: 'error'
                };

                messages['phone_saved'] = {message: 'Phone number saved.', title: 'Text notification', type: 'success'};
                messages['alert_preference_changed'] = {
                    message: 'Alert preference changed.',
                    title: 'Text notification',
                    type: 'success'
                };

                this.showSuccess = function (message, title, options) {
                    toastr.success(message, title, options);
                };

                this.getMessage = function (key) {
                    return messages[key];
                };

                this.showMessageByKey = function (key) {
                    var item = messages[key];
                    var title = '';
                    var message = key;
                    var type = 'success';
                    var options;

                    if (item !== undefined && item !== null) {
                        message = item.message;
                        title = item.title;
                        type = item.type;
                        options = item.options;
                    }

                    switch (type) {
                        case 'success':
                            this.showSuccess(message, title, options);
                            break;
                        case 'error':
                            this.showError(message, title, options);
                            break;
                        default :
                            toastr.success(message, title, options);
                    }

                };

                this.showError = function (message, title, options) {
                    toastr.error(message, title, options);
                };

                this.showWarning = function (message, title, options) {
                    toastr.warning(message, title, options);
                };

                this.showInfo = function (message, title, options) {
                    toastr.info(message, title, options);
                };

                return this;
            }

            return new ToastrService();
        }
    ]);
})(angular.module('onfileApp'));
