(function (app) {
    app.controller('RecentFilingsListCtrl', [
        '$scope', 'EServiceResource', 'CaseNameService',
        'ElasticSearchResource', 'EnvironmentService', 'SearchQueryComposerBuilder',
        '$rootScope',

        function (
            $, EServiceResource, CaseNameService,
            ElasticSearchResource, EnvironmentService, SearchQueryComposerBuilder,
            $rootScope
        ) {
            $rootScope.showList = true;

            $.items = [];
            $.query = null;
            $.paginationParams = {
                size: 25,
                from: 0
            };

            $.loadServices = function () {
                var query = SearchQueryComposerBuilder.build()
                    .setQuery(ElasticSearchResource.getFilingsQueryObject())
                    .attachFiler(EnvironmentService.getUsersIdentifiers())
                    .attachFilingType()
                    .attachFilledDateSorting()
                    .attachPagination($.paginationParams)
                    .getQuery();

                $rootScope.startLoader({ message: 'Loading filings' });

                return ElasticSearchResource.searchES(query)
                    .finally(function () {
                        $rootScope.stopLoader();
                    })
                    .then(function (res) {
                        $.items = $.items.concat(res);
                        $.paginationParams.from += res.length;

                        if (!res.length) {
                            $.hideLoadMore = true;
                        }
                    });
            };

            $.loadServices();
        }
    ]);
})(angular.module('onfileApp'));
