(function (app) {
    app
        .constant('elasticSearchUrls', {
            texas: {
                production: {
                    url: 'https://us-central1-development-onfile.cloudfunctions.net/production-search-api',
                    cases: {
                        index: 'cases',
                        type: 'case'
                    },
                    filings: {
                        index: 'filings',
                        type: 'filing'
                    }
                },
                development: {
                    url: 'https://us-central1-development-onfile.cloudfunctions.net/development-search-api',
                    cases: {
                        index: 'cases',
                        type: 'case'
                    },
                    filings: {
                        index: 'filings',
                        type: 'filing'
                    }
                }
            },
            california: {
                production: {
                    url: 'https://us-central1-development-onfile.cloudfunctions.net/production-search-api',
                    cases: {
                        index: 'ca_cases',
                        type: 'case'
                    },
                    filings: {
                        index: 'ca_filings',
                        type: 'filing'
                    }
                },
                development: {
                    url: 'https://us-central1-development-onfile.cloudfunctions.net/development-search-api',
                    cases: {
                        index: 'ca_cases',
                        type: 'case'
                    },
                    filings: {
                        index: 'ca_filings',
                        type: 'filing'
                    }
                }
            },
            illinois: {
                development: {
                    url: 'https://us-central1-development-onfile.cloudfunctions.net/development-search-api',
                    cases: {
                        index: 'il_cases',
                        type: 'case'
                    },
                    filings: {
                        index: 'il_filings',
                        type: 'filing'
                    }
                },
                production: {
                    url: 'https://us-central1-development-onfile.cloudfunctions.net/production-search-api',
                    cases: {
                        index: 'il_cases',
                        type: 'case'
                    },
                    filings: {
                        index: 'il_filings',
                        type: 'filing'
                    }
                }
            }
        });
})(angular.module('onfileApp'));
