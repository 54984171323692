;(function (app) {
    app.controller('SubmittedEProductionCtrl', [
        '$scope', '$rootScope', '$stateParams',
        'EnvironmentService', 'FirebaseService', 'ToastrService',
        'EServiceResource',

        function ($, $rootScope, $stateParams,
                  EnvironmentService, FirebaseService, ToastrService,
                  EServiceResource) {
            angular.extend($rootScope, {
                showList: true,
                activeSubmittedEProductionId: null
            });

            angular.extend($, {
                downloadPdfLink: null,
                eProduction: null,
                packageId: $stateParams.id,
                email: FirebaseService.email,
                emailHash: FirebaseService.emailHash,
                firmId: EnvironmentService.firm_id(),

                linkCopied: function () {
                    ToastrService.showMessageByKey('link_copied');
                }
            });

            const getInstructionsFileLink = function (documents) {
                const instructionFilenameRegexp = /instruction/gi;
                const statuses = ['transmitted', 'original'];

                var status;
                var document;

                if (documents && documents.length) {
                    for (var i = 0; i < documents.length; i++) {
                        document = documents[i];

                        if (!document) {
                            continue;
                        }

                        for (var j = 0; j < statuses.length; j++) {
                            status = statuses[j];

                            if (
                                document[status] && document[status].name &&
                                document[status].name.match(instructionFilenameRegexp)
                            ) {
                                return document[status].link;
                            }
                        }
                    }
                }

                return null;
            };

            this.uiOnParamsChanged = function (changedParams) {
                $rootScope.activeSubmittedEProductionId = changedParams.id;
                $.packageId = changedParams.id;

                $rootScope.$watch('eProductionsSubmitted', function (eProductionsSubmitted) {
                    if (!eProductionsSubmitted) {
                        return false;
                    }

                    for (var i = 0; i < eProductionsSubmitted.length; i++) {
                        if (eProductionsSubmitted[i].eProdId === changedParams.id) {
                            $.eProduction = eProductionsSubmitted[i];
                            break;
                        }
                    }

                    EServiceResource
                        .get({
                            id: $.eProduction.batch_header.filing_id,
                            firebaseDetail: true
                        })
                        .$promise
                        .then(function (eService) {
                            $.downloadPdfLink = getInstructionsFileLink(eService.documents);
                        });

                    $.eProdLink = window.location.origin + '/eproduction/' + EnvironmentService.firm_id() + '/' + $.eProduction.eProdId;
                });
            };

            this.uiOnParamsChanged({id: $stateParams.id});
        }
    ])
})(angular.module('onfileApp'));
