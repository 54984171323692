;(function (app) {
    app.service('CaseSearchService', [
        'CaseService',
        function (CaseService) {
            this.init = function (list) {
                var caseTitle;
                var caseTitleNormalized;
                var casesNameMap = {};
                var casesRaw = {};

                for (var i = 0; i < list.length; i++) {
                    caseTitle = CaseService.composeCaseName(list[i], false);
                    caseTitleNormalized = caseTitle ? caseTitle.toLowerCase() : '';

                    if (!casesNameMap[caseTitleNormalized]) {
                        casesNameMap[caseTitleNormalized] = [];
                    }

                    casesNameMap[caseTitleNormalized].push(list[i].id);
                    casesRaw[list[i].id] = Object.assign({}, list[i], {
                        case_title: caseTitle
                    });
                }

                this.casesNameMap = casesNameMap;
                this.casesRaw = casesRaw;

                return this;
            };

            this.search = function (search, limit) {
                var cases = [];
                var names = Object.keys(this.casesNameMap);

                for (var i = 0; i < names.length; i++) {
                    var caseName = names[i];

                    if (caseName.indexOf(search) < 0) {
                        continue;
                    }

                    for (var j = 0; j < this.casesNameMap[caseName].length; j++) {
                        var caseId = this.casesNameMap[caseName][j];

                        if (limit && cases.length >= limit) {
                            return cases;
                        }

                        cases.push(this.casesRaw[caseId]);
                    }
                }

                return cases;
            };
        }
    ]);
})(angular.module('onfileApp'));