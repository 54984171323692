;(function (app) {
  app.directive('uiSelectRequired', function () {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, elm, attributes, ctrl) {
        // If required attribute changes, validates the form.
        attributes.$observe('uiSelectRequired', function () {
          ctrl.$validate();
        });
        
        ctrl.$validators.required = function (modelValue) {
          // If model is not required
          if (!attributes.hasOwnProperty('uiSelectRequired') || attributes.uiSelectRequired !== 'true') {
            return true;
          }

          return !!(modelValue && modelValue.length);
        };
      }
    };
  });
})(angular.module('onfileApp'));