;(function (app) {
    app.controller('CaseInformationCtrl', [
        'CourtResource', 'CaseCategoryResource', 'CaseTypeResource',
        'FilerTypeResource', 'AttorneyResource', 'DamageAmountResource',
        'ProcedureRemedyResource', 'PaymentAccountResource',
        '$scope', '$rootScope', 'ToastrService', 'AttorneyService', 'MarketRulesService',

        function (CourtResource, CaseCategoryResource, CaseTypeResource,
                  FilerTypeResource, AttorneyResource, DamageAmountResource,
                  ProcedureRemedyResource, PaymentAccountResource,
                  $, $rootScope, ToastrService, AttorneyService, MarketRulesService) {
            angular.extend($, {
                courts: [],
                categories: [],
                types: [],
                filerTypes: [],
                attorneys: [],
                damageAmounts: [],
                procedureRemedies: [],
                paymentAccounts: [],

                filterCaseTypes: function (category) {
                    return category ? $.types.filter(function (o) {
                        return o.initial === 'True' && o.casecategory === category;
                    }) : $.types;
                },
                filterCaseCategories: function () {
                    if (!$.categories || $.categories.length === 0) {
                        return [];
                    }

                    if (!$.types || $.types.length === 0) {
                        return $.categories;
                    }

                    return $.categories.filter(function (c) {
                        return $.types.find(function (t) {
                            return t.initial === 'True' && t.casecategory === c.code;
                        });
                    });
                }
            });

            //procedure rememedy checkbox list requieremwnt

            $.procedureTextChanged = function (ev) {
                ev.stopPropagation();
            };

            $.procedureSearchTerm = '';
            $.clearSearchTerm = function () {
                this.procedureSearchTerm = '';
            };

            $.selectCourt = function (court) {

                $.case.court = court;
                $.selectedCourt = court;

                if (court !== undefined && court !== null)
                    $.case.court_code = court.code;

                return $.getCaseCategories(court);
            };

            $.rules = MarketRulesService;
            $.nonIndexFiling = false;
            $rootScope.nonIndexedCaseFiling = false;

            // for non-indexed case court_code
            //TODO: need to simplify this
            if ($.case.court_code !== undefined) {
                $.nonIndexFiling = $.case.nonIndexedCaseFiling; //!$.case.hasOwnProperty('draft') || $.case.hasOwnProperty("nonIndexCaseFiling") ;
                $rootScope.nonIndexedCaseFiling = $.case.nonIndexedCaseFiling; //!$.case.hasOwnProperty('draft') || $.case.hasOwnProperty("nonIndexCaseFiling");

                CourtResource.query({search: $.case.court_code}).$promise
                    .then(function (res) {
                        if (res === null || res.length === 0) {
                            console.log("could not translate court code into court object from the server");
                            throw "could not translate court code into court object from the server";
                        }
                        var court = res[0];

                        $.case.court = court;
                        $.courtSearch = court.name;
                        $.selectCourt(court);

                        var nonindexed = $.case.nonIndexedCaseFiling; //!$.case.hasOwnProperty('draft') || $.case.hasOwnProperty("nonIndexCaseFiling");

                        $rootScope.nonIndexedCaseFiling = nonindexed;
                        $rootScope.nonIndexFiling = nonindexed;
                        $.nonIndexFiling = nonindexed;
                        $.nonIndexedCaseFiling = nonindexed;
                        $.case.nonIndexCaseFiling = nonindexed;
                    });
            }

            // Loads Firm's attorneys
            AttorneyResource.query().$promise
                .then(function (attorneys) {
                    for (var i = 0; i < attorneys.length; i++) attorneys[i] = new AttorneyService(attorneys[i]);
                    $.attorneys = attorneys;
                });

            // Loads Payment accounts
            PaymentAccountResource.query().$promise
                .then(function (paymentAccounts) {
                    $.paymentAccounts = paymentAccounts;
                    if (paymentAccounts === null || paymentAccounts === undefined || paymentAccounts.length === 0)
                        ToastrService.showMessageByKey('no_payment_account');
                });

            // Function for searching a Court by name
            $.searchCourt = function (search) {
                //CourtResource.query({search: search}).$promise
                CourtResource.query().$promise
                    .then(function (res) {
                        $.courts = res;
                    });
            };

            //$rootScope.forceSelectCourt = function (court) { $.selectCourt(court) };


            $.filterCourts = function (query) {
                var results = query ? $.courts.filter(createFilterFor(query)) : $.courts;
                return results;
            };

            function createFilterFor(query) {
                var lowercaseQuery = angular.lowercase(query);

                return function filterFn(item) {
                    return (angular.lowercase(item.name).indexOf(lowercaseQuery) === 0);
                };

            }


            CourtResource.query({ initial: true })
                .$promise
                .then(function (res) {
                    $.courts = res;
                });

            /**
             * @param eCase
             * @returns {boolean}
             */
            $.isNextStepVisible = function (eCase) {
                var result;
                if (!eCase)
                    result = false;
                else
                    result = eCase ? !!(eCase.court && eCase.category && eCase.type) : false;

                //$.nextStepVisible = result;

                return result;
            };

            /**
             * Callback for changing a Court location
             *
             * @param court
             * @returns {boolean}
             */
            $.getCaseCategories = function (court) {
                $.categories = [];
                $.types = [];
                $.filerTypes = [];
                $.damageAmounts = [];
                $.procedureRemedies = [];

                // If passed Court is invalid terminates loading
                if (!court || !court.hasOwnProperty('code')) {
                    return false;
                }

                // Loads Case Category Codes
                CaseCategoryResource.query({court_code: court.code}).$promise
                    .then(function (categories) {
                        $.categories = categories;
                    });

                // Loads Case Type Codes
                CaseTypeResource.query({court_code: court.code}).$promise
                    .then(function (types) {
                        $.types = types;
                    });

                // Loads Filer Type Codes
                FilerTypeResource.query({court_code: court.code}).$promise
                    .then(function (types) {
                        $.filerTypes = types;
                    });

                // Loads Damage Amount codes
                DamageAmountResource.query({courtCode: court.code}).$promise
                    .then(function (damageAmounts) {
                        $.damageAmounts = damageAmounts;
                    });

                // Loads Procedure Remedy codes
                ProcedureRemedyResource.query({courtCode: court.code}).$promise
                    .then(function (procedureRemedies) {
                        $.procedureRemedies = procedureRemedies;
                    });

                return true;
            };

            $.validate = function () {
                var valid = true;

                if (!$.case.category)
                    return false;

                var procedureRemedyRequired = (!$.nonIndexedCaseFiling && $.case.category.procedureremedyinitial === 'Required')
                    || ($.nonIndexedCaseFiling && $.case.category.procedureremedysubsequent === 'Required');

                if (procedureRemedyRequired && ($.case.procedureRemedies === null || $.case.procedureRemedies.length === 0)) {
                    valid = false;
                    ToastrService.showMessageByKey("procedure_required");
                }

                valid = valid && $.caseForm.$valid;

                $.caseForm.$setSubmitted();

                return valid;
            };

            $.$watch('[caseForm.$valid, caseForm.$submitted]', function () {
                $.$emit('caseInformationValidationState', $.caseForm);
            });

            if ($.case.court) {
                $.selectCourt($.case.court);
            }
        }
    ]);
})(angular.module('onfileApp'));