(function (app) {
    app.controller('CreateFilingCtrl', [
        'EFilingResource', 'RefreshDataService', 'TextNotificationService',
        '$scope', '$rootScope', '$controller',
        'GAService', 'CaseService', 'FilingCreateCacheService', '$stateParams',
        'EnvironmentService', 'FirebaseService', '$state',
        'reloadOnEmptyRouterHelper',

        function (
            EFilingResource, RefreshDataService, TextNotificationService,
            $, $rootScope, $controller,
            GAService, CaseService, FilingCreateCacheService,
            $stateParams, EnvironmentService, FirebaseService,
            $state, reloadOnEmptyRouterHelper
        ) {
            $.nextState = 'filingCreate.accordion';
            $.isFiling = true;
            $.isEProductionPackage = false;

            $rootScope.isCaseInformationStepValid = false;
            $rootScope.isPartiesInformationStageisValid = false;
            $rootScope.isServiceContactStageIsValid = false;
            $rootScope.isDocumentStageIsValid = false;

            $rootScope.serviceType = 'efiling';

            // inheriting of a behavior from CreateEServiceCtrl
            angular.extend(this, $controller('CreateEServiceCtrl', {$scope: $}));

            $.updateResubmittedFiling = function (newId) {
                var resubmitFilingId = null;
                if ($stateParams && $stateParams.hasOwnProperty('resubmitFilingId')) {
                    resubmitFilingId = $stateParams.resubmitFilingId;
                }

                if (!resubmitFilingId || resubmitFilingId.length < 10) {
                    return;
                }

                var nodePath = '/filings/' + EnvironmentService.firm_id() + '/filings/data/'  + resubmitFilingId;

                return FirebaseService.updateNode(nodePath, { resubmitted: newId });
            }

            $.createService = function () {
                EFilingResource.save(CaseService.prepareBeforeSend($.case))
                    .$promise
                    .then(function (res) {
                        if (!res || !res.hasOwnProperty('id')) {
                            throw res;
                        }

                        // get resubmitFilingId to update old filing
                        $.updateResubmittedFiling(res.id);

                        GAService.addNewEFiling($.case, res);

                        if ($.case.text_alert_on_change) {
                            TextNotificationService.saveNotificationForFiling(res.id, {case: $.case});
                        }
                        RefreshDataService.forceRefresh();

                        //this method is defined in CreateEServiceCtrl
                        $.completeTheDraft();
                        // Forming variables for success popup
                        $.creationStatus = true;
                        $.newFilingId = res.id;
                        $.newFilingEnvelopeId = res.envelope_id;
                    })
                    .then(function () {
                        return FilingCreateCacheService.save($.case);
                    });
            };

            $.isValidToSubmit = function () {
                return true;
            };

            $rootScope.isNewPartyAllowed = true;

            this.uiOnParamsChanged = reloadOnEmptyRouterHelper($state);
        }
    ]);
})(angular.module('onfileApp'));
