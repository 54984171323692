(function (app) {
    app.service('UserNotificationService', [
        'ToastrService', 'FirebaseService', 'EnvironmentService', '$window',
        function (ToastrService, FirebaseService, EnvironmentService, $window) {
            var viewedNotificationNode = 'viewedNotification';

            this.check = function () {
                var isAuth = !!EnvironmentService.userId();

                if (isAuth) {
                    return this.showForUser();
                }

                return this.showForGuest();
            };

            this.showForGuest = function () {
                return FirebaseService.getNode('/configuration/userNotifications/guest').$loaded().then(function (v) {
                    if (!v || !v.text) {
                        return null;
                    }

                    return ToastrService.showWarning(v.text, {
                        autoDismiss: false,
                        allowHtml: true,
                        timeOut: 0,
                        extendedTimeOut: 0,
                        closeButton: true,
                        tapToDismiss: false,
                        toastClass: 'toast toast-without-icon'
                    });
                });
            };

            this.showForUser = function () {
                return FirebaseService.getNode('/configuration/userNotifications/auth').$loaded().then(function (v) {
                    var viewedNotification = $window.sessionStorage.getItem(viewedNotificationNode);

                    if (!v || !v.text || viewedNotification) {
                        return null;
                    }

                    return ToastrService.showWarning(v.text, {
                        autoDismiss: false,
                        allowHtml: true,
                        timeOut: 0,
                        extendedTimeOut: 0,
                        closeButton: true,
                        tapToDismiss: false,
                        onHidden: function () {
                            return $window.sessionStorage.setItem(viewedNotificationNode, '1');
                        },
                        toastClass: 'toast toast-without-icon'
                    });
                });
            };
        }
    ]);
})(angular.module('onfileApp'));
