;(function (app) {
  app.factory('CaseCategoryResource', ['$resource',
    function ($resource) {
      return $resource(
        '/caseCategories?court_code=:court_code&code=:code', {court_code: '@court_code', code: '@code'}, {
          query: {
            method: 'GET',
            isArray: true,
            cache: true
          },
          get: {
            url: '/caseCategories/detail?court_code=:court_code&code=:code',
            method: 'GET',
            isArray: false,
            cache: true
          }
        }
      );
    }]);
})(angular.module('onfileApp'));