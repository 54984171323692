;(function (app) {
    app.directive('paymentReport', [
        'ReportResource', 'FilingTransformer', 'moment',
        '$rootScope', '$filter', 'CsvExportService',
        'caseSearchFilter', 'ReportsService',

        function (
            ReportResource, FilingTransformer, moment,
            $rootScope, $filter, CsvExportService,
            caseSearchFilter, ReportsService
        ) {
            return {
                strict: 'A',
                scope: {
                    paymentAccounts: '=?',
                    cases: '=?',
                    attorneys: '=?'
                },
                link: function ($) {
                    angular.extend($, {
                        dateFormat: $rootScope.longDate,
                        dateFormatForView: $rootScope.shortDate,
                        query: {
                            paymentAccount: null,
                            case: null,
                            date: {
                                from: moment().subtract(7, 'days').toDate(),
                                to: moment().toDate()
                            }
                        },
                        rawFilingDetails: null,
                        reportFeeHeaders: ReportsService.getFeesHeaders(),

                        getFeeValue: function (key, item) {
                            return ReportsService.getFeeValue(key, item);
                        },
                        filterItems: caseSearchFilter(),
                        calculatePaymentReport: function () {
                            var query = {
                                paymentAccount: $.query.paymentAccount && $.query.paymentAccount.accountName ?
                                    $.query.paymentAccount.accountName : null,
                                caseId: $.query.case ? $.query.case.id : null,
                                date: {
                                    from: $.query.date && $.query.date.from ? formatDate($.query.date.from) : null,
                                    to: $.query.date && $.query.date.to ? formatDate($.query.date.to) : null
                                }
                            };
                            var request = ReportResource.payment(query);
                            $rootScope.loading_message = 'Generating report';
                            $rootScope.cancelableRequests = [request];

                            return request.$promise
                                .then(function (rawFilingDetails) {
                                    $.rawFilingDetails = rawFilingDetails.map(function (filing) {
                                        return FilingTransformer.import(filing);
                                    });
                                })
                                .finally(function () {
                                    $rootScope.removeFromCancelableQueue(request);
                                    $rootScope.loading_message = '';
                                });
                        },
                        export: function () {
                            var items = ReportsService.transformPaymentReportForImport({
                                attorneys: $.attorneys,
                                dateFormat: $.dateFormat,
                                rawFilingDetails: $.rawFilingDetails
                            });
                            var filename = 'Payment-account-report_' +
                                $filter('amDateFormat')(new Date(), $rootScope.shortDate) + '.csv';

                            return CsvExportService.exportAndDownload({
                                items: items,
                                filename: filename
                            });
                        }
                    });

                    var formatDate = function (date) {
                        return date ? moment(date).format('MM/DD/YYYY') : null;
                    };
                },
                templateUrl: '/assets/app/components/reports/payment/template.html?{{onfileApplicationVersion}}'
            };
        }
    ]);
})(angular.module('onfileApp'));
