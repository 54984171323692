;(function (app) {
    app.factory('UsersResource', [
        '$resource',
        function ($resource) {
            return $resource('/users', {}, {
                query: {
                    method: 'GET',
                    isArray: true,
                    cache: true
                }
            });
        }]
    );
})(angular.module('onfileApp'));