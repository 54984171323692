;(function (app) {
    app.factory('MarketRulesService', [
        'EnvironmentService', 'SiteConfigurationService',
        function (EnvironmentService, SiteConfigurationService) {
            function MarketRulesService() {
                var visibilityRules = {};
                var requiredRulesForCurrentMarket = {};
                var requiredRules = {
                    filing_attorney: {default: false, illinois: true},
                    serviceContacts: {default: true, illinois: false}
                };

                this.loaded = SiteConfigurationService.getVisibilityRules()
                    .then(function (e) {
                        visibilityRules = e;

                        return SiteConfigurationService.getRequiredRules();
                    })
                    .then(function (e) {
                        requiredRulesForCurrentMarket = e;
                    });

                this.enabled = this.isVisible = function (name) {
                    if (visibilityRules && visibilityRules[name] !== undefined) {
                        return visibilityRules[name];
                    }

                    return false;
                };

                this.isRequired = function (name) {
                    if (requiredRulesForCurrentMarket && requiredRulesForCurrentMarket[name] !== undefined) {
                        return requiredRulesForCurrentMarket[name];
                    }

                    var market = EnvironmentService.market();

                    if (requiredRules && requiredRules[name] !== undefined) {
                        var rule = requiredRules[name];

                        if (rule[market] !== undefined) {
                            return rule[market];
                        }

                        return rule.default;
                    }
                };

                return this;
            }

            return new MarketRulesService();
        }]
    );
})(angular.module('onfileApp'));
