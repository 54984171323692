;(function (app) {
    app.factory('CaseService', [
        'EServiceResource', 'FilingService', 'FirebaseService',
        'DataProvider', '$rootScope', '$q',
        'EnvironmentService', 'CaseResource',

        function CaseService(
            EServiceResource, FilingService, FirebaseService,
            DataProvider, $rootScope, $q,
            EnvironmentService, CaseResource
        ) {
            var NAME_UNAVAILABLE = 'Name unavailable';
            var lastUsedAtProperty = 'lastUsedAt';

            this.DEFAULT_COURT_CODE = function () {
                switch (EnvironmentService.market()) {
                    case 'texas':
                        return 'coa:8';
                    case 'illinois':
                        return 'peoria';
                    case 'california':
                        return 'alameda:cr';
                    default:
                        throw new Error('invalid market');
                }
            };

            /**
             * Composes Cases title
             *
             * @param {object} eCase
             * @param {boolean} returnNullIfCannotCompute
             * @returns {*}
             * returnNullIfCannotCompute may not even be used
             */
            this.composeCaseName = function (eCase, returnNullIfCannotCompute) {
                var defaultNameToReturn = returnNullIfCannotCompute ? null : NAME_UNAVAILABLE;
                var parts = [];

                if (eCase && eCase.hasOwnProperty('title') && eCase.title)
                    return eCase.title;

                // Checks if Case has participants in it
                if (
                    !eCase || !eCase.hasOwnProperty('participants') ||
                    !eCase.participants || !eCase.participants.hasOwnProperty('length') || !eCase.participants.length
                ) {
                    return defaultNameToReturn;
                }

                for (var i = 0; i < eCase.participants.length; i++) {
                    if (eCase.participants[i].hasOwnProperty('name')
                        && eCase.participants[i].name
                        && eCase.participants[i].type_code !== 'ATTY'
                    ) {
                        parts.push(
                            eCase.participants[i].name.trim()
                        );
                    }
                }
                return parts.length ? parts.join(', ') : defaultNameToReturn;
            };

            /**
             * Loads list of Cases through Services
             *
             * @returns {Promise}
             */
            this.getList = function () {
                return DataProvider.getList('cases');
            };

            this.getListPage = function (options) {
                var fieldOrderBy = 'title';
                var modifier = function (query) {
                    query = query
                        .orderByChild(fieldOrderBy)
                        .limitToFirst(options.limit);

                    if (options.lastCase) {
                        query = query.startAt(options.lastCase[fieldOrderBy]);
                    }

                    return query;
                };

                return DataProvider.getList('cases', { modifier: modifier, ignoreCached: true })
                    .then(function (cases) {
                        if (!options.lastCase || !options.lastCase[fieldOrderBy]) {
                            return cases;
                        }

                        return cases.filter(function (c) {
                            return c[fieldOrderBy] !== options.lastCase[fieldOrderBy];
                        });
                    });
            };

            this.searchByName = function (name) {
                var defer = $q.defer();
                var nodePath = DataProvider.getPath('cases');

                try {
                    FirebaseService.getRef(nodePath)
                        .orderByChild('title')
                        .startAt(name)
                        .on('value', function (snapshot) {
                            defer.resolve(snapshot.val());
                        });
                } catch (e) {
                    defer.reject(e);
                }


                return defer.promise;
            };

            this.getById = function (caseId) {
                return FirebaseService
                    .getNode('/cases', function (query) {
                        return query.orderByChild('case/id')
                            .equalTo(caseId)
                            .limitToFirst(1);
                    })
                    .$loaded()
                    .then(function (services) {
                        for (var prop in services) {
                            if (services.hasOwnProperty(prop) && services[prop] && services[prop].case) {
                                return services[prop].case;
                            }
                        }

                        return null;
                    });
            };

            /**
             * Queries a Case from Firebase. If it doesn't exist in Firebase queries it from Tyler and saves in Firebase
             * for further usage.
             *
             * @return {Promise}
             */
            this.loadOrImport = function (caseId, courtCode) {
                return CaseResource
                    .get({id: caseId})
                    .$promise
                    .then(function (eCase) {
                        if (FirebaseService.objectExists(eCase) === false) {
                            throw eCase;
                        }

                        return eCase;
                    })
                    .catch(function (eCase) {
                        var casePath = eCase.$$conf.ref.path.toString();

                        return CaseResource.get({
                            court_code: courtCode,
                            tracking_number: caseId,
                            with: 'attorneys,participants,service_contacts',
                            direct: true
                        }).$promise
                            .then(function (tylerCase) {
                                var isInvalid = typeof tylerCase === 'string' ||
                                    !tylerCase || !tylerCase.hasOwnProperty('id');

                                if (isInvalid) {
                                    throw new Error('Error of getting Case from Tyler');
                                }

                                return FirebaseService.saveNode(
                                    casePath,
                                    FirebaseService.removeRedundantNodes(tylerCase)
                                );
                            })
                            .then(function () {
                                return CaseResource.get({id: caseId}).$promise;
                            });
                    });
            };

            this.loadWithoutImport = function (caseId, courtCode) {
                var _this = this;

                return CaseResource
                    .get({id: caseId})
                    .$promise
                    .then(function (eCase) {
                        if (FirebaseService.objectExists(eCase) === false) {
                            throw eCase;
                        }

                        return eCase;
                    })
                    .catch(function () {
                        return CaseResource.get({
                            court_code: courtCode,
                            tracking_number: caseId,
                            with: 'attorneys,participants,service_contacts',
                            direct: true
                        }).$promise;
                    })
                    .then(function (eCase) {
                        return Object.assign(
                            {},
                            _this.clear(eCase),
                            {
                                attorneys: _this.clear(eCase.attorneys),
                                participants: eCase.participants,
                                service_contacts: _this.clear(eCase.service_contacts)
                            }
                        );
                    });
            };

            this.getParticipantById = function (query) {
                var caseId = query.caseId;
                var participantId = query.participantId;
                var courtCode = query.courtCode || this.DEFAULT_COURT_CODE();

                return this.loadOrImport(caseId, courtCode)
                    .then(function (res) {
                        if (res && res.participants && res.participants.length) {
                            return res.participants
                                .find(function (participant) {
                                    return participant.id === participantId;
                                });
                        }
                    })
                    .then(function (participant) {
                        return participant || '';
                    });
            };

            this.direct = function (caseId, courtCode, query) {
                if (typeof courtCode === 'object') {
                    query = courtCode;
                    courtCode = this.DEFAULT_COURT_CODE();
                }
                courtCode = courtCode || this.DEFAULT_COURT_CODE();

                return CaseResource
                    .get(Object.assign({}, {
                        tracking_number: caseId,
                        direct: true,
                        court_code: courtCode
                    }, query || {}))
                    .$promise;
            };

            this.getNodePath = function (caseId) {
                return '/filings/' + EnvironmentService.firm_id() + '/cases/data/' + (caseId || '');
            };

            this.clear = function (node) {
                var fun = function (item) {
                    if (item && item.all) {
                        delete item.all;
                    }
                    if (item && item.StopWatch) {
                        delete item.StopWatch;
                    }

                    return item;
                };

                if (node) {
                    node = fun(node);
                }

                return node && node.map ? node.map(fun) : node;
            };

            this.prepareBeforeSend = function (eCase) {
                var filterServiceContact = function (item) { return item.included; };

                return Object.assign({}, eCase, {
                    service_contacts: eCase.service_contacts.filter(filterServiceContact)
                });
            };

            this.updateDetails = function (eCase) {
                if (eCase && eCase.StopWatch !== undefined) {
                    delete eCase.StopWatch;
                }

                return CaseResource.get({ id: eCase.id })
                    .$promise
                    .then(function (c) {
                        var path = c.$$conf.ref.path.toString();
                        var isInvalid = typeof eCase === 'string' ||
                            !eCase || !eCase.hasOwnProperty('id');

                        if (isInvalid) {
                            throw new Error('Error of getting Case from Tyler');
                        }

                        var newCase = Object.assign({}, eCase, {
                            sync_status: c.sync_status || null
                        });
                        var data = FirebaseService.purifyObject(newCase);

                        return FirebaseService.saveNode(path, data);
                    });
            };

            this.compose = function (eCase, filings) {
                eCase = window.angular.extend(eCase, { filings: filings });

                if (eCase.hasOwnProperty('prepare')) {
                    eCase.prepare();
                }

                return eCase;
            };

            this.getServiceContactsForParty = function (caseId) {
                var path = '/serviceContacts/' + caseId;

                return FirebaseService.getNode(path).$loaded();
            };

            this.saveServiceContactsForParty = function (params) {
                var caseId = params.caseId;
                var serviceContactId = params.serviceContactId;
                var partyId = params.partyId;
                var path = '/serviceContacts/' + caseId + '/' + serviceContactId + '/partyId';

                return FirebaseService.saveNode(path, partyId);
            };

            this.updateLastUsedAt = function (eCase) {
                var path = eCase.$$conf.ref.path.toString();

                return FirebaseService.saveNode(path + '/' + lastUsedAtProperty, +new Date());
            };

            this.populateName = function (eCase) {
                eCase.isNameAvailable = !this.composeCaseName(eCase, true);
                eCase.case_title_composed = this.composeCaseName(eCase);
                eCase.court_name = eCase.court && eCase.court.name ? eCase.court.name : '';

                return eCase;
            };

            this.participantsToString = function (eCase, defaultName) {
                if (!eCase || !eCase.participants || !eCase.participants.length) {
                    return defaultName || '';
                }

                return eCase.participants
                    .map(function (p) { return p.name; })
                    .filter(function (p) { return p; })
                    .join(', ');
            };

            this.fixNames = function (items) {
                return items.map(function (item) {
                    item.case_title = this.composeCaseName(item);
                    item.participantsString = this.participantsToString(item);

                    return item;
                }.bind(this));
            }.bind(this);

            return this;
        }
    ]);
})(angular.module('onfileApp'));