;(function (app) {
    app.service('FilestackService', [
        '$window', '$q', 'filepickerApikey', 'filepickerSecurityEnabled',
      'EnvironmentService', 'SecurityService',

        function ($window, $q, filepickerApikey, filepickerSecurityEnabled, EnvironmentService, SecurityService) {
            const PDF_MIMETYPE = 'application/pdf';
            const dialogParams = {
                services: ['COMPUTER', 'BOX', 'DROPBOX', 'GOOGLE_DRIVE', 'SKYDRIVE', 'FTP'],
                container: 'modal',
                multiple: true,
                folders: true,
                extensions: ['.txt', '.rtf', '.doc',
                  '.docx', '.pdf', '.png', '.jpg',
                  '.jpeg', '.gif', '.bmp', '.tif',
                  '.tiff', '.odt', '.odp', '.xls',
                  '.xlsx', '.html', '.htm', '.ppt',
                  '.pptx', '.ods', '.psd', '.ai', 'svg'],
            };

            $window.filepicker.setKey(filepickerApikey);

            this.openDialogAndUpload = function (params) {
                const defer = $q.defer();
                const promise = defer.promise;
                const storeParams = {
                    location: 'S3',
                    access: 'public',
                    container: EnvironmentService.s3BucketLocation(params.location)
                };

                SecurityService.getDialogSecParams().then(function(secParams){
                  angular.extend(dialogParams,secParams);
                  $window.filepicker.pickAndStore(
                      dialogParams,
                      storeParams,
                      function (result) {
                          defer.resolve(result);
                      }
                  );

                });

                return promise.then(function (result) {
                    const promises = result.map(function (file) {
                        if (file.mimetype === PDF_MIMETYPE) {
                            return $q.resolve(file);
                        }

                        return this.convertToPdf(file, storeParams)
                            .then(function (converted) {
                                try {
                                  this.deleteFile(file)
                                } catch (e) {
                                    // Ignore
                                }

                                return converted;
                            });
                    }.bind(this));

                    return $q.all(promises);
                }.bind(this));
            };

            this.deleteFile = function (file) {
                const defer = $q.defer();

                try {
                    SecurityService.getRemoveSecParams().then(function(secParams){
                      $window.filepicker.remove(
                          file.url,
                          secParams,
                          function () {
                              defer.resolve(true);
                          },
                          function (err) {
                              defer.reject(err);
                          }
                      );

                    });
                } catch (err) {
                    defer.reject(err);
                }

                return defer.promise;
            };

            this.convertToPdf = function (file, storeParams) {
                const defer = $q.defer();
                const f = {
                    url: file.url,
                    size: file.size,
                    isWriteable: false,
                    mimetype: 'image/png',
                    filename: file.filename
                };

                try {
                    SecurityService.getConvertSecParams().then(function(convertParams){
                      convertParams.format = "pdf";
                      $window.filepicker.convert(
                          f,
                          convertParams,
                          storeParams,
                          function (converted) {
                              defer.resolve(converted);
                          }
                      );

                    })
                } catch (e) {
                    defer.reject(e);
                }
                return defer.promise;
            };

            this.addDisplayFilename = function (files) {
                return files.map(function (file) {
                    return Object.assign(
                        {},
                        file,
                        {display_filename: file.filename.split('.').shift()}
                    )
                });
            };
        }
    ]);
})(angular.module('onfileApp'));
