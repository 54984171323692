;(function (app) {
    app.factory('FilingService', [
        'serviceStatusCodes', 'EnvironmentService',
        function FilingService(serviceStatusCodes, EnvironmentService) {

            /**
             * @param eCase
             * @returns {boolean}
             */
            this.isCaseAvailableForFiling = function (eCase) {
                if (!eCase || !eCase.hasOwnProperty('is_filing_allowed'))
                    return false;

                return !!eCase.is_filing_allowed;
            };

            this.isCompleted = function (filingCode) {
                filingCode = parseInt(filingCode);
                var available = [
                    serviceStatusCodes.served,
                    serviceStatusCodes.accepted,
                    serviceStatusCodes.underReview,
                    serviceStatusCodes.reviewed];

                return available.indexOf(filingCode) >= 0;
            };

            this.caseForFilingAvailable = function (filingCode) {

                filingCode = parseInt(filingCode);
                var available = [
                    serviceStatusCodes.accepted];

                return available.indexOf(filingCode) >= 0;
            };

            this.isCancelable = function (filingCode) {
                filingCode = parseInt(filingCode);

                var cancelable = [
                    serviceStatusCodes.deferred,
                    serviceStatusCodes.submitting,
                    serviceStatusCodes.submitted
                ];

                return cancelable.indexOf(filingCode) >= 0;
            };

            var _services;

            this.services = function (services) {
                _services = services;
            };

            this.getFilingAttorney = function (filingId) {
                if (_services && _services.length > 0) {
                    for (var i = 0; i < _services.length; i++) {
                        var service = _services[i];

                        if (service.id === filingId) {
                            if (service.hasOwnProperty("filing_attorney"))
                                return service.filing_attorney;
                        }
                    }
                }
            };

            this.isFiling = function (item) {
                return item && (item.type_code === 'filing' || item.type_code === 'service_and_filing' || item.type_code === undefined);
            };

            this.isService = function (item) {
                return item && item.type_code === 'service';
            };

            this.isRejected = function (filing) {
                var statuses = [ serviceStatusCodes.rejected ];

                return (filing && filing.status_code) ?
                    statuses.indexOf(filing.status_code) >= 0 : false;
            };

            this.canBeResubmitted = function (filing) {
                var available = [ serviceStatusCodes.rejected ];

                if (filing && filing.status_code) {
                    return available.indexOf(filing.status_code) >= 0;
                }

                return false;
            };

            this.isSubsequentFiling = function (item) {
                return !!(item && (item.case && item.case.id && !hasError28(item)));
            };

            function hasError28(item){
                var errors = item.case.errors;

                if(errors){
                    for(var i=0 ; i < errors.length; i++)
                        if(errors[i]['ErrorCode'] === 28)
                            return true;
                }

                return false;
            }

            this.isInitialFiling = function (item) {
                return this.isSubsequentFiling(item) === false;
            };

            this.typeFilterOptions = [
                {
                    title: 'All',
                    value: 'all',
                    class: ''
                }, {
                    title: 'Accepted',
                    value: 'accepted',
                    class: 'c-label--success'
                }, {
                    title: 'Pending',
                    value: 'pending',
                    class: 'c-label--warning'
                }, {
                    title: 'Rejected or cancelled',
                    value: 'failed',
                    class: 'c-label--danger'
                }
            ];



            this.selectedTypeFilter = this.typeFilterOptions[0];

            this.selectedTypeFilterClass = function (status) {
                var neededType = this.typeFilterOptions.find(function (type) {
                    return type.title === status;
                });

                return neededType && neededType.class || '';
            };

            this.isUserFiler = function (service, listOfFilers) {
                if (!listOfFilers || !listOfFilers.length) {
                    return true;
                }

                var isCurrentUser = false;

                if (service && service.document_submitter && service.document_submitter.email) {
                    isCurrentUser = listOfFilers.indexOf(service.document_submitter.email) >= 0;
                }

                return isCurrentUser;
            };

            return this;
        }
    ]);
})(angular.module('onfileApp'));
