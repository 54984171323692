;(function (app) {
  app.controller('EFilingsDraftSidebarCtrl', [
    'FirebaseService',
    '$scope', '$rootScope', '$stateParams','$q',
    function (
      FirebaseService,
      $, $rootScope, $stateParams,$q
    ) {
      $rootScope.showList = true;

      $rootScope.eFilingsDrafts = null;

      angular.extend($rootScope, {
        activeEFilingDraftId: $stateParams.id
      });

      // Callback function which is called when URL state changes.
      this.uiOnParamsChanged = function (changedParams) {
        // If current active eService ID is changed - refresh variable for a view.
        if (changedParams.hasOwnProperty('id'))
          $rootScope.activeEFilingDraftId = changedParams.id;
      };

      var efilingDefer = $q.defer();
      var newCaseDefer = $q.defer();

      var efilings;
      var cases;
      FirebaseService.getIdChannel('auth_users', 'efilings_drafts')
        .then(function (drafts) {
          $rootScope.efilingsDraftsO = drafts;
          drafts.$loaded().then(function(loaded){
            for(var i=0 ;i < drafts.length; i ++) {
              drafts[i].draftType = 'efile';
            }
            efilings = loaded;
            efilingDefer.resolve(true);
          });
        });

      FirebaseService.getIdChannel('auth_users', 'cases_drafts')
          .then(function (drafts) {
            $rootScope.casesDraftsO = drafts;
            drafts.$loaded().then(function(loaded){
              for(var i=0 ;i < drafts.length; i ++) {
                  var type = 'case';
                if(drafts[i].item.hasOwnProperty("nonIndexCaseFiling") && drafts[i].item.nonIndexCaseFiling)
                    type = 'nonindexed';

                  drafts[i].draftType = type;
              }
              cases = drafts;
              newCaseDefer.resolve(true);
            });
          });

      $q.all([efilingDefer.promise,newCaseDefer.promise]).then(function(){
          var combined = efilings.concat(cases);
          $rootScope.eFilingsDrafts = combined;
      });
    }
  ])
})(angular.module('onfileApp'));