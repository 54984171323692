;(function (app) {
    app.filter('reminderTypeFilter', function reminderTypeFilter() {
        return function (value) {

            var types = {};

            types.court_efiling = 'Court eFiling';
            types.eservice_to_party = 'eService to party';
            types.eproduction = 'eProduction';
            types.other = 'Other';

            return types[value];
        };
    });
})(angular.module('onfileApp'));
